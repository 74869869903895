import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tk-error-list"
}

import { watch, ref, onUnmounted } from 'vue';
import { tkErrorList } from './tkErrorList';

interface IList {
    stringifedJson?: string | Record<string, any>;
}

type TList = {
    code: string,
    message: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TKErrorList',
  props: {
    stringifedJson: {}
  },
  setup(__props: any) {

const props = __props;

// helpers
const matchErrorCode = (data: any): TList[] =>{
    const result: TList[] = [];

    data.forEach((message: any) => {
        if (message.code) {
            result.push({
                code: message.code + '',
                message: ((tkErrorList as Record<string, string>)?.[message.code] ?? '') + '',
            });
        }
    })

    return result;
}


const prepareList = (data: any): TList[] => {
    if (!data) { return [] }

    const result: TList[] = [];

    if (typeof data === 'string') {
        try {
            const json = JSON.parse(data);
            if (Array.isArray(json.messages)) {
                result.push(...matchErrorCode(json.messages));
            }
        }
        catch(e) {

        }
    }
    
    return result;
}

// data
const list = ref<TList[]>(prepareList(props.stringifedJson));
watch(props, (v) => { list.value = prepareList(v) });
onUnmounted(() => { list.value = [] });

return (_ctx: any,_cache: any) => {
  return (list.value.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "tk-error-list__title" }, "Ошибки:", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item) => {
          return (_openBlock(), _createElementBlock("p", {
            key: item.code
          }, [
            _createElementVNode("span", null, _toDisplayString(item.code) + ":", 1),
            _createTextVNode(_toDisplayString(item.message), 1)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})