<script setup lang="ts">
import { defineProps, watch, ref, onUnmounted } from 'vue';

interface IList {
    stringifedJson?: string | Record<string, any>;
}

const props = defineProps<IList>();

// helpers
const prepareList = (data: any): string => {
    if (!data) { return '' }

    let atragID: string = '';

    if (typeof data === 'string') {
        try {
            const json = JSON.parse(data);
            if (typeof json?.antragId === 'string') {
                atragID = json.antragId
            }
        }
        catch(e) {

        }
    }
    
    return atragID;
}

// data
const atragID = ref<string>(prepareList(props.stringifedJson));

watch(props, (v) => { atragID.value = prepareList(v) });
onUnmounted(() => { atragID.value = '' });
</script>

<template>
    <div v-if="atragID" class="tk-success-test">
        <p class="tk-success-test__title">atragID:</p>
        <p>{{ atragID }}</p>
    </div>
</template>


<style lang="scss">
.tk-success-test {
    &__title {
        margin: 2em 0 0.5em;
        color: #000;
    }

    p span {
        font-weight: 600;
        color: #000;
        padding-right: 10px;
    }
}
</style>