import { defineComponent as _defineComponent } from 'vue'
import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-check" }
const _hoisted_2 = { class: "form-check-label" }
const _hoisted_3 = ["required", "disabled", "name", "value"]

import { defineComponent, ref } from 'vue';
import { IRadio } from './types';
import { getValueFromEvent } from './helpers';

// props

export default /*@__PURE__*/_defineComponent({
  __name: 'Radio',
  props: {
    modelValue: {},
    disabled: {},
    required: {},
    value: {},
    name: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const inputValue = ref(props.modelValue || '');

defineComponent({ name: 'RadioBox' });

// emits
const emits = __emit;

// methods
const inputHandler = (e: Event) => {
    const value = getValueFromEvent(e);
    emits('update:modelValue', value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
        class: "form-check-input",
        type: "radio",
        required: Boolean(_ctx.required),
        disabled: Boolean(_ctx.disabled),
        name: _ctx.name,
        value: _ctx.value,
        onInput: inputHandler
      }, null, 40, _hoisted_3), [
        [_vModelRadio, inputValue.value]
      ]),
      _renderSlot(_ctx.$slots, "default"),
      _cache[1] || (_cache[1] = _createElementVNode("i", { class: "input-helper" }, null, -1))
    ])
  ]))
}
}

})