<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
import { ITablePaginationPrevNextButton } from '@/components/Table/types';

defineComponent({ name: 'TPaginationBtn' });

const props = defineProps<ITablePaginationPrevNextButton>();

</script>

<template>
  <button
    class="--btn"
    :class="props.isActive ? '--active' : ''"
  >
    {{ '<' }}
  </button>
</template>

<style>
button.--btn {
    opacity: .3;
}

button.--active {
    opacity: 1;
}
</style>