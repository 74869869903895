<script setup lang="ts">
import { ref } from 'vue';
import { ENavy, router } from '@/router';
import defaultUserImage from '@/assets/img/user.svg';
// store
import { useUserStore, loginUserByLoginPassword } from '@/store';


const userStore = useUserStore();

const login = ref<string>('');
const password = ref<string>('');

const isEmailValidator = (email?: string): boolean => {
    if (!email) { return false }
    const regex = /^[a-zA-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/ig;

    return regex.test(email);
}

const loginHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropogation && e.stopPropogation();

    if (userStore.loading) { return }

    // проверяем на почту или телефон
    let identifier = login.value.trim();
    if (!isEmailValidator(login.value.trim())) {
        identifier = (identifier.match(/\d+/g) || []).join('');
        if (identifier[0] === '8') {
            identifier = '7' + identifier.slice(1);
        }
    }

    loginUserByLoginPassword(identifier, password.value)
        .then((status) => status && router.push(ENavy.dashboard))
}
</script>

<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth lock-full-bg">
        <div class="row w-100">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-transparent text-left p-5 text-center">
              <img
                :src="defaultUserImage"
                class="lock-profile-img"
                alt="img"
              >
              <form
                class="pt-5"
                @submit="loginHandler"
              >
                <div class="form-group">
                  <label for="login">Login to unlock</label>
                  <input
                    id="login"
                    v-model="login"
                    type="text"
                    class="form-control text-center"
                    placeholder="Login"
                    required
                    :disabled="userStore.loading"
                  >
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <input
                    id="password"
                    v-model="password"
                    type="password"
                    class="form-control text-center"
                    placeholder="Password"
                    required
                    :disabled="userStore.loading"
                  >
                </div>
                <div class="mt-5">
                  <button
                    type="submit"
                    :disabled="userStore.loading"
                    class="btn btn-block btn-success btn-lg font-weight-medium"
                  >
                    Login
                  </button>
                </div>

                <div
                  v-if="userStore.errorMessage"
                  class="mt-3 text-center"
                >
                  <p class="auth-link text-white">
                    Login error!
                  </p>
                  <p class="auth-link text-white">
                    {{ userStore.errorMessage.toLocaleUpperCase() }}
                  </p>
                  <p
                    v-if="userStore.errorRequestId"
                    class="auth-link text-white"
                  >
                    request id: {{ userStore.errorRequestId }}
                  </p>
                  <p
                    v-if="userStore.errorCode"
                    class="auth-link text-white"
                  >
                    code: {{ userStore.errorCode }}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
    #login, #password {
        color: #fff;
    }
</style>