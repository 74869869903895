import { generateSyncDataTableStore } from '@/components/Table';
import { defineStore } from 'pinia';


export type TRuleData = {
	id: number;
	title: string;
}

export const useSyncRulesStore = generateSyncDataTableStore<TRuleData>({
	initialState: {
		loading: true,
		paginateInfoTpl: '_DATA_ отфильтровано: _FILTERED_ всего: _TOTAL_',

		body: {
			emptyTitle: 'Права на действия не найдены'
		},

		head: {
			class: 'head-no-select' 
		},
	},

	onDataStateChange(useStore) {
		const store = useStore();
		// @dev:
		console.log('onDataStateChange', store.paginateFiltered, store.filteredData.length, store.viewData.length)
	}
});

// для апи таблицы
export const usePermissionsApi = defineStore({
	id: 'permissions-api',
	state: (): { loading: boolean; list: TRuleData[] } =>{
		return {
			loading: true,
			list: []
		}
	},

	actions: {
		getList(clearFilters?: boolean) {
			this.loading = true;
			const tableStore = useSyncRulesStore();
			tableStore.loading = true;
			getData()
				.then(list => {
					tableStore.data = list;
					clearFilters && tableStore.clearFilters();
				})
				.finally(()=>{
					this.loading = false;
					tableStore.loading = false;
				})
		},

		saveRule(id: number, title: string) {
			this.loading = true;
			console.log('save rule', id, title); // @dev:
			new Promise(res=>{
				setTimeout(()=>res(1), 1e3)
			})
				.finally(()=>{
					this.getList()
				})
		},

		addRule(title: string) {
			this.loading = true;
			console.log('add new rule', title);
			new Promise(res=>{
				setTimeout(()=>res(1), 5e2)
			})
				.finally(()=>{
					this.getList()
				})
		}
	}
});

export const getData = async (): Promise<TRuleData[]> => {
	// mock

	return new Promise((res, rej) => {
		setTimeout(()=>{
			const data = [
				{
					id: 100,
					title: 'Показывать управление правами'
				},
				{
					id: 101,
					title: 'Изменение прав'
				},
				{
					id: 102,
					title: 'Добавление прав'
				},
				...((): TRuleData[]=>{
					return new Array(100).fill(null).map((i,k)=>{
						return {
							id: k + 1000,
							title: Math.random().toString(36).replace('0.', '')
						}
					})
				})(),
			];

			res(data);
		}, 2e3)
	})
}