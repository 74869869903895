<script setup lang="ts">
import { defineComponent } from 'vue';

defineComponent({name: 'KitTablePaginationDelimeter'});

</script>

<template>
  <li class="paginate_button page-item delimeter">
    <a class="page-link delimeter">
      <i class="fa fa-ellipsis-h" />
    </a>
  </li>
</template>

<style lang="scss">
    .paginate_button.page-item.delimeter {
        & a {
            cursor: no-drop;

            @media screen and (max-width: 540px) {
                & {
                    opacity: 0;
                }
            }
        }

        @media screen and (max-width: 540px) {
            & {
                width: 10px;
                background: #e0e0e0;
            }
        }
    } 
</style>