<script setup lang="ts">
import { ref, defineProps } from 'vue';
import { usePageEditItemStore } from './store/dealItemStore';
import { TJsonExtensionProps } from 'litsystems-vue-json-editor-dev/src';


const props = defineProps<TJsonExtensionProps>();

const value = ref<string>((props.value ?? '').toString());

const editStore = usePageEditItemStore();

const onChangeHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    var inputValue = (e.target as HTMLInputElement).value;

    if (inputValue !== props.value) {
        editStore.isUpdated = true; // установка флага обновления

        // если прошли валидацию, то устанавливаем новое значение
        value.value = inputValue;

        const jsonStore = props.useStore();
        jsonStore.setValueByKeys(
            [...props["parentKeys"], props["propKey"]].slice(1), // подготавливаем ключи
            inputValue
        );
    }
}
</script>

<template>
    <input class="form-control title-price" @input="onChangeHandler" v-model="value" />
</template>
