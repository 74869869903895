import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { defineComponent } from 'vue';
import { ITableCell } from './types';



export default /*@__PURE__*/_defineComponent({
  __name: 'Cell',
  props: {
    class: {},
    value: {}
  },
  setup(__props: any) {

const props = __props;

defineComponent({ name: 'TableCell' });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", {
    class: _normalizeClass(props.class || '')
  }, _toDisplayString(props.value || ''), 3))
}
}

})