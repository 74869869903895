<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
// components
import Cell from './Cell.vue';
// types
import { ITableColumn, ETableColumnDataType } from './types';


defineComponent({ name: 'TableColumn' });
const props = defineProps<ITableColumn>();

const getMode = (): ETableColumnDataType | void => {
    if (props.render) { return ETableColumnDataType.render }
    if (props.component) { return ETableColumnDataType.component }
    if (props.dataKey) { return ETableColumnDataType.dataKey }
};

const mode = getMode();

// prepared data for cell
let data = '';

if (mode != ETableColumnDataType.component) {
    switch (mode) {
        case ETableColumnDataType.render:
            try {
                data = props?.render?.(props.rowData, props.value, props.rowIndex) || ''
            }
            catch (e) {
                props.devMode && console.error(e);
            }
            break;

        case ETableColumnDataType.dataKey:
            data = props.value && props.value.toString() || '';
            break;

        default:
            break;
    }
}

props.devMode && (() => {
    console.log({
        ...props
    })
})()
</script>

<template>
  <component 
    :is="props.component" 
    v-if="mode==='component' && props?.component"
    :data-key="props.dataKey"
    :row-data="props.rowData"
    :row-index="props.rowIndex"
    :value="props.value"
    :class="props.class || ''"
  />

  <Cell
    v-else
    :class="props.class"
    :value="data"
  />
</template>