import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import PageHeader from '@/components/kit/PageHeader.vue';
import Card from '@/components/kit/Card.vue';
import CardTitle from '@/components/kit/CardTitle.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'Dashboard',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Dashboard")
      ])),
      _: 1
    }),
    _createVNode(Card, null, {
      default: _withCtx(() => [
        _createVNode(CardTitle, null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("ERP (enterprise resource planning)")
          ])),
          _: 1
        }),
        _cache[2] || (_cache[2] = _createElementVNode("p", null, "ERP (enterprise resource planning) система организации Tupa Germania.", -1))
      ]),
      _: 1
    })
  ], 64))
}
}

})