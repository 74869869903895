<script setup lang="ts">
import { defineComponent } from 'vue';


defineComponent({ name: 'LoaderView' });
</script>

<template>
  <div class="loader">
    <div class="loader-box">
      <div class="circle-loader">
        <span>загрузка</span>
      </div>
    </div>
  </div>
</template>