<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
import { ILabel } from './types';
import { getUniqId } from '@/utils';


// props
const props = defineProps<ILabel>();
const idi = props.id || getUniqId()

defineComponent({ name: 'LabelInput' });
</script>

<template>
  <div class="form-group">
    <slot :id="idi" />
  </div>
</template>