import { useRouter } from 'vue-router';
import { routeNames } from './router';

// helpers
export const redirectToHome = ()=>{
	const router = useRouter();
	router.push('/')
}

export const redirectTo404 = ()=>{
	const router = useRouter();
	router.push('/404-not-found')
}

export const redirectTo401 = ()=>{
	const router = useRouter();
	router.push('/login')
}