<script setup lang="ts">
import { defineComponent } from 'vue';


defineComponent({ name: 'LabelText' });
</script>

<template>
  <label>
    <slot />
  </label>
</template>

<style lang="scss">
    label {
        &[for] {
            cursor: pointer;
        }

        &.no-pointer{
            cursor: initial;
        }
    }
</style>