import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-lg-4" }
const _hoisted_2 = { class: "border-bottom text-center pb-4" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "border-bottom py-4" }
const _hoisted_5 = { class: "col-lg-8" }
const _hoisted_6 = {
  key: 0,
  class: "mt-4 py-2 border-top border-bottom"
}

import { defineComponent } from 'vue';
import defaultUserImage from '@/assets/img/user.svg';
// routes
import { useRoute } from 'vue-router';
import { redirectTo404 } from '@/router';
// components
import PageHeader from '@/components/kit/PageHeader.vue';
import Card from '@/components/kit/Card.vue';
import Row from '@/components/kit/Row.vue';
import Loader from '@/components/Loader.vue';
// store
import { isUserCanEditUser } from '@/store';



export default /*@__PURE__*/_defineComponent({
  __name: 'UserPage',
  setup(__props) {

defineComponent({ name: 'UserPage' });

const getUserInfo = () => {
    const userId = getUserId();
    if (userId) {
        alert('get data for user id: ' + userId)
    }
    else {
        redirectTo404()
    }
    
}

const getUserId = (): number => {
    const route = useRoute();
    const userId = parseInt(route.params?.userId + '');

    if (userId && Number.isInteger(userId) && userId > 0 && userId.toString() === route.params?.userId) {
        return userId
    }
    else {
        return NaN
    }
}


getUserInfo();

const store = {

    id: 101,
    name: 'Пётр Петров',
    email: 'c@d.com',
    avatar: '',
    description: 'sadasdas asdasdfsdf sadfsdfsadfsadf sdfsadfsadfsadfasdf sdfasdfasdfasdf',

    loading: false

};

store;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Профиль пользователя")
      ])),
      _: 1
    }),
    _createVNode(Card, { style: {"position":"relative"} }, {
      default: _withCtx(() => [
        (store.loading)
          ? (_openBlock(), _createBlock(Loader, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(Row, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  src: store.avatar || _unref(defaultUserImage),
                  alt: store.name,
                  class: "img-lg rounded-circle mb-3"
                }, null, 8, _hoisted_3),
                _createElementVNode("h3", null, _toDisplayString(store.name), 1),
                _createElementVNode("p", null, _toDisplayString(store.description), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", null, _toDisplayString(store.email), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_unref(isUserCanEditUser)())
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[1] || (_cache[1] = [
                    _createElementVNode("ul", { class: "nav profile-navbar" }, [
                      _createElementVNode("li", { class: "nav-item" }, [
                        _createElementVNode("a", {
                          class: "nav-link",
                          href: "#"
                        }, [
                          _createElementVNode("i", { class: "mdi mdi-account-outline" }),
                          _createTextVNode(" Info ")
                        ])
                      ]),
                      _createElementVNode("li", { class: "nav-item" }, [
                        _createElementVNode("a", {
                          class: "nav-link active",
                          href: "#"
                        }, [
                          _createElementVNode("i", { class: "mdi mdi-newspaper" }),
                          _createTextVNode(" Feed ")
                        ])
                      ]),
                      _createElementVNode("li", { class: "nav-item" }, [
                        _createElementVNode("a", {
                          class: "nav-link",
                          href: "#"
                        }, [
                          _createElementVNode("i", { class: "mdi mdi-calendar" }),
                          _createTextVNode(" Agenda ")
                        ])
                      ]),
                      _createElementVNode("li", { class: "nav-item" }, [
                        _createElementVNode("a", {
                          class: "nav-link",
                          href: "#"
                        }, [
                          _createElementVNode("i", { class: "mdi mdi-attachment" }),
                          _createTextVNode(" Resume ")
                        ])
                      ])
                    ], -1)
                  ])))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
}

})