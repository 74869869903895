<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';


const props = defineProps<{ minWidth?: number | 'none' }>();
defineComponent({name: 'MinWidthScroll'});

const minWidthHelper = (): string => {
    if (typeof props.minWidth === 'number') {
        return props.minWidth + 'px'
    }
    return 'none';
}
</script>

<template>
  <div class="min-width-scroll">
    <div
      class="min-width-scroll-box"
      :style="{minWidth: minWidthHelper()}"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
    .row > .min-width-scroll {
        padding: 0;
    }

    .min-width-scroll {
        width: 100%;
        padding: 0;
        overflow-x: auto;
        position: relative;

        &-box {
            width: 100%;
        }
    }
</style>