import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ENavy, router } from '@/router';

interface IRow {
    rowData: {
        id: string | number;
    }
}
export default /*@__PURE__*/_defineComponent({
  __name: 'PostRow',
  props: {
    rowData: {}
  },
  setup(__props: any) {

;

const props = __props;

const clickHandler = (e: Event)=>{
    e.preventDefault();
    e.stopPropagation();
    
    if (props.rowData.id) {
        router.push(ENavy.cmiPostEditor.replace(':id', props.rowData.id.toString()));
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", {
    class: "cmi-table-row",
    onClick: clickHandler
  }, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})