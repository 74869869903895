<script setup lang="ts">
import { ref, defineEmits, watch } from 'vue';
import { getValueFromEvent } from '@/components/kit';
// components
import VueDatePicker, { DatePickerInstance } from '@vuepic/vue-datepicker';
// utils
import {
    getUniqId,
    isPartOfDate,
    isStrDateValid,
    formatDateStr
} from '@/utils';


// utils
const validateDate = (): boolean => {
    const minDate = props.minDate;
    const maxDate = props.maxDate;

    const date = value.value;

    // 
    if (props.required && date === null) { return false }

    if (date === null) {
        if (minDate || maxDate) { return false }
        return true;
    }

    if (minDate && minDate > date) { return false }
    if (maxDate && maxDate < date) { return false }

    return true;
}

// types
type TValue = Date | null;

interface IQuestionViewerEditorDatepicker {
    isEditable?: boolean;
    label?: string;
    modelValue: TValue;
    required?: boolean;
    type?: 'textarea';
    minDate?: Date;
    maxDate?: Date;
}

// props
const props = defineProps<IQuestionViewerEditorDatepicker>();
const value = ref<TValue>(props.modelValue || null);
const inputValue = ref<string>(props.modelValue?.toLocaleDateString() || '');
const preValue = ref<string>(inputValue.value);
const isValueValid = ref<boolean>(validateDate());


// actions
const emits = defineEmits(['update:modelValue']);
watch(() => props.modelValue, () => {
    const modelValue = props.modelValue;
    value.value = modelValue;
    inputValue.value = modelValue?.toLocaleDateString() || '';
    preValue.value = inputValue.value;
    isValueValid.value = validateDate();
    isEditorOpened.value = false;
});

watch(value, () => {
    isValueValid.value = validateDate();
});

// handlers
const isEditorOpened = ref<boolean>(false);
const editBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    isEditorOpened.value = true;
}

const cancelBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    value.value = props.modelValue;
    inputValue.value = props.modelValue && props.modelValue.toLocaleDateString() || '';
    preValue.value = inputValue.value;
    isValueValid.value = validateDate();
    isEditorOpened.value = false;
}

const saveValue = () => {
    if (!validateDate()) { return }
    emits('update:modelValue', value.value);
    isEditorOpened.value = false;
}

const saveBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    saveValue();
}

const submitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
}

// datepicker options
const dayNames = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
const inputId = getUniqId();
const datepicker = ref<DatePickerInstance>(null); // связываем компонент datepicker с переменной

// datepicker handlers
const updateHandler = (modelData: string) => {
    if (modelData) {
        const date = new Date(modelData);
        const formatedDate = date.toLocaleDateString();
        inputValue.value = formatedDate;
        preValue.value = formatedDate;
        value.value = date;
    }
    else {
        value.value = null;
    }
}

const clearHandler = () => {
    inputValue.value = '';
    preValue.value = '';
    value.value = null;
}

const blurHandler = () => {
    if (value.value) {
        inputValue.value = new Date(value.value).toLocaleDateString();
        preValue.value = inputValue.value
    }
    else {
        inputValue.value = '';
        preValue.value = '';
        value.value = null;
    }
}

const inputFocusHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    datepicker.value?.openMenu();
}

const inputEnterHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (!validateDate()) { return }
    saveBtnClickHandler(e);
}

const inputHandler = (e: Event) => {
    // при вставке даты c / заменим на точки
    const eventValue = getValueFromEvent(e).replace(/\//g, '.');

    // проверка, для того, чтобы можно было стирать точки
    if (eventValue + '.' === preValue.value) {
        preValue.value = eventValue;
        inputValue.value = eventValue;
        return
    }

    // если стёрли дату
    if (eventValue === '') {
        preValue.value = '';
        inputValue.value = '';
        value.value = null;
        return
    }

    if (isPartOfDate(eventValue)) {
        let doted = '';
        if (eventValue === preValue.value) {
            doted = eventValue;
        }
        else {
            // автодобавление точек
            doted = eventValue.length === 2 ? (eventValue + '.') : (
                eventValue.length === 5 ? (eventValue + '.') : eventValue
            );
        }

        // если дата полная, то отправляем её в дейтпикер
        if (doted.length === 10) {
            if (isStrDateValid(doted)) {
                let setDate: Date | null = new Date(formatDateStr(doted));

                // если указан диапазон дат
                if (props.minDate && props.maxDate) {
                    if (props.minDate > setDate || props.maxDate < setDate) {
                        setDate = null;
                    }
                }
                else {
                    // если указан только minDate
                    if (props.minDate) {
                        if (props.minDate > setDate) {
                            setDate = null;
                        }
                    }
                    else {
                        // если указан только maxDate
                        if (props.maxDate) {
                            if (props.maxDate < setDate) {
                                setDate = null;
                            }
                        }
                    }

                }

                if (setDate) {
                    value.value = setDate;
                    preValue.value = doted;
                    inputValue.value = doted;
                }
                else {
                    value.value = null;
                    inputValue.value = preValue.value;
                }
            }
            else {
                if (value.value) {
                    inputValue.value = value.value.toLocaleDateString();
                    preValue.value = inputValue.value;
                }
                else {
                    const lastValidStr = serachValidStr(doted);
                    inputValue.value = lastValidStr;
                    preValue.value = lastValidStr;
                }
            }
        }
        else {
            preValue.value = doted;
            inputValue.value = doted;
        }
    }
    else {
        // если введён недопустимый символ, то возвращаем предыдущее значение
        inputValue.value = preValue.value;
    }
}

// будем искать последнюю часть строки для даты
const serachValidStr = (date: string): string => {
    let str = date;
    while (str) {
        str = str.substring(0, str.length - 1);
        let strForMatch = str.padEnd(10, '1').split('');
        strForMatch[2] = '.';
        strForMatch[5] = '.';

        if (isStrDateValid(strForMatch.join(''))) {
            return str;
        }
    }
    return ''
}
</script>

<template>
    <div class="value-editor-datepicker" :class="!isValueValid && 'value-editor-error'">
        <label :for="inputId" :class="props.isEditable && isEditorOpened && 'value-editor-editable'">
            {{ props.label ?? '' }}
        </label>
        <template v-if="props.isEditable">
            <div v-if="!isEditorOpened" class="value-editor-preview">
                <input :value="value && value.toLocaleDateString() || ''" disabled="true" class="form-control form-control-sm">
                <button v-if="props.isEditable" @click="editBtnClickHandler" 
                    class="btn btn-outline-secondary btn-icon" title="Редактировать"
                >
                    <i class="mdi mdi-pencil"></i>
                </button>
            </div>
            <form v-else @submit="submitHandler" class="value-editor-conrollers">
                <div class="value-editor-conrollers-input">
                    <VueDatePicker
                        v-model="value"
                        :utc="true"
                        @update:model-value="updateHandler"
                        @cleared="clearHandler"
                        :min-date="props.minDate"
                        :max-date="props.maxDate"
                        
                        ref="datepicker"
                        locale="ru"
                        placeholder="dd.mm.yyyy"
                        format="dd.MM.yyyy"
                        :enable-time-picker="false"
                        auto-apply
                        :day-names="dayNames"
                        :uid="inputId"
                        text-input
                    >
                        <template #dp-input="{ }">
                            <input 
                                v-model="inputValue"
                                :onInput="inputHandler"
                                :onBlur="blurHandler"
                                @keydown.enter="inputEnterHandler"
                                @focusin="inputFocusHandler"
                                @click="inputFocusHandler"
                                placeholder="dd.mm.YYYY"
                                autocomplete="off"
                                :id="inputId"
                                class="form-control form-control-sm"
                                type="text"
                            />
                        </template>
                    </VueDatePicker>
                </div>

                <div class="value-editor-conrollers-btn">
                    <button @click="saveBtnClickHandler" :disabled="!isValueValid" class="btn btn-outline-success btn-icon"
                        title="Применить редактирование">
                        <i class="mdi mdi-check"></i>
                    </button>
                    <button @click="cancelBtnClickHandler" class="btn btn-outline-danger btn-icon" title="Отменить редактирование">
                        <i class="mdi mdi-close"></i>
                    </button>
                </div>
            </form>
        </template>
        <input v-else :value="value && value.toLocaleDateString() || ''" disabled="true" class="form-control form-control-sm">
        <div class="value-editor-datepicker-viewer">
            <slot />
        </div>
    </div>
</template>


<style lang="scss">
.value-editor-datepicker {
    margin: 20px 0;

    .btn.btn-icon {
        height: 2.875rem;
    }

    .value-editor-datepicker-viewer {
        display: none;
    }

    &.value-editor-error {
        color: red;

        .value-editor-datepicker-viewer {
            display: initial;
        }
    }

    label {
        color: #000007;
        margin-bottom: 5px;
    }

    label[for] {
        cursor: initial;
    }

    label.value-editor-editable[for] {
        cursor: pointer;
    }

    .value-editor-preview {
        display: flex;
    }

    .value-editor-conrollers {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: normal;
        align-content: normal;

        .value-editor-conrollers-btn {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: normal;
            align-content: normal;
        }

        .value-editor-conrollers-input {
            width: 100%;
        }
    }

    /* datepicker */
    .dp__instance_calendar {
        font-family: "ubuntu-light", sans-serif;

        * {
            font-family: "ubuntu-light", sans-serif !important;
        }
    }
}
</style>