<script setup lang="ts">
import { defineComponent } from 'vue';
// components
import PageHeader from '@/components/kit/PageHeader.vue';
import Card from '@/components/kit/Card.vue';
import Row from '@/components/kit/Row.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import MinWidthAndScroll from '@/components/kit/MinWidthAndScroll.vue';
import Table from '@/components/kit/Table/Table.vue';
import TablePagination from '@/components/kit/Table/TablePagination.vue';
import TableSearch from '@/components/kit/Table/TableSearch.vue';
import Loader from '@/components/Loader.vue';
import UserAvatarTd from './UserAvatarTd.vue';
// import UserActionsTd from './UserActionsTd.vue';
import UserDescriptionTd from './UserDescriptionTd.vue';
// store
import { useSyncUserListStore, useUserApi } from './usersStore';


const tableStore = useSyncUserListStore();
const userApiStore = useUserApi();

defineComponent({ name: 'UserList' });

// настройка колонок
tableStore.columns = [
    {
        key: 'avatar',
        title: '#',
        component: UserAvatarTd,
    },

    {
        key: 'id',
        title: 'ID юзера',
        searchable: true,
        sortable: true,
        headClass: 'users-th-id',
    },

    {
        key: 'name',
        title: 'Фио',
        searchable: true,
        sortable: true,
    },

    {
        key: 'email',
        title: 'email',
        searchable: true,
        sortable: true,
    },

    {
        key: 'description',
        title: 'Описание',
        component: UserDescriptionTd,
    },

    // пересено до 1го релиза
    // {
    //     key: 'id',
    //     title: '#',
    //     component: UserActionsTd,
    // }
];

// получение данных сразу для всех юзеров
userApiStore.getList();
</script>

<template>
  <PageHeader>Управление пользователями</PageHeader>

  <Card style="position:relative">
    <Loader v-if="userApiStore.loading" />
    <CardTitle>Cписок пользователей</CardTitle>
        
    <TableSearch :use-store="useSyncUserListStore" />

    <Row class="tab-content tab-transparent-content">
      <MinWidthAndScroll>
        <Table :use-store="useSyncUserListStore" />
      </MinWidthAndScroll>
    </Row>

    <TablePagination
      class="tab-content tab-transparent-content"
      :use-store="useSyncUserListStore"
    />
  </Card>
</template>

<style lang="scss">
.users-th-id {
    width: 100px;
}
</style>