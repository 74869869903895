import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, unref as _unref, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-sm-12 col-md-6" }
const _hoisted_2 = { class: "table-per-page" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "col-sm-12 col-md-6" }
const _hoisted_5 = { class: "table-search-filter" }

import { defineComponent, ref, watch } from 'vue';
// components
import Row from '@/components/kit/Row.vue';
import Input from '@/components/kit/Input.vue';
// types
import { TTableStore, useTableSearch } from '@/components/Table';



export default /*@__PURE__*/_defineComponent({
  __name: 'TableSearch',
  props: {
    useStore: {}
  },
  setup(__props: any) {

const props = __props;
const store = props.useStore();

// search
const searchValue = useTableSearch(props.useStore);

// perpage
const perPageList = ref<number[]>([10, 25, 50, 100]);
const perPageValue = ref<number>(store.paginatePerPage);
watch(perPageValue, ()=>store.setPerPage(perPageValue.value));
watch(()=>store.paginatePerPage, ()=>{
    const storePerPage = store.paginatePerPage;
    if (!~perPageList.value.indexOf(storePerPage)) {
        perPageList.value = [...perPageList.value, storePerPage].sort((a,b)=>{return a<b?-1:1});
        perPageValue.value = storePerPage;
    }
})

defineComponent({ name: 'KitTableSearch'});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Row, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", null, [
            _cache[2] || (_cache[2] = _createTextVNode("Показывать ")),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((perPageValue).value = $event)),
              class: "custom-select custom-select-sm form-control"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(perPageList.value, (v, k) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: k,
                  value: v
                }, _toDisplayString(v), 9, _hoisted_3))
              }), 128))
            ], 512), [
              [_vModelSelect, perPageValue.value]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, [
            _createVNode(Input, {
              modelValue: _unref(searchValue),
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(searchValue) ? (searchValue).value = $event : null)),
              type: "search",
              placeholder: "Поиск"
            }, null, 8, ["modelValue"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})