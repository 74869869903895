<script setup lang="ts">
import { onMounted, onUnmounted, ref, reactive, watch } from 'vue';
import { isUserCanEditPostCmi } from '@/store';
// routes
import { useRoute } from 'vue-router';
// components
import { Card, CardTitle, PageHeader, Loader } from '@/components/kit';
import {
    QuestionViewer,
    QuestionViewerEditorRadio,
    QuestionViewerEditorDatepicker,
} from '@/components/kit/QuestionViewer';
// store
import { useCmiPostedItemStore } from './store'; 


const dictionaryPosted = {
	"Отправлено": true,
	"Не отправлено": false
}

const store = useCmiPostedItemStore();

const dealId = ref<number>(NaN);
onMounted(()=>{
    const route = useRoute();
    const deal = parseInt((route?.params?.id ?? '') + '', 10);
    if ( !Number.isNaN(deal) ) {
        dealId.value = deal;
        store.getDataById(deal);
    }
});

const initialStatus = ref<boolean>();
const initialDate = ref<Date>();

onUnmounted(()=>{
    store.$reset();
});

const isSaveEnabled = ref(false);
watch(()=>[ store.$loading, store.status, store.postedDate ], ({0: loading})=>{
    if (loading) {
        initialStatus.value = store.status;
        initialDate.value = store.postedDate;
        return
    }

    isSaveEnabled.value = store.status !== initialStatus.value || store.postedDate !== initialDate.value;
})

// handlers
const savePostDataHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    store.saveDataById();
}
</script>

<template>
    <PageHeader>Почтовая отправка ОМС</PageHeader>
    <Card
        v-if="!isUserCanEditPostCmi()"
        style="position:relative"
    >
        <CardTitle>Нет прав на просмотр данных</CardTitle>
    </Card>

    <Card v-if="!store.$error">
        <Loader v-if="store.$loading" />
        <CardTitle>Заявка ОМС № {{ dealId }}</CardTitle>

        <div class="row">
            <QuestionViewer label="ID заявки на сайте" :value="store.uid" class="value-viewer col-sm-12 col-md-4" />
            <QuestionViewer label="Email" :value="store.email" class="value-viewer col-sm-12 col-md-4" />
        </div>
        <div class="row">
            <QuestionViewer label="Имя" :value="store.firstName" class="value-viewer col-sm-12 col-md-4" />
            <QuestionViewer label="Фамилия" :value="store.lastName" class="value-viewer col-sm-12 col-md-4" />
            <QuestionViewer label="Фамилия супруга" :value="store.additionalLastName" class="value-viewer col-sm-12 col-md-4" />
        </div>

        <div class="row">
            <QuestionViewerEditorRadio
                label="Статус" 
                class="col-lg-4 col-md-4 col-sm-12"
                v-model="store.status"
                :dictionary="dictionaryPosted"
                :is-editable="true"
            >
                <p></p>
            </QuestionViewerEditorRadio>
    
            <QuestionViewerEditorDatepicker
                v-if="store.status && store.postedDate"
                label="Дата отправки" 
                class="col-lg-4 col-md-4 col-sm-12"
                v-model="store.postedDate" 
                :is-editable="true" 
            >
                    <p></p>
            </QuestionViewerEditorDatepicker>
        </div>

        <div class="row">
            <QuestionViewer 
                v-if="store.status && store.postedUserName"
                label="Отправил" 
                :value="store.postedUserName" 
                class="value-viewer"
            />
        </div>

        <button
            @click="savePostDataHandler"
            class="btn btn-inverse-primary btn-fw"
            :disabled="!isSaveEnabled"
        >
            Сохранить
        </button>
    </Card>

    <Card v-else>
        <CardTitle>Произошла ошибка</CardTitle>
        <p>{{ store.$error }}</p>
    </Card>
</template>