import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["download", "href"]

import { watch, ref, onUnmounted } from 'vue';

interface IFile {
    stringifedJson?: string | Record<string, any>;
    fileName: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DownloadFileBtn',
  props: {
    stringifedJson: {},
    fileName: {}
  },
  setup(__props: any) {

const props = __props;

// helpers
const prepareAtragID = (data: any): string => {
    if (!data) { return '' }

    let atragID: string = '';

    if (typeof data === 'string') {
        try {
            const json = JSON.parse(data);
            if (typeof json?.antragId === 'string') {
                atragID = json.antragId
            }
        }
        catch(e) {

        }
    }
    
    return atragID;
}

const prepareFileContent = (data: any): string => {
    if (!data) { return '' }

    let content: string = '';

    if (typeof data === 'string') {
        try {
            const json = JSON.parse(data);
            if (typeof json?.vorlBescheinigungData === 'string') {
                content = json.vorlBescheinigungData
            }
        }
        catch(e) {

        }
    }
    
    return content;
}

// data
const atragID = ref<string>(prepareAtragID(props.stringifedJson));
const fileContent = ref<string>(prepareFileContent(props.stringifedJson));

watch(props, (v) => {
    atragID.value = prepareAtragID(v);
    fileContent.value = prepareFileContent(v);
});
onUnmounted(() => {
    atragID.value = '';
    fileContent.value = '';
});

return (_ctx: any,_cache: any) => {
  return (atragID.value && fileContent.value)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: "btn btn-inverse-primary btn-fw",
        target: "_blank",
        download: props.fileName,
        href: `data:application/pdf;base64,${fileContent.value}`
      }, " Download file ", 8, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})