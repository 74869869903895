<script setup lang="ts">
import { defineComponent, defineProps, ref, watch } from 'vue';
// components
import Tbody from './Tbody.vue';
import Thead from './Thead.vue';
// types
import { ITable } from './types';


const props = defineProps<ITable<any>>();

defineComponent({ name: 'TableTable' });

const store = props.store();

// создаём ref, чтобы не пересчитывать каждый раз
const viewData = ref<typeof store.viewData>(store.viewData);
watch(() => store.viewData, () => { viewData.value = store.viewData });

const isEmptyRowComponent = ref<boolean>(Boolean(store?.body?.emptyComponent));
watch(() => store?.body?.emptyComponent, () => {
    isEmptyRowComponent.value = Boolean(store?.body?.emptyComponent);
})
</script>

<template>
  <table>
    <Thead
      :store="props.store"

      :dev-mode="props.devMode"
    />
    <slot />

    <Tbody 
      v-if="viewData.length && !store.loading"
      :store="props.store"

      :dev-mode="props.devMode"
    />
        
    <tbody v-if="!viewData.length && !isEmptyRowComponent">
      <tr>
        <td :colspan="store.columns.length">
          {{ store?.body?.emptyTitle || '' }}
        </td>
      </tr>
    </tbody>

    <component
      :is="store?.body?.emptyComponent"
      v-if="!viewData.length && isEmptyRowComponent"
      :title="store?.body?.emptyTitle || ''"
    />
  </table>
</template>