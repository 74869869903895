<script setup lang="ts">
import { ref, watch } from 'vue';
// components
import Input from '@/components/kit/Input.vue';
// store 
import { useAsyncPostListStore } from '../store';

const tableStore = useAsyncPostListStore();


const uid = ref('');
const firstName = ref('');
const lastName = ref('');
const additionalLastName = ref('');
const email = ref('');

watch([uid, firstName, lastName, additionalLastName, email], ()=>{
    // tableStore.searchBy
    tableStore.filterBy(1, [uid.value]);
    tableStore.filterBy(2, [firstName.value]);
    tableStore.filterBy(3, [lastName.value]);
    tableStore.filterBy(4, [additionalLastName.value]);
    tableStore.filterBy(5, [email.value]);
})
</script>

<template>
    <tbody class="cmi-posted-filters">
        <tr>
            <td colspan="1">
                Фильтры:
            </td>
            <td>
                <Input v-model="uid" type="search"/>
            </td>
            <td>
                <Input v-model="firstName" type="search"/>
            </td>
            <td>
                <Input v-model="lastName" type="search"/>
            </td>
            <td>
                <Input v-model="additionalLastName" type="search"/>
            </td>
            <td>
                <Input v-model="email" type="search"/>
            </td>
        </tr>
    </tbody>
</template>


<style lang="scss">
.cmi-posted-filters {
    td {
        color: gray;

        input.form-control {
            padding: 5px;
            min-width: 100px;
        }
    }
}
</style>