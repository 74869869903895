import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container-fluid page-body-wrapper" }
const _hoisted_2 = { class: "main-panel" }
const _hoisted_3 = { class: "content-wrapper kit-content-wrapper" }

import Loader from '@/components/Loader.vue';
import Header from '@/components/Header.vue';
import Sidebar from '@/components/Sidebar.vue';
import { LoginPage } from '@/modules/login';
// store
import {
    useUserStore,
    useNavyStore
} from '../store';


// store

export default /*@__PURE__*/_defineComponent({
  __name: 'Wrap',
  setup(__props) {

// components
const userStore = useUserStore();
const navyStore = useNavyStore();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(userStore).status)
      ? (_openBlock(), _createElementBlock("section", {
          key: 0,
          class: _normalizeClass(["container-scroller", _unref(navyStore).leftNavyOpen && 'sidebar-icon-only'])
        }, [
          _createVNode(Header),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(Sidebar),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_unref(userStore).loading)
                  ? (_openBlock(), _createBlock(Loader, { key: 0 }))
                  : _createCommentVNode("", true),
                (!_unref(userStore).loading)
                  ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ], 2))
      : _createCommentVNode("", true),
    (!_unref(userStore).status && !_unref(userStore).loading)
      ? (_openBlock(), _createBlock(_unref(LoginPage), { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})