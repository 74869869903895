import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { defineComponent } from 'vue';
// components
import PageHeader from '@/components/kit/PageHeader.vue';
import Card from '@/components/kit/Card.vue';
import Row from '@/components/kit/Row.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import MinWidthAndScroll from '@/components/kit/MinWidthAndScroll.vue';
import Table from '@/components/kit/Table/Table.vue';
import TablePagination from '@/components/kit/Table/TablePagination.vue';
import TableSearch from '@/components/kit/Table/TableSearch.vue';
import Loader from '@/components/Loader.vue';
import PermissionTitleTd from './PermissionTitleTd.vue';
import PermissionAdd from './PermissionAdd.vue';
// import TableConstructor from '@/modules/tableConstructor/TableConstructor.vue';
// store
import { useSyncRulesStore, usePermissionsApi } from './permissionsStore';



export default /*@__PURE__*/_defineComponent({
  __name: 'PermissionsPage',
  setup(__props) {

const tableStore = useSyncRulesStore();
const permissionsApi = usePermissionsApi();

defineComponent({ name: 'UserPermissions' });

// настройка колонок
tableStore.columns = [
    {
        key: 'id',
        title: 'ID права',
        searchable: true,
        sortable: true,
        headClass: 'rules-th-id',
    },

    {
        key: 'title',
        title: 'Наименование действия',
        searchable: true,
        sortable: true,
        headClass: '',
        component: PermissionTitleTd,
    }
];

// получение данных сразу для всех прав
permissionsApi.getList();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Управление правами на действия")
      ])),
      _: 1
    }),
    _createVNode(Card, { style: {"position":"relative"} }, {
      default: _withCtx(() => [
        (_unref(permissionsApi).loading)
          ? (_openBlock(), _createBlock(Loader, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(CardTitle, null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Список прав и действий")
          ])),
          _: 1
        }),
        _createVNode(TableSearch, { "use-store": _unref(useSyncRulesStore) }, null, 8, ["use-store"]),
        _createVNode(Row, { class: "tab-content tab-transparent-content" }, {
          default: _withCtx(() => [
            _createVNode(MinWidthAndScroll, { "min-width": 400 }, {
              default: _withCtx(() => [
                _createVNode(Table, { "use-store": _unref(useSyncRulesStore) }, {
                  default: _withCtx(() => [
                    _createVNode(PermissionAdd)
                  ]),
                  _: 1
                }, 8, ["use-store"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(TablePagination, {
          class: "tab-content tab-transparent-content",
          "use-store": _unref(useSyncRulesStore)
        }, null, 8, ["use-store"])
      ]),
      _: 1
    })
  ], 64))
}
}

})