<script setup lang="ts">
import { defineProps, watch, ref, onUnmounted } from 'vue';
import { tkErrorList } from './tkErrorList';

interface IList {
    stringifedJson?: string | Record<string, any>;
}

type TList = {
    code: string,
    message: string
}

const props = defineProps<IList>();

// helpers
const matchErrorCode = (data: any): TList[] =>{
    const result: TList[] = [];

    data.forEach((message: any) => {
        if (message.code) {
            result.push({
                code: message.code + '',
                message: ((tkErrorList as Record<string, string>)?.[message.code] ?? '') + '',
            });
        }
    })

    return result;
}


const prepareList = (data: any): TList[] => {
    if (!data) { return [] }

    const result: TList[] = [];

    if (typeof data === 'string') {
        try {
            const json = JSON.parse(data);
            if (Array.isArray(json.messages)) {
                result.push(...matchErrorCode(json.messages));
            }
        }
        catch(e) {

        }
    }
    
    return result;
}

// data
const list = ref<TList[]>(prepareList(props.stringifedJson));
watch(props, (v) => { list.value = prepareList(v) });
onUnmounted(() => { list.value = [] });
</script>

<template>
    <div v-if="list.length" class="tk-error-list">
        <p class="tk-error-list__title">Ошибки:</p>
        <p v-for="item of list" :key="item.code">
            <span>{{ item.code }}:</span>{{ item.message }}
        </p>
    </div>
</template>


<style lang="scss">
.tk-error-list {
    &__title {
        margin: 2em 0 0.5em;
        color: #000;
    }

    p span {
        font-weight: 600;
        color: #000;
        padding-right: 10px;
    }
}
</style>