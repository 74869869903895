import { 
	// TPricePostedGetListResDto, 
	// TPricePostedGetItemResDto,
	TPagesGetListResDto,
	TPageDealItemUpdateReqDto,
	TPageSettingItemResDto
} from './types';
export { 
	TPricePostedGetListResDto, 
	TPricePostedGetItemResDto,
} from './types';
import { get, put } from '@/service/bffService';
export { TBffResponseError } from '@/service/bffService';


export type TPriceSearchParams = {
	title?: string;
	url?: string;
}

export const getList = async (page: number, perPage: number, search?: TPriceSearchParams): Promise<TPagesGetListResDto> => {
	const searchParams = Object.entries(search || {}).map(filter=>`${filter[0]}=${encodeURI(filter[1])}`).join('&');
	
	return get<TPagesGetListResDto>(`/api/price-page-edit?perPage=${perPage}&page=${page}&${searchParams}`);
}

export const getItem = async (id: number): Promise<TPageSettingItemResDto> => {
	return get<TPageSettingItemResDto>('/api/price-page-edit/' + id);
}

export const updateItem = async (id: number, data: string): Promise<any> => {
	return put<any, string>('/api/price-page-edit/' + id, data);
}
