import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "value-viewer" }
const _hoisted_2 = ["for"]

import { getUniqId } from '@/utils';


interface IQuestionViewer {
    label?: string;
    value: string;
}

// props

export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionViewer',
  props: {
    label: {},
    value: {}
  },
  setup(__props: any) {

const props = __props;
const inputId = getUniqId();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", { for: _unref(inputId) }, _toDisplayString(props.label ?? ''), 9, _hoisted_2),
    _createElementVNode("p", null, _toDisplayString(props.value ?? ''), 1)
  ]))
}
}

})