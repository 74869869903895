import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cmi-posted-filters" }

import { ref, watch } from 'vue';
// components
import Input from '@/components/kit/Input.vue';
// store 
import { useAsyncPostListStore } from '../store';


export default /*@__PURE__*/_defineComponent({
  __name: 'PostedListFilters',
  setup(__props) {

const tableStore = useAsyncPostListStore();


const uid = ref('');
const firstName = ref('');
const lastName = ref('');
const additionalLastName = ref('');
const email = ref('');

watch([uid, firstName, lastName, additionalLastName, email], ()=>{
    // tableStore.searchBy
    tableStore.filterBy(1, [uid.value]);
    tableStore.filterBy(2, [firstName.value]);
    tableStore.filterBy(3, [lastName.value]);
    tableStore.filterBy(4, [additionalLastName.value]);
    tableStore.filterBy(5, [email.value]);
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tbody", _hoisted_1, [
    _createElementVNode("tr", null, [
      _cache[5] || (_cache[5] = _createElementVNode("td", { colspan: "1" }, " Фильтры: ", -1)),
      _createElementVNode("td", null, [
        _createVNode(Input, {
          modelValue: uid.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((uid).value = $event)),
          type: "search"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("td", null, [
        _createVNode(Input, {
          modelValue: firstName.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((firstName).value = $event)),
          type: "search"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("td", null, [
        _createVNode(Input, {
          modelValue: lastName.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((lastName).value = $event)),
          type: "search"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("td", null, [
        _createVNode(Input, {
          modelValue: additionalLastName.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((additionalLastName).value = $event)),
          type: "search"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("td", null, [
        _createVNode(Input, {
          modelValue: email.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((email).value = $event)),
          type: "search"
        }, null, 8, ["modelValue"])
      ])
    ])
  ]))
}
}

})