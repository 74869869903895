import { Component } from 'vue';
import { TTableStore, TDataRecord } from './tableStore';


type TData = TDataRecord;

interface IDevMode {
	devMode?: boolean;
}

export enum ETableColumnDataType {
	render = 'render',
	component = 'component',
	dataKey = 'dataKey',
}

export interface ITableCell {
	class?: string;
	value?: string;
}

export interface ITableCellProps extends ITableCell {
	dataKey?: string;
	rowData: TData;
	rowIndex: number;
	value?: any;
}

export interface ITableColumn extends IDevMode {
	dataKey?: string | null;
	rowData: TData;
	rowIndex: number;
	value?: any;
	render?: TRender | null;
	component?: Component | null;
	class?: string;
}

export type TRender = (data: TData, value: any, index: number) => string;

export interface ITableTr {
	class?: string,
}

export interface ITableRow extends IDevMode {
	class?: string;

	columns: ITableColumnOpts[];
	component?: Component<ITableRowOpts>;

	rowData: TData;
	rowIndex: number;
}

export interface ITableRowOpts {
	class?: string;
	rowData: TData;
	rowIndex: number;
}

export interface ITableTbody<T extends TDataRecord> extends IDevMode {
	store: TTableStore<T>;
}

export interface ITableColumnOpts {
	// cell
	key: string;
	render?: TRender | null;
	component?: Component | null;
	mapper?: <Input, Output>(value: Input) => Output;
	cellClass?: string;
}


export interface ITable<T extends TDataRecord> extends IDevMode {
	store: TTableStore<T>;
}


export interface ITableHeadColumnOpts {
	key: string;
	sortable?: boolean;

	// head cell
	title: string;
	headComponent?: Component<ITableTHeadColumn>;
	headClass?: string;
	headOrderedClass?: string;
	headOrderedClassAsc?: string;
	headOrderedClassDesc?: string;
}

export interface ITableThead<T extends TDataRecord> extends IDevMode {
	class?: string;
	store: TTableStore<T>;
}

export interface ITableTHeadColumn extends IDevMode {
	title: string;
	class?: string;
	component?: Component;
}

export interface ITablePagination<T extends TDataRecord> extends IDevMode {
	store: TTableStore<T>;
	btnNumber?: number;
	prevBtn: Component<ITablePaginationPrevNextButton>;
	nextBtn: Component<ITablePaginationPrevNextButton>;
	btn: Component<ITablePaginationButton>;
	delimeter?: Component;
}

export interface ITablePaginationButton {
	isActive: boolean;
	index: number;
}
export interface ITablePaginationPrevNextButton {
	isActive: boolean;
}

export interface ITableBodyEmpty {
	title: string;
}