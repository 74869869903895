import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-width-scroll" }

import { defineComponent } from 'vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'MinWidthAndScroll',
  props: {
    minWidth: {}
  },
  setup(__props: any) {

const props = __props;
defineComponent({name: 'MinWidthScroll'});

const minWidthHelper = (): string => {
    if (typeof props.minWidth === 'number') {
        return props.minWidth + 'px'
    }
    return 'none';
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "min-width-scroll-box",
      style: _normalizeStyle({minWidth: minWidthHelper()})
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 4)
  ]))
}
}

})