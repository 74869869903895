import { TSelectOption } from '@/components/kit';

// yes no dictionary
export const dictionaryYesNo = {
	"Да": true,
	"Нет": false
}

export const dictionaryInsurance = {
	"Государственное": "goverment",
	"Частное": "private",
	"Туристическая / Incoming": "incoming",
	"Нет страховки": "none",
}

export const dictionaryVisaType = {
	"Получаю национальную визу D": "visa_d",
	"Получил визу, но еще не в Германии": "out_g",
	"Уже в Германии": "in_g",
}

export const dictionaryGender = {
	"Мужской": "male",
	"Женский": "female",
}


export const dictionaryTarget = {
	"Я хочу застраховаться как можно быстрее, пришлите мне подтверждение": "order",
	"Я хочу сначала проконсультироваться": "consultation",
}
