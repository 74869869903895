import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

import { defineComponent, ref, onUpdated } from 'vue';
// components
import PageHeader from '@/components/kit/PageHeader.vue';
import AllDealsTable from './AllDealsTable.vue';
// routes
import { useRoute } from 'vue-router';
import { redirectTo404, cmiStatuses } from '@/router';



export default /*@__PURE__*/_defineComponent({
  __name: 'CmiList',
  setup(__props) {

const getListType = (): string => {
    const route = useRoute();
    const listType = (route.params?.status ?? '').toString();
    
    if ( !~(Object.values(cmiStatuses) as string[]).indexOf(listType) ) {
        redirectTo404();
        return '';
    }
    return listType;
}

const listType = ref<string>(getListType());

onUpdated(()=>{
    const type = getListType();
    if (type !== listType.value) {
        listType.value = type;
    }
})

defineComponent({ name: 'CmiListPage' });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("ОМС")
      ])),
      _: 1
    }),
    (listType.value === _unref(cmiStatuses).all)
      ? (_openBlock(), _createBlock(AllDealsTable, { key: 0 }))
      : (_openBlock(), _createElementBlock("p", _hoisted_1, " Page type: " + _toDisplayString(listType.value), 1))
  ], 64))
}
}

})