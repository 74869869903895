<script setup lang="ts">
// components
import {
    Card,
    CardTitle,
    Row,
    // tables
    MinWidthAndScroll,
    Table,
    TablePagination,
} from '@/components/kit';
import EmptyTr from './EmptyTr.vue';
import PostStatusTd from './PostStatusTd.vue';
import PostRow from './PostRow.vue';
import PostedListFilters from './PostedListFilters.vue';
// store 
import { isUserCanEditPostCmi } from '@/store';
import { useAsyncPostListStore } from '../store';


const tableStore = useAsyncPostListStore();

tableStore.columns = [

    {
        key: 'status',
        title: 'Статус',
        component: PostStatusTd,
    },

    {
        key: 'uid',
        title: 'ID',
        searchable: true,
        filterable: true,
    },

    {
        key: 'firstName',
        title: 'Имя',
        searchable: true,
        filterable: true,
    },
    
    {
        key: 'lastName',
        title: 'Фамилия',
        searchable: true,
        filterable: true,
    },
    
    {
        key: 'additionalLastName',
        title: 'ФИО супруга',
        searchable: true,
        filterable: true,
    },
    
    {
        key: 'email',
        title: 'email',
        searchable: true,
        filterable: true,
    },
];

tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
tableStore.row = {
    component: PostRow
}

// получение данных
if (isUserCanEditPostCmi()) {
    tableStore.getData()
}
</script>

<template>

    <Card v-if="!isUserCanEditPostCmi()" style="position:relative">
        <CardTitle>Нет прав на просмотр данных</CardTitle>
    </Card>

    <Card v-if="isUserCanEditPostCmi()" style="position:relative">
        <!-- <Loader v-if="tableStore.loading"/> -->
        <CardTitle>Список отправленных писем</CardTitle>

        <Row>
            <MinWidthAndScroll :minWidth="900">
                <Table :use-store="useAsyncPostListStore">
                    <PostedListFilters />
                </Table>
            </MinWidthAndScroll>
        </Row>

        <TablePagination
            class="tab-content tab-transparent-content"
            :use-store="useAsyncPostListStore"
        />
    </Card>
</template>