<script setup lang="ts">
import { defineComponent, onMounted } from 'vue';
// components
import TestPaginationBtn from './TestPaginationBtn.vue';
import TestPaginationBtnNext from './TestPaginationBtnNext.vue';
//
// table
import { Table, TablePagination, useTableSearch } from '@/components/Table';
import { useAsyncDataStore } from './asyncDataStore';


// store
const store = useAsyncDataStore();
// onMounted
store.clearFilters();
onMounted(()=>store.clearFilters())
// setTimeout(()=>store.getData(), 5e3)

// table

store.columns = [
    {
        key: 'id',
        title: '#id',

        // sorting
        sortable: true,
        headOrderedClass: '--ordered',
        headOrderedClassAsc: '--ordered-asc',
        headOrderedClassDesc: '--ordered-desc',

        // searching
        searchable: true,

        // filtering
        filterable: true,
    },

    {
        key: 'title',
        title: 'name',

        // sorting
        sortable: true,
        headOrderedClass: '--ordered',
        headOrderedClassAsc: '--ordered-asc',
        headOrderedClassDesc: '--ordered-desc',
    }
]

defineComponent({name: 'TAsyncTable'});

// search
const searchInput = useTableSearch(useAsyncDataStore);

</script>

<template>
  <h1>Async data table</h1>
  <p>Данные для таблицы передаются <strong>ассинхронно</strong></p>

  <input
    v-model="searchInput"
    type="search"
    placeholder="search"
  ><br>
  <button @click="()=>store.filterBy(0, [1,2,3])">
    filter by id
  </button><br>
  <button @click="()=>{ console.log('click');store.clearFilters()}">
    clear filters
  </button>
  <div>
    now per page: {{ store.paginatePerPage }} &nbsp;
    <button @click="()=>store.setPerPage(store.paginatePerPage + 10)">
      set {{ store.paginatePerPage + 10 }} per page
    </button>
  </div>
  <p>&nbsp;</p>

  <Table :store="useAsyncDataStore">
    <tbody v-if="store.loading">
      <tr>
        <td :colspan="store.columns.length">
          LOADING...
        </td>
      </tr>
    </tbody>
  </Table>

  <p>{{ store.paginateInfo }}</p>

  <TablePagination 
    :store="useAsyncDataStore"
    :btn="TestPaginationBtn"
    :next-btn="TestPaginationBtnNext"
    :prev-btn="TestPaginationBtnNext"
    :delimeter="TestPaginationBtn"
  />
</template>

<style>
.--ordered {
    background: gray;
    position: relative;
}

.--ordered-asc::before {
    content: "ASC";
    position: absolute;
    top: -15px;
    font-size: 12px;
}

.--ordered-desc::before {
    content: "DESC";
    position: absolute;
    top: -15px;
    font-size: 12px;
}
</style>