<script setup lang="ts">
import { defineComponent } from 'vue';

defineComponent({ name: "LabelDescription" });
</script>

<template>
  <span class="label-desc">
    <slot />
  </span>
</template>

<style lang="scss">
    .label-desc {
        display: block;
        margin-top: 10px;
        opacity: .85;
    }
</style>