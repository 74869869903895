<script setup lang="ts">
import { ITableBodyEmpty } from '@/components/Table/types';
import { useAsyncPriceAllListStore } from '../store';

defineProps<ITableBodyEmpty>();
const tableStore = useAsyncPriceAllListStore();
</script>

<template>
  <tbody>
    <tr>
      <td
        v-if="!tableStore.loading"
        :colspan="tableStore.columns.length"
      >
        Ничего не найдено
      </td>
    </tr>
  </tbody>
</template>