import { defineStore } from 'pinia';
import { TRoutes, routesPermissions } from '@/router';


export type TNavy = {
	loading: boolean;
	leftNavyOpen: boolean;
	mobileNavyOpen: boolean;
	userNavyList: TRoutes[];
}

// store
export const useNavyStore = defineStore({
	id: 'navy',
	state: (): TNavy => ({
		loading: true,
		leftNavyOpen: false,
		mobileNavyOpen: false,
		userNavyList: []
	}),
	getters: {},
	actions: {
		toggleLeftNavy() {
			this.leftNavyOpen=!this.leftNavyOpen;
		},

		toggleMobileNavy() {
			this.mobileNavyOpen=!this.mobileNavyOpen;
		},

		closeMobileNavy() {
			this.mobileNavyOpen=false;
		},

		setUserNavyList(permissions: number[] = []) {
			const list: Set<TRoutes> = new Set();

			for (const key in routesPermissions) {
				const tkey = key as TRoutes;
				const routeList = routesPermissions[tkey];

				if (routeList.length) {
					permissions.forEach(perm=>{
						if (~routeList.indexOf(perm)) {
							list.add(tkey);
						}
					})
				}
				else {
					list.add(tkey);
				}
			}
            
			this.userNavyList = [...list.values()];
		}
	},
});