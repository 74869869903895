import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { defineComponent } from 'vue';
import { ITableCellProps } from '@/components/Table/types';



export default /*@__PURE__*/_defineComponent({
  __name: 'TestCell',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

defineComponent({name: 'TCell'});

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", {
    class: _normalizeClass(props.class || '')
  }, [
    _createElementVNode("span", null, [
      _createTextVNode("value: " + _toDisplayString(props.value || '') + ", ", 1),
      _createElementVNode("strong", null, "index: " + _toDisplayString(props.rowIndex), 1),
      _cache[0] || (_cache[0] = _createTextVNode(", ")),
      _createElementVNode("strong", null, "keys: " + _toDisplayString(Object.keys(props.rowData)), 1),
      _cache[1] || (_cache[1] = _createTextVNode(", ")),
      _createElementVNode("strong", null, "dataKey: " + _toDisplayString(props.dataKey), 1)
    ])
  ], 2))
}
}

})