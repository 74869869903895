import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { defineComponent } from 'vue';
import { ITablePaginationPrevNextButton } from '@/components/Table/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'PaginationNextBtn',
  props: {
    isActive: { type: Boolean }
  },
  setup(__props: any) {

defineComponent({name: 'KitTablePaginationNextbtn'});

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["paginate_button page-item next", props.isActive ? '' : 'disabled'])
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("a", {
      "aria-controls": "order-listing",
      class: "page-link"
    }, [
      _createElementVNode("i", { class: "fa fa-arrow-right" })
    ], -1)
  ]), 2))
}
}

})