<script setup lang="ts">
import { ITableCellProps } from '@/components/Table/types';
import { dateIsoToHumanDate } from '@/utils';


const props = defineProps<ITableCellProps>();
</script>

<template>
  <td :class="class" class="post-status-td">
    <div v-if="value" class="badge badge-pill badge-success">{{dateIsoToHumanDate(props.rowData.postedDate)}}</div>
  </td>
</template>

<style lang="scss">
.post-status-td {
  width: 100px;
}
</style>