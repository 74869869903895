<script setup lang="ts">
import { defineComponent, defineProps, ref, watch } from 'vue';
// components
// types
import { ITablePagination } from './types';
// helpers
import { TTableButtonList, mathPages, mathPagesButtons } from './helpers';


const props = defineProps<ITablePagination<any>>();

defineComponent({ name: 'TablePagination' });

const store = props.store();

// создаём ref, чтобы не пересчитывать каждый раз
const page = ref<number>(store.paginatePage);
watch(() => store.paginatePage, () => (page.value = store.paginatePage));

const perPage = ref<number>(store.paginatePerPage);
watch(() => store.paginatePerPage, () => (perPage.value = store.paginatePerPage));

const filtered = ref<number>(store.paginateFiltered);
watch(() => store.paginateFiltered, () => (filtered.value = store.paginateFiltered));

const viewed = ref<number>(store.paginateViewed);
watch(() => store.paginateViewed, () => (viewed.value = store.paginateViewed));

const pages = ref<number>(mathPages(filtered.value, perPage.value));
const btnList = ref<TTableButtonList>(mathPagesButtons(pages.value, page.value, props?.btnNumber || 0));

watch([filtered, viewed, perPage, page], () => {
    pages.value = mathPages(filtered.value, perPage.value);
    btnList.value = mathPagesButtons(pages.value, page.value, props?.btnNumber || 0)
})

const btnClickHandler = (pageIndex: number) => {
    if (store.paginatePage === pageIndex) { return }
    if (0 < pageIndex && pageIndex <= pages.value) {
        store.setPage(pageIndex)
    }
}
</script>

<template>
  <slot />
    
  <component
    :is="props.prevBtn" 
    :is-active="page - 1 > 0"
    @click="()=>btnClickHandler(page - 1)"
  />

  <component
    :is="props.btn" 
    v-for="(btn, k) of btnList[0]"
    :key="k"
    :is-active="btn === page"
    :index="btn"
    @click="()=>btnClickHandler(btn)"
  />

  <component
    :is="props.delimeter"
    v-if="props.delimeter && btnList[1].length" 
  />
    
  <component
    :is="props.btn" 
    v-for="(btn, k) of btnList[1]"
    :key="k"
    :is-active="btn === page"
    :index="btn"
    @click="()=>btnClickHandler(btn)"
  />

  <component
    :is="props.delimeter"
    v-if="props.delimeter && btnList[2].length" 
  />
    
  <component
    :is="props.btn" 
    v-for="(btn, k) of btnList[2]"
    :key="k"
    :is-active="btn === page"
    :index="btn"
    @click="()=>btnClickHandler(btn)"
  />

  <component
    :is="props.nextBtn" 
    :is-active="page < pages"
    @click="()=>btnClickHandler(page + 1)"
  />
</template>