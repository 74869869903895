<script setup lang="ts">
import { defineComponent } from 'vue';
// components
import TestCell from './TestCell.vue';
import TestRow from './TestRow.vue';
import TestThead from './TestThead.vue';
import TestTheadTh from './TestTheadTh.vue';
import TestPaginationBtn from './TestPaginationBtn.vue';
import TestPaginationBtnNext from './TestPaginationBtnNext.vue';
import TestEmptyBody from './TestEmptyBody.vue';
import Input from '@/components/kit/Input.vue';
import KitTablePagination from '@/components/kit/Table/TablePagination.vue';
import KitTable from '@/components/kit/Table/Table.vue';
import KitTableSearch from '@/components/kit/Table/TableSearch.vue';
//
// table
import { ETableOrder, Table, TablePagination, useTableSearch } from '@/components/Table';
import { useSyncDataStore, TTestSyncData } from './syncDataStore';


// store
const store = useSyncDataStore();

// search
const searchValue = useTableSearch(useSyncDataStore);

// установка данных
store.data = [
    {
        id: 100,
        name: 'first row',
        status: true,
        permissions: [1,2],
    },
    {
        id: 200,
        name: 'second row',
        status: true,
        permissions: [5,6],
    },
    {
        id: 300,
        name: 'third row',
        status: true,
        permissions: [8,9,99],
    },

    ...((): TTestSyncData[]=>{
        return new Array(100).fill(null).map((i,k)=>{
            i;
            return {
                id: 300 + k,
                name: Math.random().toString(36),
                permissions: [k + 100],
                status: Math.random() > .5
            }
        })
    })()
];

// store.data = [
//     {
//         id: 100,
//         name: '1 row',
//         status: true,
//         permissions: [1,2],
//     },
//     {
//         id: 100,
//         name: '2 row',
//         status: true,
//         permissions: [4,5],
//     },
//     {
//         id: 100,
//         name: '3 row',
//         status: true,
//         permissions: [1,3],
//     },
//     {
//         id: 300,
//         name: '4 row',
//         status: true,
//         permissions: [1,2],
//     },
// ];

(window as any).__r = store;

// columns
store.columns = [
    {
        // пример с рендером данных
        key: 'id',
        title: '#',
        render: (data, value, index) => {
            return index + 1 + ''
        },
        cellClass: '--index',
        headClass: '--headclass',

        filterable: true,
    },

    {
        // пример с дефолтным режимом
        key: 'id',
        title: 'ID',
        cellClass: '--id',
        sortable: true,
        searchable: true,

        headClass: '--headclass',
        headOrderedClass: '--sortable',
        headOrderedClassAsc: '--sortAsc',
        headOrderedClassDesc: '--sortDesc',
        headComponent: TestTheadTh,

        filterable: true,
    },

    {
        // с компонентом для th
        key: 'name',
        title: 'name',
        cellClass: '--name',
        headComponent: TestTheadTh,
        // фильтруемый
        filterable: true,
        filteringOrder: 2
    },

    {
        // пример с кастомным компонентом
        key: 'status',
        title: 'custom td',
        cellClass: '--custom-td',
        component: TestCell
    },

    {
        // c колбеком для сортировки
        key: 'permissions',
        title: 'perms',
        render(data, value) {
            if (value && Array.isArray(value)) {
                return '(' + value.join('; ') + ')'
            }
            return '()'
        },

        sortable: true,
        sortingCb: (a,b,asc) => {
            const resT = asc ? 1 : -1;
            const resF = ~resT;

            if (Array.isArray(a) && Array.isArray(b)) {
                return a?.[1] > b?.[1] ? resT : resF;
            }
            return 1;
        },

        searchable: true,
        searchingCb: (search: any, value: TTestSyncData['permissions'], row)=>{
            row;
            const int = parseInt(search);
            console.log('int', int)
            if (isNaN(int)) {
                return true;
            }
            // return true;
            return Boolean(~value.indexOf(int));
        },

        filterable: true,
        filteringOrder: 1,
        filteringCb: (search: number[], value: TTestSyncData['permissions'])=>{
            for (let val of value) {
                if (~search.indexOf(val)) { return true }
            }
            return false;
        }
    },
];
TestCell // @dev:

// сортировка
// setInterval(()=>{
//     if (Math.random() > .5) {
//         store.sortBy(1, ETableOrder.asc);
//     }
//     else {
//         store.sortBy(1, ETableOrder.desc);
//     }
// }, 3e3)
ETableOrder// @dev:

// настройка row
store.row = {
    class: 'row--class',
    // component: TestRow, // кастомный компонент
}
TestRow//@dev:

// настройка tbody
store.body = {
    class: '--tbody',
    emptyTitle: '---',
    // emptyComponent: TestEmptyBody // кастомный компонент
}
TestEmptyBody; // @dev:

// настройка thead
store.head = {
    class: '--class-thead',
    // component: TestThead // кастомный компонент
}
TestThead; //@dev:


// пример с динамичеким изменнением колонок таблиц
// const cold = [...store.columns];
// setInterval(()=>{
//     if (Math.random() > .5) {
//         store.columns = [cold[0], cold[1], cold[2]];
//     }
//     else {
//         store.columns = cold
//     }
// }, 3e3)

defineComponent({name: 'SyncDataTable'});
</script>

<template>
  <h1>Sync data table</h1>
  <p>Данные для таблицы передаются синхронно</p>

  <button @click="()=>store.sortBy(1, ETableOrder.asc)">
    id: asc
  </button>
  <button @click="()=>store.sortBy(1, ETableOrder.desc)">
    id: desc
  </button>
  <div>
    search: <Input
      v-model="searchValue"
      type="search"
    /> {{ searchValue }}
  </div>
  <div>
    filter by name: <input
      type="search"
      @input="(e: Event) => store.filterBy(2, e.target?.value ? [e.target.value] : null) "
    >
  </div>
  <div>
    filter by permissions: 
    <button @click="()=>store.filterBy(4, [1])">
      1
    </button>
    <button @click="()=>store.filterBy(4, [1, 99])">
      1, 99
    </button>
  </div>
  <div>
    now per page: {{ store.paginatePerPage }} &nbsp;
    <button @click="()=>store.setPerPage(store.paginatePerPage + 10)">
      set {{ store.paginatePerPage + 10 }} per page
    </button>
  </div>
  <div>
    <button @click="()=>store.clearFilters()">
      clear all filters
    </button>
  </div>

  <Table
    :store="useSyncDataStore"
    class="--table"
  />
        
  <p>{{ store.paginateInfo }}</p>

  <TablePagination 
    :store="useSyncDataStore"
    :btn="TestPaginationBtn"
    :next-btn="TestPaginationBtnNext"
    :prev-btn="TestPaginationBtnNext"
    :delimeter="TestPaginationBtn"
  />

  <p>&nbsp;</p>
  <h1>Sync data table with tpl from KIT</h1>
  <p>&nbsp;</p>
  <div>
    <KitTableSearch :use-store="useSyncDataStore" />
    <p>&nbsp;</p>
    <KitTable :use-store="useSyncDataStore" />
    <p>&nbsp;</p>
    <KitTablePagination :use-store="useSyncDataStore" />
  </div>
</template>