import { defineStore } from 'pinia';
import { getPostedItem, updatePostedItem, TBffResponseError } from '../bff-service';
import { isoToDate } from '@/utils';


export type TCmiPostedItem = {
    // system
    $loading: boolean,
	$error?: string,

    // item
	id: number;
	uid: string;
	firstName: string; 
	lastName: string; 
	additionalLastName: string;
	email: string;

	status: boolean;
	postedDate: Date;
	postedUserName: string;
}

// store
export const useCmiPostedItemStore = defineStore({
	id: 'cmi-posted-item',

	state: (): TCmiPostedItem => ({
        $loading: true,
		$error: void 0,
		// data
		id: NaN,
		uid: '',
        firstName: '',
        lastName: '',
        additionalLastName: '',
        email: '',

        status: true,
        postedDate: new Date(),
        postedUserName: ''
    }),

    actions: {
        getDataById(dealId: number) {
			this.$loading = true;
			this.id = NaN;

			getPostedItem(dealId)
				.then((responseData)=>{
					this.$error = void 0;

					const {
						id,
                        uid,
                        firstName,
                        lastName,
                        additionalLastName,
						email,

                        status,
                        postedDate = '',
                        postedUserName = '',
					} = responseData;

					Object.assign(
						this, 
						{
							id,
                            uid,
                            firstName,
                            lastName,
                            additionalLastName,
							email,

                            status,
                            postedDate: isoToDate(postedDate) ?? new Date(),
                            postedUserName,
						}
					);
				})
				.catch((e: TBffResponseError)=>{
					this.$error = [e.code, e.error, `REQUEST ID: ${e.requestId}`].join('. ');
				})
				.finally(()=>{
					this.$loading = false;
				});
		},

		saveDataById() {
			this.$loading = true;

			updatePostedItem(this.id, this.status, this.postedDate.toISOString())
			.then(()=>{
				this.$error = void 0;	
			})
			.catch((e: TBffResponseError)=>{
				this.$error = [e.code, e.error, `REQUEST ID: ${e.requestId}`].join('. ');
			})
			.finally(()=>{
				this.$loading = false;
			});
		}
    }

});