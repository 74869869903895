import { defineStore } from 'pinia';
import { getItem, updateItem, TBffResponseError } from '../bff-service';
import { isoToDate, dateToUtcWithTimezone } from '@/utils';
import { TPageDealItemUpdateReqDto } from '../types';


type TPageEditItemStore = {
	// system
	$loading: boolean;
	$error?: TBffResponseError;
	// data
	id: number;
	title: string;
	url: string;
	editorOptions: Record<string, any>;
	pageContent: Record<string, any>;
	isUpdated: boolean;
	newPriceValue: string;
	tariffPrice: string; 
}

// store
export const usePageEditItemStore = defineStore({
	id: 'page-item',

	state: (): TPageEditItemStore => ({
		$loading: true,
		$error: void 0,
		// data
		id: NaN,
		title: '',
		url: '',
		editorOptions: {},
		// добавить pageContent
		// @dev: 
		pageContent: {},
		isUpdated: false,
		newPriceValue: '',
		tariffPrice: ''
	}),

	actions: {
		getDataById(dealId: number) {
			this.$loading = true;
			this.id = NaN;

			getItem(dealId)
				.then((responseData) => {
					this.$error = void 0;
					// Пример установки значений store
					this.id = responseData.id;
					this.title = responseData.title;
					this.url = responseData.url;
					this.editorOptions = responseData.editor_options;
					
					this.pageContent = JSON.parse(responseData.page_content);
					this.isUpdated = false;
				})
				.catch((e: TBffResponseError) => {
					this.$error = e;
				})
				.finally(() => {
					this.$loading = false;
				});
		},

		updateData(data: string) {
			this.$loading = true;

			const dealId = this.id;

			updateItem(dealId, data)
				.then(() => {
					this.$error = void 0;
				})
				.catch((e: TBffResponseError) => {
					this.$error = e;
				})
				.finally(() => {
					this.$loading = false;
				});
		},

	},


	getters: {
		dto(): TPageDealItemUpdateReqDto {
			return {
				editor_options: this.editorOptions,
			}
		}
	},
});