import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

import { defineComponent } from 'vue';
// types
import { ITableThead } from './types';
import { ETableOrder } from './tableStore';
// components
import Tr from './Tr.vue';
import THeadColumn from './THeadColumn.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'Thead',
  props: {
    class: {},
    store: {},
    devMode: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
defineComponent({ name: 'TableThead' });

// store
const store = props.store();
const componentMode = Boolean(store?.head?.component);


const tdClassHelper = (index: number): string => {
    const result: string[] = [];

    const colData = store.columns?.[index];

    if (!colData) { return '' }

    if (colData.headClass) {
        result.push(colData.headClass)
    }

    if (colData.sortable && (colData.headOrderedClass || colData.headOrderedClassAsc || colData.headOrderedClassDesc)) {

        if (colData.headOrderedClass) {
            result.push(colData.headOrderedClass)
        }

        const colOrder = store.order?.[index];
        if (colOrder) {
            switch (colOrder.type) {
                case ETableOrder.asc:
                    result.push(colData.headOrderedClassAsc || '')
                    break;
                case ETableOrder.desc:
                    result.push(colData.headOrderedClassDesc || '')
                    break;
                default:
                    break;
            }
        }

    }

    return result.join(' ');
}

const orderHandler = (index: number) => {
    store.sortToogle(index);
}

return (_ctx: any,_cache: any) => {
  return (_unref(componentMode))
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(store)?.head?.component), {
        key: 0,
        class: _normalizeClass(_unref(store)?.head?.class || '')
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default"),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).columns, (colData, ck) => {
            return (_openBlock(), _createBlock(THeadColumn, {
              key: ck,
              class: _normalizeClass(tdClassHelper(ck)),
              title: colData.title,
              component: colData.headComponent || undefined,
              onClick: ($event: any) => (( is=> is ? (()=>orderHandler(ck)) : undefined )(colData.sortable))
            }, null, 8, ["class", "title", "component", "onClick"]))
          }), 128))
        ]),
        _: 3
      }, 8, ["class"]))
    : (_openBlock(), _createElementBlock("thead", {
        key: 1,
        class: _normalizeClass(_unref(store)?.head?.class || '')
      }, [
        _renderSlot(_ctx.$slots, "default"),
        _createVNode(Tr, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).columns, (colData, ck) => {
              return (_openBlock(), _createBlock(THeadColumn, {
                key: ck,
                class: _normalizeClass(tdClassHelper(ck)),
                title: colData.title,
                component: colData.headComponent || undefined,
                onClick: ($event: any) => (( is=> is ? (()=>orderHandler(ck)) : undefined )(colData.sortable))
              }, null, 8, ["class", "title", "component", "onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ], 2))
}
}

})