export * from './dates';
export * from './status';
export * from './localStorage';
export * from './objectMaps';
export * from './countries';

export const getUniqId = () => {
	return 'u' + Math.random().toString(36).slice(2)
}


import { statusColorHelper } from './status'

const aaa = statusColorHelper({
	1: {
		title: 'aaa',
		manual: ['2', '3', '4'],
		error: '4',
		success: '2'
	},

	2: {
		title: 'asa',
		manual: ['1'],
		error: '4',
		success: '3'
	},

	3: {
		title: 'asabb',
		manual: [],
		error: '4',
		success: '3'
	}
});