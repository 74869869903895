import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { defineComponent } from 'vue';
import { ITablePaginationPrevNextButton } from '@/components/Table/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'TestPaginationBtnNext',
  props: {
    isActive: { type: Boolean }
  },
  setup(__props: any) {

defineComponent({ name: 'TPaginationBtn' });

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["--btn", props.isActive ? '--active' : ''])
  }, _toDisplayString('<'), 2))
}
}

})