import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge badge-pill badge-success"
}

import { ITableCellProps } from '@/components/Table/types';
import { dateIsoToHumanDate } from '@/utils';



export default /*@__PURE__*/_defineComponent({
  __name: 'PostStatusTd',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", {
    class: _normalizeClass([_ctx.class, "post-status-td"])
  }, [
    (_ctx.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(dateIsoToHumanDate)(props.rowData.postedDate)), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})