import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { defineComponent, onMounted } from 'vue';
// components
import {
    Card,
    CardTitle,
    Row,
    MinWidthAndScroll,
    Table,
    TablePagination,
} from '@/components/kit';
import AllDealsFilters from './components/AllDealsFilters.vue';
import { EmptyTr, Row as TableRow } from '@/modules/prices/components';
// store
import { useAsyncPriceAllListStore } from './store/detailsListStore';
import { isUserCanEditPrices, isUserCanViewPrices } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'PriceList',
  setup(__props) {

const tableStore = useAsyncPriceAllListStore();

// настройки талицы
tableStore.columns = [
    {
        key: 'id',
        title: '#',
        // component: ActionsTd,
    },
    {
        key: 'title',
        title: 'Имя страницы',
        searchable: true,
        filterable: true,
    },

    {
        key: 'url',
        title: 'URL',
        searchable: true,
        filterable: true,
    },
];

tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
    tableStore.row = {};
}
tableStore.row.component = TableRow; 

onMounted(() => {
    // Очищаем фильтры при возвращении на главную
    tableStore.clearFilters(); 
    tableStore.getData(); 
});

// Получение данных при наличии прав
if (isUserCanViewPrices()) {
    tableStore.getData();
}

defineComponent({name: 'AllPriceTable'});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(isUserCanViewPrices)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(isUserCanEditPrices)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 1,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Список страниц")
              ])),
              _: 1
            }),
            _createVNode(_unref(Row), null, {
              default: _withCtx(() => [
                _createVNode(_unref(MinWidthAndScroll), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Table), { "use-store": _unref(useAsyncPriceAllListStore) }, {
                      default: _withCtx(() => [
                        _createVNode(AllDealsFilters)
                      ]),
                      _: 1
                    }, 8, ["use-store"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(TablePagination), {
              class: "tab-content tab-transparent-content",
              "use-store": _unref(useAsyncPriceAllListStore)
            }, null, 8, ["use-store"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})