<script setup lang="ts">
import { ENavy, router } from '@/router';

interface IRow {
    rowData: {
        id: string | number;
    }
};

const props = defineProps<IRow>();

const clickHandler = (e: Event)=>{
    e.preventDefault();
    e.stopPropagation();
    
    if (props.rowData.id) {
        router.push(ENavy.cmiPostEditor.replace(':id', props.rowData.id.toString()));
    }
}
</script>

<template>
    <tr class="cmi-table-row" @click="clickHandler">
      <slot />
    </tr>
</template>

<style lang="scss">
.cmi-table-row {
    cursor: pointer;
}
</style>