import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

import { defineComponent } from 'vue';
// components
import Tr from './Tr.vue';
import Column from './Column.vue';
// types
import { ITableRow } from './types';



export default /*@__PURE__*/_defineComponent({
  __name: 'Row',
  props: {
    class: {},
    columns: {},
    component: {},
    rowData: {},
    rowIndex: {},
    devMode: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
defineComponent({ name: 'TableRow' });

const componentMode = Boolean(props.component);

return (_ctx: any,_cache: any) => {
  return (_unref(componentMode))
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(props.component), {
        key: 0,
        class: _normalizeClass(props.class || ''),
        "row-data": props.rowData,
        "row-index": props.rowIndex
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.columns, (colData, ck) => {
            return (_openBlock(), _createBlock(Column, {
              key: ck,
              class: _normalizeClass(colData?.cellClass || ''),
              "data-key": colData.key,
              component: colData.component,
              render: colData.render,
              value: colData.key && props.rowData?.[colData.key] || undefined,
              "row-data": props.rowData,
              "row-index": props.rowIndex,
              "dev-mode": props.devMode
            }, null, 8, ["class", "data-key", "component", "render", "value", "row-data", "row-index", "dev-mode"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["class", "row-data", "row-index"]))
    : (_openBlock(), _createBlock(Tr, {
        key: 1,
        class: _normalizeClass(props.class || '')
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.columns, (colData, ck) => {
            return (_openBlock(), _createBlock(Column, {
              key: ck,
              class: _normalizeClass(colData?.cellClass || ''),
              "data-key": colData.key,
              component: colData.component,
              render: colData.render,
              value: colData.key && props.rowData?.[colData.key] || undefined,
              "row-data": props.rowData,
              "row-index": props.rowIndex,
              "dev-mode": props.devMode
            }, null, 8, ["class", "data-key", "component", "render", "value", "row-data", "row-index", "dev-mode"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["class"]))
}
}

})