import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-avatar-td" }
const _hoisted_2 = ["src", "alt"]

import { defineComponent, ref, watch } from 'vue';
import { ITableCellProps } from '@/components/Table/types';
import defaultUserImage from '@/assets/img/user.svg';



export default /*@__PURE__*/_defineComponent({
  __name: 'UserAvatarTd',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;
defineComponent({name: 'UserAvatarTd'});

const imgRef = ref<HTMLImageElement | null>(null);
watch(imgRef, ()=>{
    if (!imgRef.value) { return }
    imgRef.value.onerror = function(){
        this.src = defaultUserImage;
    }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", _hoisted_1, [
    _createElementVNode("img", {
      ref_key: "imgRef",
      ref: imgRef,
      src: props.value ?? _unref(defaultUserImage),
      alt: props.rowData?.['name'] ?? '',
      class: "user-image-avatar-td"
    }, null, 8, _hoisted_2)
  ]))
}
}

})