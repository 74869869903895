import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { defineComponent } from 'vue';
// components
import Cell from './Cell.vue';
// types
import { ITableColumn, ETableColumnDataType } from './types';



export default /*@__PURE__*/_defineComponent({
  __name: 'Column',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    render: { type: [Function, null] },
    component: {},
    class: {},
    devMode: { type: Boolean }
  },
  setup(__props: any) {

defineComponent({ name: 'TableColumn' });
const props = __props;

const getMode = (): ETableColumnDataType | void => {
    if (props.render) { return ETableColumnDataType.render }
    if (props.component) { return ETableColumnDataType.component }
    if (props.dataKey) { return ETableColumnDataType.dataKey }
};

const mode = getMode();

// prepared data for cell
let data = '';

if (mode != ETableColumnDataType.component) {
    switch (mode) {
        case ETableColumnDataType.render:
            try {
                data = props?.render?.(props.rowData, props.value, props.rowIndex) || ''
            }
            catch (e) {
                props.devMode && console.error(e);
            }
            break;

        case ETableColumnDataType.dataKey:
            data = props.value && props.value.toString() || '';
            break;

        default:
            break;
    }
}

props.devMode && (() => {
    console.log({
        ...props
    })
})()

return (_ctx: any,_cache: any) => {
  return (_unref(mode)==='component' && props?.component)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(props.component), {
        key: 0,
        "data-key": props.dataKey,
        "row-data": props.rowData,
        "row-index": props.rowIndex,
        value: props.value,
        class: _normalizeClass(props.class || '')
      }, null, 8, ["data-key", "row-data", "row-index", "value", "class"]))
    : (_openBlock(), _createBlock(Cell, {
        key: 1,
        class: _normalizeClass(props.class),
        value: _unref(data)
      }, null, 8, ["class", "value"]))
}
}

})