import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

import { defineComponent } from 'vue';
// components
import Row from './Row.vue';
// types
import { ITableTbody, ITableColumnOpts } from './types';



export default /*@__PURE__*/_defineComponent({
  __name: 'Tbody',
  props: {
    store: {},
    devMode: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
defineComponent({ name: 'TableTbody' });

const store = props.store();

const uniqKyeHelper = (index: number) => {
    return Date.now() + Math.random().toString(36) + index;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tbody", {
    class: _normalizeClass(_unref(store)?.body?.class || '')
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).viewData, (rowData, k) => {
      return (_openBlock(), _createBlock(Row, {
        key: uniqKyeHelper(k),
        "row-data": rowData,
        "row-index": k,
        columns: _unref(store).columns as ITableColumnOpts[],
        component: _unref(store)?.row?.component || undefined,
        class: _normalizeClass([
        _unref(store)?.row?.class || '',
        !((k as number) % 2) && _unref(store)?.row?.oddClass || ''
      ].join(' ')),
        "dev-mode": props.devMode
      }, null, 8, ["row-data", "row-index", "columns", "component", "class", "dev-mode"]))
    }), 128))
  ], 2))
}
}

})