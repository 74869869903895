import { generateSyncDataTableStore } from '@/components/Table/tableStore';


export type TTestSyncData = {
	id: number;
	name: string;
	status: boolean;
	permissions: number[];
}

export const useSyncDataStore = generateSyncDataTableStore<TTestSyncData>({
	initialState: {    
		paginateInfoTpl: 'total: _TOTAL_'
	},

	onDataStateChange(useStore) {
		const store = useStore();
		console.log('onDataStateChange', store.paginateFiltered)
	}
});

// const tableStore = useSyncDataStore();