import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { defineComponent, ref, watch } from 'vue';
// components
// types
import { ITablePagination } from './types';
// helpers
import { TTableButtonList, mathPages, mathPagesButtons } from './helpers';



export default /*@__PURE__*/_defineComponent({
  __name: 'TablePagination',
  props: {
    store: {},
    btnNumber: {},
    prevBtn: {},
    nextBtn: {},
    btn: {},
    delimeter: {},
    devMode: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

defineComponent({ name: 'TablePagination' });

const store = props.store();

// создаём ref, чтобы не пересчитывать каждый раз
const page = ref<number>(store.paginatePage);
watch(() => store.paginatePage, () => (page.value = store.paginatePage));

const perPage = ref<number>(store.paginatePerPage);
watch(() => store.paginatePerPage, () => (perPage.value = store.paginatePerPage));

const filtered = ref<number>(store.paginateFiltered);
watch(() => store.paginateFiltered, () => (filtered.value = store.paginateFiltered));

const viewed = ref<number>(store.paginateViewed);
watch(() => store.paginateViewed, () => (viewed.value = store.paginateViewed));

const pages = ref<number>(mathPages(filtered.value, perPage.value));
const btnList = ref<TTableButtonList>(mathPagesButtons(pages.value, page.value, props?.btnNumber || 0));

watch([filtered, viewed, perPage, page], () => {
    pages.value = mathPages(filtered.value, perPage.value);
    btnList.value = mathPagesButtons(pages.value, page.value, props?.btnNumber || 0)
})

const btnClickHandler = (pageIndex: number) => {
    if (store.paginatePage === pageIndex) { return }
    if (0 < pageIndex && pageIndex <= pages.value) {
        store.setPage(pageIndex)
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default"),
    (_openBlock(), _createBlock(_resolveDynamicComponent(props.prevBtn), {
      "is-active": page.value - 1 > 0,
      onClick: _cache[0] || (_cache[0] = ()=>btnClickHandler(page.value - 1))
    }, null, 8, ["is-active"])),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(btnList.value[0], (btn, k) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(props.btn), {
        key: k,
        "is-active": btn === page.value,
        index: btn,
        onClick: ()=>btnClickHandler(btn)
      }, null, 8, ["is-active", "index", "onClick"]))
    }), 128)),
    (props.delimeter && btnList.value[1].length)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(props.delimeter), { key: 0 }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(btnList.value[1], (btn, k) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(props.btn), {
        key: k,
        "is-active": btn === page.value,
        index: btn,
        onClick: ()=>btnClickHandler(btn)
      }, null, 8, ["is-active", "index", "onClick"]))
    }), 128)),
    (props.delimeter && btnList.value[2].length)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(props.delimeter), { key: 1 }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(btnList.value[2], (btn, k) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(props.btn), {
        key: k,
        "is-active": btn === page.value,
        index: btn,
        onClick: ()=>btnClickHandler(btn)
      }, null, 8, ["is-active", "index", "onClick"]))
    }), 128)),
    (_openBlock(), _createBlock(_resolveDynamicComponent(props.nextBtn), {
      "is-active": page.value < pages.value,
      onClick: _cache[1] || (_cache[1] = ()=>btnClickHandler(page.value + 1))
    }, null, 8, ["is-active"]))
  ], 64))
}
}

})