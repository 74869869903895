import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue';
import { usePageEditItemStore } from './store/dealItemStore';
import { TJsonExtensionProps } from 'litsystems-vue-json-editor-dev/src';



export default /*@__PURE__*/_defineComponent({
  __name: 'PriceEditInput',
  props: {
    type: {},
    propKey: {},
    keyForVue: {},
    parentKeys: {},
    humanKey: {},
    childs: {},
    value: { type: [String, Number, Boolean, null] },
    className: {},
    extension: {},
    hidden: { type: Boolean },
    useStore: {}
  },
  setup(__props: any) {

const props = __props;

const value = ref<string>((props.value ?? '').toString());

const editStore = usePageEditItemStore();

const onChangeHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    var inputValue = (e.target as HTMLInputElement).value;

    if (inputValue !== props.value) {
        editStore.isUpdated = true; // установка флага обновления

        // если прошли валидацию, то устанавливаем новое значение
        value.value = inputValue;

        const jsonStore = props.useStore();
        jsonStore.setValueByKeys(
            [...props["parentKeys"], props["propKey"]].slice(1), // подготавливаем ключи
            inputValue
        );
    }
}

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    class: "form-control title-price",
    onInput: onChangeHandler,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event))
  }, null, 544)), [
    [_vModelText, value.value]
  ])
}
}

})