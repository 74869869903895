import { TAsyncDataTableStore, TSyncDataTableStore } from './tableStore';


// math pagination
export type TTableButtonList = [number[], number[], number[]];

export const mathPages = (total: number, perPage: number): number => {
	return Math.trunc(total / perPage) + (total % perPage ? 1 : 0);
}

const generateFirst = (length: number): number[] => {
	return new Array(length).fill(0).map((i, k) => k + 1)
}
const generateLast = (length: number, total: number): number[] => {
	return new Array(length).fill(0).map((i, k) => total - k).reverse()
}

const generateMiddle = (length: number, index: number) => {
	const start = index - Math.trunc((length - 1) / 2);
	return new Array(length).fill(0).map((i, k) => k + start)
}

export const mathPagesButtons = (pagesNumber: number, activePageIndex: number, length?: number): TTableButtonList => {
	const firstBtns: number[] = [];
	const centerBtns: number[] = [];
	const lastBtns: number[] = [];

	const maxLength = (!length || length < 5) ? 5 : length;

	if (pagesNumber === 0) {
		return [[1], [], []];
	}

	if (pagesNumber <= maxLength) {
		firstBtns.push(...new Array(pagesNumber).fill(null).map((i, k) => k + 1))
	}
	else {
		const first = Math.trunc(maxLength / 2) + 1;
		const last = maxLength - first;

		if (activePageIndex < first) {
			firstBtns.push(...generateFirst(first));
			lastBtns.push(...generateLast(last, pagesNumber));
		}

		else if (activePageIndex >= (pagesNumber - last)) {
			firstBtns.push(...generateFirst(last - 1));
			lastBtns.push(...generateLast(last + 2, pagesNumber));
		}

		else if (activePageIndex === first) {
			firstBtns.push(...generateFirst(first + 1));
			lastBtns.push(...generateLast(last - 1, pagesNumber));
		}

		else {
			firstBtns.push(...generateFirst(1));
			lastBtns.push(...generateLast(1, pagesNumber));
			centerBtns.push(...generateMiddle(maxLength - 2, activePageIndex))
		}
	}

	return [firstBtns, centerBtns, lastBtns];
}

// table store filters to Record<string, string>

type TGetKeyOfObject<O extends {}> = keyof O;
type TObjectToObjectMap<ObjectA extends {}, ObjectB extends {}> = Partial<{ [k in keyof ObjectA]: TGetKeyOfObject<ObjectB> }>;
type TInferTableStoreDataType<S> = S extends ReturnType<TAsyncDataTableStore<infer U> | TSyncDataTableStore<infer U>> ? U : never;


export const mapTableFiltersToSearchParams = <
	T extends ReturnType<TAsyncDataTableStore<any> | TSyncDataTableStore<any>>,
	S extends { [k: string]: string }
>	( store: T, search: S, map: TObjectToObjectMap<TInferTableStoreDataType<typeof store>, S> ): S => {
	const result: Record<string, string> = {};

	store.filters.forEach(filter => {
		if (!filter || !filter.column || typeof filter.column !== 'string') { return }

		const column = filter.column;

		if (column in map) {
			const searchColumn = map[column] as keyof S;
			if (searchColumn in search) {
				const value = (filter.value?.[0] ?? '').toString();
				search[searchColumn] = value;
			}
		}
	});

	return Object.assign(search, result);
}