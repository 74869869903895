import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {
    Card,
    CardTitle,
    Row,
    // tables
    MinWidthAndScroll,
    Table,
    TablePagination,
} from '@/components/kit';
import EmptyTr from './EmptyTr.vue';
import PostStatusTd from './PostStatusTd.vue';
import PostRow from './PostRow.vue';
import PostedListFilters from './PostedListFilters.vue';
// store 
import { isUserCanEditPostCmi } from '@/store';
import { useAsyncPostListStore } from '../store';



export default /*@__PURE__*/_defineComponent({
  __name: 'PostTable',
  setup(__props) {

// components
const tableStore = useAsyncPostListStore();

tableStore.columns = [

    {
        key: 'status',
        title: 'Статус',
        component: PostStatusTd,
    },

    {
        key: 'uid',
        title: 'ID',
        searchable: true,
        filterable: true,
    },

    {
        key: 'firstName',
        title: 'Имя',
        searchable: true,
        filterable: true,
    },
    
    {
        key: 'lastName',
        title: 'Фамилия',
        searchable: true,
        filterable: true,
    },
    
    {
        key: 'additionalLastName',
        title: 'ФИО супруга',
        searchable: true,
        filterable: true,
    },
    
    {
        key: 'email',
        title: 'email',
        searchable: true,
        filterable: true,
    },
];

tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
tableStore.row = {
    component: PostRow
}

// получение данных
if (isUserCanEditPostCmi()) {
    tableStore.getData()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(isUserCanEditPostCmi)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(isUserCanEditPostCmi)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 1,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Список отправленных писем")
              ])),
              _: 1
            }),
            _createVNode(_unref(Row), null, {
              default: _withCtx(() => [
                _createVNode(_unref(MinWidthAndScroll), { minWidth: 900 }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Table), { "use-store": _unref(useAsyncPostListStore) }, {
                      default: _withCtx(() => [
                        _createVNode(PostedListFilters)
                      ]),
                      _: 1
                    }, 8, ["use-store"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(TablePagination), {
              class: "tab-content tab-transparent-content",
              "use-store": _unref(useAsyncPostListStore)
            }, null, 8, ["use-store"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})