<script setup lang="ts">
import { getUniqId } from '@/utils';


interface IQuestionViewer {
    label?: string;
    value: string;
}

// props
const props = defineProps<IQuestionViewer>();
const inputId = getUniqId();
</script>

<template>
  <div class="value-viewer">
    <label :for="inputId">{{ props.label ?? '' }}</label>
    <p>{{ props.value ?? '' }}</p>
  </div>
</template>


<style lang="scss">
.value-viewer {
  margin: 20px 0;

  label[for] {
    cursor: initial;
    color: #000007;
  }

  p {
    font-size: 1em;
    line-height: 1.5;
    color: #000;
    font-weight: 600;
    margin: 0;
  }
}
</style>