<script setup lang="ts">
import { defineComponent } from 'vue';
import defaultUserImage from '@/assets/img/user.svg';
// routes
import { useRoute } from 'vue-router';
import { redirectTo404 } from '@/router';
// components
import PageHeader from '@/components/kit/PageHeader.vue';
import Card from '@/components/kit/Card.vue';
import Row from '@/components/kit/Row.vue';
import Loader from '@/components/Loader.vue';
// store
import { isUserCanEditUser } from '@/store';


defineComponent({ name: 'UserPage' });

const getUserInfo = () => {
    const userId = getUserId();
    if (userId) {
        alert('get data for user id: ' + userId)
    }
    else {
        redirectTo404()
    }
    
}

const getUserId = (): number => {
    const route = useRoute();
    const userId = parseInt(route.params?.userId + '');

    if (userId && Number.isInteger(userId) && userId > 0 && userId.toString() === route.params?.userId) {
        return userId
    }
    else {
        return NaN
    }
}


getUserInfo();

const store = {

    id: 101,
    name: 'Пётр Петров',
    email: 'c@d.com',
    avatar: '',
    description: 'sadasdas asdasdfsdf sadfsdfsadfsadf sdfsadfsadfsadfasdf sdfasdfasdfasdf',

    loading: false

};

store;
</script>

<template>
  <PageHeader>Профиль пользователя</PageHeader>
  <Card style="position:relative">
    <Loader v-if="store.loading" />

    <Row>
      <div class="col-lg-4">
        <div class="border-bottom text-center pb-4">
          <img
            :src="store.avatar || defaultUserImage"
            :alt="store.name"
            class="img-lg rounded-circle mb-3"
          >
          <h3>{{ store.name }}</h3>
          <p>{{ store.description }}</p>
        </div>
        <div class="border-bottom py-4">
          <p>{{ store.email }}</p>
        </div>
      </div>

      <div class="col-lg-8">
        <div
          v-if="isUserCanEditUser()"
          class="mt-4 py-2 border-top border-bottom"
        >
          <ul class="nav profile-navbar">
            <li class="nav-item">
              <a
                class="nav-link"
                href="#"
              >
                <i class="mdi mdi-account-outline" /> Info </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link active"
                href="#"
              >
                <i class="mdi mdi-newspaper" /> Feed </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#"
              >
                <i class="mdi mdi-calendar" /> Agenda </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#"
              >
                <i class="mdi mdi-attachment" /> Resume </a>
            </li>
          </ul>
        </div>
      </div>
    </Row>
  </Card>
</template>