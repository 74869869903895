<script setup lang="ts">
import { defineComponent, defineProps, ref, watch } from 'vue';
import { ITableCellProps } from '@/components/Table/types';
import defaultUserImage from '@/assets/img/user.svg';


const props = defineProps<ITableCellProps>();
defineComponent({name: 'UserAvatarTd'});

const imgRef = ref<HTMLImageElement | null>(null);
watch(imgRef, ()=>{
    if (!imgRef.value) { return }
    imgRef.value.onerror = function(){
        this.src = defaultUserImage;
    }
})
</script>

<template>
  <td class="user-avatar-td">
    <img
      ref="imgRef"
      :src="props.value ?? defaultUserImage"
      :alt="props.rowData?.['name'] ?? ''"
      class="user-image-avatar-td"
    >
  </td>
</template>

<style lang="scss">
table {
    td.user-avatar-td {
        width: 40px;
    
        img {
            width: 30px;
            height: 100%;
            border-radius: 50%;
        }
    }
}
</style>