import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { defineComponent } from 'vue';
import { ITableTr } from './types';



export default /*@__PURE__*/_defineComponent({
  __name: 'Tr',
  props: {
    class: {}
  },
  setup(__props: any) {

const props = __props; 
defineComponent({ name: 'TableTr'});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(props.class || '')
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})