import { TSelectOption } from '@/components/kit';


export const countriesList: TSelectOption[] = [
	{
		"label": "Австралия",
		"value": "AU"
	},
	{
		"label": "Австрия",
		"value": "AT"
	},
	{
		"label": "Азербайджан",
		"value": "AZ"
	},
	{
		"label": "Аландские острова",
		"value": "AX"
	},
	{
		"label": "Албания",
		"value": "AL"
	},
	{
		"label": "Алжир",
		"value": "DZ"
	},
	{
		"label": "Американское Самоа",
		"value": "AS"
	},
	{
		"label": "Ангилья",
		"value": "AI"
	},
	{
		"label": "Ангола",
		"value": "AO"
	},
	{
		"label": "Андорра",
		"value": "AD"
	},
	{
		"label": "Антарктика",
		"value": "AQ"
	},
	{
		"label": "Антигуа и Барбуда",
		"value": "AG"
	},
	{
		"label": "Аргентина",
		"value": "AR"
	},
	{
		"label": "Армения",
		"value": "AM"
	},
	{
		"label": "Аруба",
		"value": "AW"
	},
	{
		"label": "Афганистан",
		"value": "AF"
	},
	{
		"label": "Багамы",
		"value": "BS"
	},
	{
		"label": "Бангладеш",
		"value": "BD"
	},
	{
		"label": "Барбадос",
		"value": "BB"
	},
	{
		"label": "Бахрейн",
		"value": "BH"
	},
	{
		"label": "Беларусь",
		"value": "BY"
	},
	{
		"label": "Белиз",
		"value": "BZ"
	},
	{
		"label": "Бельгия",
		"value": "BE"
	},
	{
		"label": "Бенин",
		"value": "BJ"
	},
	{
		"label": "Бермуды",
		"value": "BM"
	},
	{
		"label": "Болгария",
		"value": "BG"
	},
	{
		"label": "Боливия",
		"value": "BO"
	},
	{
		"label": "Босния и Герцеговина",
		"value": "BA"
	},
	{
		"label": "Ботсвана",
		"value": "BW"
	},
	{
		"label": "Бразилия",
		"value": "BR"
	},
	{
		"label": "Британская территория в Индийском океане",
		"value": "IO"
	},
	{
		"label": "Бруней",
		"value": "BN"
	},
	{
		"label": "Буркина-Фасо",
		"value": "BF"
	},
	{
		"label": "Бурунди",
		"value": "BI"
	},
	{
		"label": "Бутан",
		"value": "BT"
	},
	{
		"label": "Вануату",
		"value": "VU"
	},
	{
		"label": "Ватикан",
		"value": "VA"
	},
	{
		"label": "Великобритания",
		"value": "GB"
	},
	{
		"label": "Венгрия",
		"value": "HU"
	},
	{
		"label": "Венесуэла",
		"value": "VE"
	},
	{
		"label": "Виргинские острова, Британские",
		"value": "VG"
	},
	{
		"label": "Виргинские острова, США",
		"value": "VI"
	},
	{
		"label": "Внешние малые острова (США)",
		"value": "UM"
	},
	{
		"label": "Восточный Тимор",
		"value": "TL"
	},
	{
		"label": "Вьетнам",
		"value": "VN"
	},
	{
		"label": "Габон",
		"value": "GA"
	},
	{
		"label": "Гаити",
		"value": "HT"
	},
	{
		"label": "Гайана",
		"value": "GY"
	},
	{
		"label": "Гамбия",
		"value": "GM"
	},
	{
		"label": "Гана",
		"value": "GH"
	},
	{
		"label": "Гваделупа",
		"value": "GP"
	},
	{
		"label": "Гватемала",
		"value": "GT"
	},
	{
		"label": "Гвинея",
		"value": "GN"
	},
	{
		"label": "Гвинея-Бисау",
		"value": "GW"
	},
	{
		"label": "Германия",
		"value": "DE"
	},
	{
		"label": "Гернси",
		"value": "GG"
	},
	{
		"label": "Гибралтар",
		"value": "GI"
	},
	{
		"label": "Гондурас",
		"value": "HN"
	},
	{
		"label": "Гонконг",
		"value": "HK"
	},
	{
		"label": "Государство Палестина",
		"value": "PS"
	},
	{
		"label": "Гренада",
		"value": "GD"
	},
	{
		"label": "Гренландия",
		"value": "GL"
	},
	{
		"label": "Греция",
		"value": "GR"
	},
	{
		"label": "Грузия",
		"value": "GE"
	},
	{
		"label": "Гуам",
		"value": "GU"
	},
	{
		"label": "Дания",
		"value": "DK"
	},
	{
		"label": "Демократическая Республика Конго",
		"value": "CD"
	},
	{
		"label": "Джерси",
		"value": "JE"
	},
	{
		"label": "Джибути",
		"value": "DJ"
	},
	{
		"label": "Доминика",
		"value": "DM"
	},
	{
		"label": "Доминиканская Республика",
		"value": "DO"
	},
	{
		"label": "Египет",
		"value": "EG"
	},
	{
		"label": "Замбия",
		"value": "ZM"
	},
	{
		"label": "Западная Сахара",
		"value": "EH"
	},
	{
		"label": "Зимбабве",
		"value": "ZW"
	},
	{
		"label": "Израиль",
		"value": "IL"
	},
	{
		"label": "Индия",
		"value": "IN"
	},
	{
		"label": "Индонезия",
		"value": "ID"
	},
	{
		"label": "Иордания",
		"value": "JO"
	},
	{
		"label": "Ирак",
		"value": "IQ"
	},
	{
		"label": "Иран, Исламская Республика",
		"value": "IR"
	},
	{
		"label": "Ирландия",
		"value": "IE"
	},
	{
		"label": "Исландия",
		"value": "IS"
	},
	{
		"label": "Испания",
		"value": "ES"
	},
	{
		"label": "Италия",
		"value": "IT"
	},
	{
		"label": "Йемен",
		"value": "YE"
	},
	{
		"label": "КНДР (Северная Корея)",
		"value": "KP"
	},
	{
		"label": "Кабо-Верде",
		"value": "CV"
	},
	{
		"label": "Казахстан",
		"value": "KZ"
	},
	{
		"label": "Каймановы острова",
		"value": "KY"
	},
	{
		"label": "Камбоджа",
		"value": "KH"
	},
	{
		"label": "Камерун",
		"value": "CM"
	},
	{
		"label": "Канада",
		"value": "CA"
	},
	{
		"label": "Катар",
		"value": "QA"
	},
	{
		"label": "Кения",
		"value": "KE"
	},
	{
		"label": "Кипр",
		"value": "CY"
	},
	{
		"label": "Киргизия",
		"value": "KG"
	},
	{
		"label": "Кирибати",
		"value": "KI"
	},
	{
		"label": "Китай",
		"value": "CN"
	},
	{
		"label": "Кокосовые острова (Килинг)",
		"value": "CC"
	},
	{
		"label": "Колумбия",
		"value": "CO"
	},
	{
		"label": "Коморы",
		"value": "KM"
	},
	{
		"label": "Конго",
		"value": "CG"
	},
	{
		"label": "Корея",
		"value": "KR"
	},
	{
		"label": "Коста-Рика",
		"value": "CR"
	},
	{
		"label": "Кот-д'Ивуар",
		"value": "CI"
	},
	{
		"label": "Куба",
		"value": "CU"
	},
	{
		"label": "Кувейт",
		"value": "KW"
	},
	{
		"label": "Лаос",
		"value": "LA"
	},
	{
		"label": "Латвия",
		"value": "LV"
	},
	{
		"label": "Лесото",
		"value": "LS"
	},
	{
		"label": "Либерия",
		"value": "LR"
	},
	{
		"label": "Ливан",
		"value": "LB"
	},
	{
		"label": "Ливийская Арабская Джамахирия",
		"value": "LY"
	},
	{
		"label": "Литва",
		"value": "LT"
	},
	{
		"label": "Лихтенштейн",
		"value": "LI"
	},
	{
		"label": "Люксембург",
		"value": "LU"
	},
	{
		"label": "Маврикий",
		"value": "MU"
	},
	{
		"label": "Мавритания",
		"value": "MR"
	},
	{
		"label": "Мадагаскар",
		"value": "MG"
	},
	{
		"label": "Майотта",
		"value": "YT"
	},
	{
		"label": "Макао",
		"value": "MO"
	},
	{
		"label": "Македония",
		"value": "MK"
	},
	{
		"label": "Малави",
		"value": "MW"
	},
	{
		"label": "Малайзия",
		"value": "MY"
	},
	{
		"label": "Мали",
		"value": "ML"
	},
	{
		"label": "Мальдивы",
		"value": "MV"
	},
	{
		"label": "Мальта",
		"value": "MT"
	},
	{
		"label": "Марокко",
		"value": "MA"
	},
	{
		"label": "Мартиника",
		"value": "MQ"
	},
	{
		"label": "Маршалловы острова",
		"value": "MH"
	},
	{
		"label": "Мексика",
		"value": "MX"
	},
	{
		"label": "Микронезия, Федеративные Штаты",
		"value": "FM"
	},
	{
		"label": "Мозамбик",
		"value": "MZ"
	},
	{
		"label": "Молдавия",
		"value": "MD"
	},
	{
		"label": "Монако",
		"value": "MC"
	},
	{
		"label": "Монголия",
		"value": "MN"
	},
	{
		"label": "Монтсеррат",
		"value": "MS"
	},
	{
		"label": "Мьянма",
		"value": "MM"
	},
	{
		"label": "Намибия",
		"value": "NA"
	},
	{
		"label": "Науру",
		"value": "NR"
	},
	{
		"label": "Непал",
		"value": "NP"
	},
	{
		"label": "Нигер",
		"value": "NE"
	},
	{
		"label": "Нигерия",
		"value": "NG"
	},
	{
		"label": "Нидерландские Антильские острова",
		"value": "AN"
	},
	{
		"label": "Нидерланды",
		"value": "NL"
	},
	{
		"label": "Никарагуа",
		"value": "NI"
	},
	{
		"label": "Ниуэ",
		"value": "NU"
	},
	{
		"label": "Новая Зеландия",
		"value": "NZ"
	},
	{
		"label": "Новая Каледония",
		"value": "NC"
	},
	{
		"label": "Норвегия",
		"value": "NO"
	},
	{
		"label": "Объединенные Арабские Эмираты",
		"value": "AE"
	},
	{
		"label": "Оман",
		"value": "OM"
	},
	{
		"label": "Остров Буве",
		"value": "BV"
	},
	{
		"label": "Остров Мэн",
		"value": "IM"
	},
	{
		"label": "Остров Норфолк",
		"value": "NF"
	},
	{
		"label": "Остров Рождества",
		"value": "CX"
	},
	{
		"label": "Остров Святой Елены",
		"value": "SH"
	},
	{
		"label": "Остров Херд и острова Макдональд",
		"value": "HM"
	},
	{
		"label": "Острова Кука",
		"value": "CK"
	},
	{
		"label": "Пакистан",
		"value": "PK"
	},
	{
		"label": "Палау",
		"value": "PW"
	},
	{
		"label": "Панама",
		"value": "PA"
	},
	{
		"label": "Папуа — Новая Гвинея",
		"value": "PG"
	},
	{
		"label": "Парагвай",
		"value": "PY"
	},
	{
		"label": "Перу",
		"value": "PE"
	},
	{
		"label": "Питкэрн",
		"value": "PN"
	},
	{
		"label": "Польша",
		"value": "PL"
	},
	{
		"label": "Португалия",
		"value": "PT"
	},
	{
		"label": "Пуэрто-Рико",
		"value": "PR"
	},
	{
		"label": "Реюньон",
		"value": "RE"
	},
	{
		"label": "Российская Федерация",
		"value": "RU"
	},
	{
		"label": "Руанда",
		"value": "RW"
	},
	{
		"label": "Румыния",
		"value": "RO"
	},
	{
		"label": "Сальвадор",
		"value": "SV"
	},
	{
		"label": "Самоа",
		"value": "WS"
	},
	{
		"label": "Сан-Марино",
		"value": "SM"
	},
	{
		"label": "Сан-Томе и Принсипи",
		"value": "ST"
	},
	{
		"label": "Саудовская Аравия",
		"value": "SA"
	},
	{
		"label": "Свазиленд",
		"value": "SZ"
	},
	{
		"label": "Северные Марианские острова",
		"value": "MP"
	},
	{
		"label": "Сейшелы",
		"value": "SC"
	},
	{
		"label": "Сен-Бартелеми",
		"value": "BL"
	},
	{
		"label": "Сен-Мартен",
		"value": "MF"
	},
	{
		"label": "Сен-Пьер и Микелон",
		"value": "PM"
	},
	{
		"label": "Сенегал",
		"value": "SN"
	},
	{
		"label": "Сент-Винсент и Гренадины",
		"value": "VC"
	},
	{
		"label": "Сент-Китс и Невис",
		"value": "KN"
	},
	{
		"label": "Сент-Люсия",
		"value": "LC"
	},
	{
		"label": "Сербия",
		"value": "RS"
	},
	{
		"label": "Сингапур",
		"value": "SG"
	},
	{
		"label": "Сирия",
		"value": "SY"
	},
	{
		"label": "Словакия",
		"value": "SK"
	},
	{
		"label": "Словения",
		"value": "SI"
	},
	{
		"label": "Соединенные Штаты",
		"value": "US"
	},
	{
		"label": "Соломоновы острова",
		"value": "SB"
	},
	{
		"label": "Сомали",
		"value": "SO"
	},
	{
		"label": "Судан",
		"value": "SD"
	},
	{
		"label": "Суринам",
		"value": "SR"
	},
	{
		"label": "Сьерра-Леоне",
		"value": "SL"
	},
	{
		"label": "Таджикистан",
		"value": "TJ"
	},
	{
		"label": "Таиланд",
		"value": "TH"
	},
	{
		"label": "Тайвань",
		"value": "TW"
	},
	{
		"label": "Танзания",
		"value": "TZ"
	},
	{
		"label": "Теркс и Кайкос",
		"value": "TC"
	},
	{
		"label": "Того",
		"value": "TG"
	},
	{
		"label": "Токелау",
		"value": "TK"
	},
	{
		"label": "Тонга",
		"value": "TO"
	},
	{
		"label": "Тринидад и Тобаго",
		"value": "TT"
	},
	{
		"label": "Тувалу",
		"value": "TV"
	},
	{
		"label": "Тунис",
		"value": "TN"
	},
	{
		"label": "Туркменистан",
		"value": "TM"
	},
	{
		"label": "Турция",
		"value": "TR"
	},
	{
		"label": "Уганда",
		"value": "UG"
	},
	{
		"label": "Узбекистан",
		"value": "UZ"
	},
	{
		"label": "Украина",
		"value": "UA"
	},
	{
		"label": "Уоллис и Футуна",
		"value": "WF"
	},
	{
		"label": "Уругвай",
		"value": "UY"
	},
	{
		"label": "Фарерские острова",
		"value": "FO"
	},
	{
		"label": "Фиджи",
		"value": "FJ"
	},
	{
		"label": "Филиппины",
		"value": "PH"
	},
	{
		"label": "Финляндия",
		"value": "FI"
	},
	{
		"label": "Фолклендские острова (Мальвинские)",
		"value": "FK"
	},
	{
		"label": "Франция",
		"value": "FR"
	},
	{
		"label": "Французская Гвиана",
		"value": "GF"
	},
	{
		"label": "Французская Полинезия",
		"value": "PF"
	},
	{
		"label": "Французские Южные Территории",
		"value": "TF"
	},
	{
		"label": "Хорватия",
		"value": "HR"
	},
	{
		"label": "Центрально-Африканская Республика",
		"value": "CF"
	},
	{
		"label": "Чад",
		"value": "TD"
	},
	{
		"label": "Черногория",
		"value": "ME"
	},
	{
		"label": "Чехия",
		"value": "CZ"
	},
	{
		"label": "Чили",
		"value": "CL"
	},
	{
		"label": "Швейцария",
		"value": "CH"
	},
	{
		"label": "Швеция",
		"value": "SE"
	},
	{
		"label": "Шпицберген и Ян Майен",
		"value": "SJ"
	},
	{
		"label": "Шри-Ланка",
		"value": "LK"
	},
	{
		"label": "Эквадор",
		"value": "EC"
	},
	{
		"label": "Экваториальная Гвинея",
		"value": "GQ"
	},
	{
		"label": "Эритрея",
		"value": "ER"
	},
	{
		"label": "Эстония",
		"value": "EE"
	},
	{
		"label": "Эфиопия",
		"value": "ET"
	},
	{
		"label": "Южная Георгия и Южные Сандвичевы острова",
		"value": "GS"
	},
	{
		"label": "Южно-Африканская Республика",
		"value": "ZA"
	},
	{
		"label": "Ямайка",
		"value": "JM"
	},
	{
		"label": "Япония",
		"value": "JP"
	}
];