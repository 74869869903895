<script setup lang="ts">
import { defineComponent } from 'vue';

defineComponent({ name: "CardBody" });
</script>

<template>
  <div class="card kit-card">
    <div class="card-body">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.kit-card.card {
    .card-body {
        @media screen and (max-width: 540px) {
            & {
                padding: 0.9em;
            }
        }
    }
}
</style>