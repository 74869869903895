export type TStatus = {
	manual: string[];
	success: string;
	error: string;
	title: string;
}

export type TStatusList = Record<string, TStatus>

export type TStatusMode = 'error' | 'success' | 'neutral'

export const statusColorHelper = (statusList: TStatusList): Map<string, TStatusMode> => {
	const map: Map<string, TStatusMode> = new Map();

	for (const i in statusList) {
		map.set(i, 'neutral');
	}

	for (const i in statusList) {
		map.set(statusList[i].success, 'success');
		map.set(statusList[i].error, 'error');
	}

	return map;
}