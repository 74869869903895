<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
// components
import Tr from './Tr.vue';
import Column from './Column.vue';
// types
import { ITableRow } from './types';


const props = defineProps<ITableRow>();
defineComponent({ name: 'TableRow' });

const componentMode = Boolean(props.component);
</script>

<template>
  <component
    :is="props.component"
    v-if="componentMode"
        
    :class="props.class || ''"
    :row-data="props.rowData"
    :row-index="props.rowIndex"
  >
    <Column
      v-for="(colData, ck) of props.columns" 
      :key="ck"
      :class="colData?.cellClass || ''"

      :data-key="colData.key"
      :component="colData.component"
      :render="colData.render"
      :value="colData.key && props.rowData?.[colData.key] || undefined"

      :row-data="props.rowData"
      :row-index="props.rowIndex"
            
      :dev-mode="props.devMode"
    />
  </component>

  <Tr 
    v-else
    :class="props.class || ''"
  >
    <Column
      v-for="(colData, ck) of props.columns" 
      :key="ck"
      :class="colData?.cellClass || ''"

      :data-key="colData.key"
      :component="colData.component"
      :render="colData.render"
      :value="colData.key && props.rowData?.[colData.key] || undefined"

      :row-data="props.rowData"
      :row-index="props.rowIndex"
            
      :dev-mode="props.devMode"
    />
  </Tr>
</template>