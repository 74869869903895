<script setup lang="ts">
import { defineComponent, ref } from 'vue';
import Input from '@/components/kit/Input.vue';
// store
import { usePermissionsApi, useSyncRulesStore } from './permissionsStore';
import { isUserCanEditPermission } from '@/store';


defineComponent({ name: 'PermissionAddForm' });

const tableStore = useSyncRulesStore();
const apiStore = usePermissionsApi();

const isFormOpen = ref<boolean>(false);
const ruleTitle = ref<string>('');

const submitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const title = ruleTitle.value.trim();
    if (!title) { return }
    apiStore.addRule(title);
    isFormOpen.value = false;
    ruleTitle.value = '';
}
</script>

<template>
  <tbody v-if="isUserCanEditPermission()">
    <tr>
      <td
        :colspan="tableStore.columns.length"
        class="permission-add-form-box"
      >
        <div
          v-if="isFormOpen"
          class="rule-add-form"
        >
          <form @submit="submitHandler">
            <Input
              v-model="ruleTitle"
              placeholder="Наименование действия"
            />
            <button
              type="submit"
              class="btn btn-success btn-rounded btn-icon"
            >
              <i class="mdi mdi-check" />
            </button>
          </form>
        </div>
        <div v-else>
          <button
            type="button"
            class="btn btn-danger btn-icon-text"
            @click="() => { isFormOpen = true }"
          >
            <i class="mdi mdi-plus btn-icon-prepend" />
            Добавить правило
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<style lang="scss">
.permission-add-form-box {
    text-align: right;

    @media screen and (max-width: 540px) {
        & {
            text-align: left;
        }
    }
}

.rule-add-form {

    width: 100%;

    input {
        width: calc(100% - 60px);
        display: inline-block;
    }

    button {
        margin-left: 15px;
    }
}
</style>