import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "label-desc" }

import { defineComponent } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LabelDescription',
  setup(__props) {

defineComponent({ name: "LabelDescription" });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})