<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch, computed, reactive } from 'vue';
import { isUserCanViewCmi } from '@/store';
import { downloadCmiPdf } from '@/service/bffService';
// components
import { Card, CardTitle, PageHeader, Loader } from '@/components/kit';
import {
    QuestionViewer,
    QuestionViewerEditor,
    QuestionViewerEditorRadio,
    QuestionViewerEditorDatepicker,
    QuestionViewerEditorSelect
} from '@/components/kit/QuestionViewer';
import {
    TKErrorList,
    TKSuccessTest,
    DownloadFileBtn
} from './components';
// routes
import { useRoute } from 'vue-router';
// store
import  { useCmiItemStore } from './store';
// router
import { routeNames, cmiStatuses } from "@/router";
// utils
import { dateIsoToHumanDateTime, countriesList } from '@/utils';
// types
import { 
    dictionaryYesNo,
    dictionaryInsurance, 
    dictionaryVisaType,
    dictionaryGender,
    dictionaryTarget,
} from './dictionaries';


// store
const store = useCmiItemStore();

const dealId = ref<number>(NaN);
onMounted(()=>{
    const route = useRoute();
    const deal = parseInt((route?.params?.id ?? '') + '', 10);
    if ( !Number.isNaN(deal) ) {
        dealId.value = deal;
        store.getDataById(deal);
    }
});

onUnmounted(() => {
    store.$reset();
});

// просмотр вопросов
const isAllQuestions = ref<boolean>(false);
const isViewQuestion = (storeKey: string): boolean => {
    if (isAllQuestions.value) { return true };

    if (storeKey in store) {
        const value = (store as Record<string, any>)[storeKey];
        return !(!value && value !== false);
    }
    return false;
}

const clickViewedBtnHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    isAllQuestions.value = !isAllQuestions.value;
}

// редактирование вопросов
const isEditable = (): boolean => {
    return !store.antrag_id;
}

// следим за списков вопросов и запоминаем изменяющиеся
const questionToWatcherList: (keyof typeof store)[] = [
    // block 1
    'germanyPolicy', 'residenceCountry', 'insuranceType', 'insuranceCompany', 
    'payer', 'insuranceNumber', 'insuranceFree',

    // block 2
    'visaType', 'firstGermanyWork', 'jobChanged', 'startDate', 
    'firstWorkDate', 'grossSalary', 'privateInsurance', 'yourselfWork', 

    // block 4
    'gender', 'firstName', 'lastName', 'birthLastName', 
    'birthDate', 'birthPlace', 'birthCountry', 'citizenship', 
    'hasPostAddress', 'street', 'houseNumber', 'mailboxLastName', 
    'postCode', 'city', 'email', 'hasGermanyPhone', 'germanyPhone', 
    'phone', 'metaContacts', 'hasChildren', 'contractForFamily', 
    'additionalLastName', 'cyrillicName', 
    
    // block 5
    'target', 'source', 'refid', 'questions',

    // block 6
    'erp_comment'
];

// const changeQuestionList: Set<keyof typeof store> = new Set(); //@dev:
let questionsWatcherCbList: Function[] = [];



const saveDealDataHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    // останавливаем вотчеры за вопросами
    questionsWatcherCbList.forEach(cb=>cb());
    store.updateData([...changeQuestionList.values()]);
}

watch(()=>store.$loading, () => {
    if ( !store.$loading ) {
        // устанавливаем вотчеры за вопросами
        changeQuestionList.clear();
        questionsWatcherCbList = questionToWatcherList.map(key=>watch(()=>store[key], ()=>changeQuestionList.add(key)));
    }
    else {
        // обнуляем вотчеры за вопросами
        questionsWatcherCbList = [];
        changeQuestionList.clear();
    }

    console.log('%cstore::', 'background: red', {...store}) // @dev: dev - helper
});

const changeQuestionList = reactive<Set<keyof typeof store>>(new Set());
const isSaveEnabled = computed<boolean>(()=>changeQuestionList.size === 0);

const sendTestRequestToTKHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isSaveEnabled.value) { return }

    store.sendTestTK();
}

const sendProdRequestToTKHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isSaveEnabled.value) { return }

    store.sendProdTK();
}

const testTkResponseHelper = (data: string | Record<string, any> = ''): string=>{
    if (typeof data === 'string') {
        return data;
    }
    let result = '';
    try {
        result = JSON.stringify(data, null, "\t");
    }
    catch(e) {
        //
    }
    return result;
}

const fullTestResult = ref<boolean>(false);
const toggleFullTestResult = ()=>{ fullTestResult.value = !fullTestResult.value }

// file download
const downloadFile  = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (store.antrag_id) {
        downloadCmiPdf(store.antrag_id)
            .then(r => r.blob())
            .then(blob => {
                var a = Object.assign(document.createElement('a'), {
                    href: URL.createObjectURL(blob),
                    download: `${store.antrag_id}_${store.uid}.pdf`,
                });
            
                document.body.appendChild(a);
                a.click();
    
                setTimeout(()=>{a.remove()}, 1e3);
            })
            .catch(error => {
                console.error(error);
            });
    }

}
</script>

<template>
    <PageHeader>Заявка ОМС</PageHeader>
    <Card
        v-if="!isUserCanViewCmi()"
        style="position:relative"
    >
        <CardTitle>Нет прав на просмотр данных</CardTitle>
    </Card>

    <Card
        v-if="isUserCanViewCmi() && dealId"
        style="position:relative"
    >
        <Loader v-if="store.$loading" />
        <template v-else-if="store.$error">
            <CardTitle>Заявка ОМС № {{ dealId }}</CardTitle>
            <h3>Произошла ошибка получения данных.</h3>
            <p><strong>Попробуйте перезагрузить страницу</strong>, если это не поможет <strong>обратитесь в поддержку</strong>.</p>
            <p><strong>Данные об ошибке.</strong></p>
            <p><strong>Обозначение ошибки: </strong>{{ store.$error.error }}</p>
            <p><strong>ID запроса: </strong>{{ store.$error.requestId }}</p>
            <p><strong>Код ответа: </strong>{{ store.$error.code ?? '' }}</p>
            <p>&nbsp;</p>
            <router-link :to="{ name: routeNames.cmi, params: { status: cmiStatuses.all } }">Вернуться к списку заявок ОМС.</router-link>
        </template>
        <template v-else>
            <CardTitle>Заявка ОМС № {{ dealId }}</CardTitle>
            
            <div class="row">
                <QuestionViewer label="ID заявки на сайте" :value="store.uid" class="value-viewer col-sm-12 col-md-4" />
                <QuestionViewer label="Заявка создана" :value="dateIsoToHumanDateTime(store.createdAt)" class="value-viewer col-sm-12 col-md-4" />
                <QuestionViewer label="Последнее изменение" :value="dateIsoToHumanDateTime(store.updatedAt)" class="value-viewer col-sm-12 col-md-4" />
            </div>

            <br>
            <br>
            <div class="cmi-btn-box">
                <button @click="clickViewedBtnHandler" class="btn btn-inverse-primary btn-fw">
                    {{ isAllQuestions ? 'Скрыть незаполненные вопросы' : 'Показать все вопросы' }}
                </button>
                    
                <button 
                    v-if="isEditable()"
                    @click="saveDealDataHandler"
                    class="btn btn-inverse-primary btn-fw"
                    :disabled="isSaveEnabled"
                >
                    Сохранить
                </button>

                <template v-if="!store.antrag_id">

                    <button 
                        @click="sendTestRequestToTKHandler"
                        class="btn btn-inverse-primary btn-fw"
                        :disabled="!!store.antrag_id || !isSaveEnabled"
                    >
                        Отправить тестовую заявку
                    </button>
    
                    <button
                        @click="sendProdRequestToTKHandler"
                        class="btn btn-inverse-primary btn-fw"
                        :disabled="!!store.antrag_id || !isSaveEnabled"
                    >
                        Отправить заявку в TK
                    </button>
                </template>
                <a v-else 
                    class="btn btn-inverse-primary btn-fw" 
                    @click="downloadFile"
                >
                    Cкачать PDF
                </a>
            </div>

            <template v-if="store.tkTestedResponse.deal || store.tkTestedResponse.tk || store.tkTestedResponse.response"> 
                <CardTitle>Результаты теста</CardTitle>

                <button 
                    class="btn btn-inverse-primary btn-fw"
                    @click="toggleFullTestResult"
                >
                    {{ fullTestResult ? 'Скрыть полные результаты теста' : 'Показать полные результаты теста' }}
                </button>

                <template v-if="fullTestResult">
                    <p class="test-tk-result">Данные заявки</p>
                    <textarea 
                        :value="testTkResponseHelper(store.tkTestedResponse.deal)" 
                        disabled="true" 
                        class="form-control form-control-sm"
                        style="resize:vertical"
                    ></textarea>
    
                    <p class="test-tk-result">Данные отправленные в TK</p>
                    <textarea 
                        :value="testTkResponseHelper(store.tkTestedResponse.tk)" 
                        disabled="true" 
                        class="form-control form-control-sm"
                        style="resize:vertical"
                    ></textarea>
                    
                    <p class="test-tk-result">Результат запроса в TK</p>
                    <textarea 
                        :value="testTkResponseHelper(store.tkTestedResponse.response)" 
                        disabled="true" 
                        class="form-control form-control-sm"
                        style="resize:vertical"
                    ></textarea>
                </template>

                <TKErrorList v-if="store.tkTestedResponse.response" :stringifedJson="store.tkTestedResponse.response"/>

                <TKSuccessTest v-if="store.tkTestedResponse.response" :stringifedJson="store.tkTestedResponse.response" />
            </template>

            <template v-if="store.tkProdResponse.deal || store.tkProdResponse.tk || store.tkProdResponse.response"> 
                <CardTitle>Результаты отправки заявки</CardTitle>

                <p v-if="store.tkProdResponse.response">{{
                    (()=>{
                        if (~Object.keys(store.tkProdResponse.response).indexOf('message')) {
                            return (store.tkProdResponse.response as any).message
                        }
                        return ''
                    })()
                }}</p>
                <TKErrorList v-if="store.tkProdResponse.response" :stringifedJson="store.tkProdResponse.response"/>

                <TKSuccessTest v-if="store.tkProdResponse.response" :stringifedJson="store.tkProdResponse.response" />

                <DownloadFileBtn 
                    v-if="store.tkProdResponse.response"
                    :stringifedJson="store.tkProdResponse.response"
                    :fileName="`${store.firstName}_${store.lastName}.pdf`"
                />
            </template>
            <br>
            <br>
            <!-- block 1 -->
            <CardTitle>Информация о страховке</CardTitle>

            <QuestionViewerEditorRadio
                v-if="isViewQuestion('target')" 
                label="Цель подачи заявки" 
                v-model="store.target" :dictionary="dictionaryTarget"
            >
                <p></p>
            </QuestionViewerEditorRadio>

            <QuestionViewerEditorRadio
                v-if="isViewQuestion('germanyPolicy')"
                label="Есть ли у вас сейчас действующий полис медицинского страхования в Германии?" 
                v-model="store.germanyPolicy" :dictionary="dictionaryYesNo" :is-editable="isEditable()"
            >
                <p>Нужно ввести 1 из вариантов</p>
            </QuestionViewerEditorRadio>

            <QuestionViewerEditorSelect 
                v-if="isViewQuestion('residenceCountry')"
                label="В какой стране перед текущим страхованием вы длительно проживали и имеете полис медицинского страхования?"
                v-model="store.residenceCountry" :options="countriesList" :is-editable="isEditable()"
            >
                <p>Страна должны быть выбрана из списка (ISO 3166-1)</p>
            </QuestionViewerEditorSelect>
            
            <QuestionViewerEditorRadio
                v-if="isViewQuestion('insuranceType')"
                label="Вид действующего страхования"
                v-model="store.insuranceType" :dictionary="dictionaryInsurance" :is-editable="isEditable()"
            >
                <p>Один из вариантов</p>
            </QuestionViewerEditorRadio>
            
            <QuestionViewerEditor 
                v-if="isViewQuestion('insuranceCompany')"
                label="Введите название страховой компании, в которой вы сейчас застрахованы"
                v-model="store.insuranceCompany" :is-editable="isEditable()"
            >
                <p>Только латинские буквы, макс. 25 символов</p>
            </QuestionViewerEditor>

            <QuestionViewerEditorRadio 
                v-if="isViewQuestion('payer')"
                label="Вы платите взносы сами или состоите в семейном страховании?" 
                v-model="store.payer" :dictionary="dictionaryYesNo" :is-editable="isEditable()"
            >
                <p>Нужно ввести 1 из вариантов</p>
            </QuestionViewerEditorRadio>

            <QuestionViewerEditor 
                v-if="isViewQuestion('insuranceNumber')"
                label="Введите номер медицинского страхования / Versichertennummer"
                v-model="store.insuranceNumber" :is-editable="isEditable()"
            >
                <p>Номер состоит из 10 символов: первая латинская буква и 9 цифр.</p>
                <p>Максимальная длина 10 символов.</p>
            </QuestionViewerEditor>

            <QuestionViewerEditorRadio
                v-if="isViewQuestion('insuranceFree')" 
                label="Освобождены ли вы от уплаты страховых взносов?" 
                v-model="store.insuranceFree" :dictionary="dictionaryYesNo" :is-editable="isEditable()"
            >
                <p>Нужно ввести 1 из вариантов</p>
            </QuestionViewerEditorRadio>
            

            <br>
            <br>
            <!-- block 2 -->
            <CardTitle>Данные о работе</CardTitle>
            
            <QuestionViewerEditorRadio
                v-if="isViewQuestion('visaType')" 
                label="На каком вы этапе?" 
                v-model="store.visaType" :dictionary="dictionaryVisaType" :is-editable="isEditable()" 
            >
                <p>Нужно ввести 1 из вариантов</p>
            </QuestionViewerEditorRadio>

            <QuestionViewerEditorRadio
                v-if="isViewQuestion('firstGermanyWork')" 
                label="Это ваша первая работа в Германии?" 
                v-model="store.firstGermanyWork" :dictionary="dictionaryYesNo" :is-editable="isEditable()" 
            >
                <p>Нужно ввести 1 из вариантов</p>
            </QuestionViewerEditorRadio>

            <QuestionViewerEditorRadio
                v-if="isViewQuestion('jobChanged')" 
                label="Вы хотите сменить страховку в связи со сменой работы?" 
                v-model="store.jobChanged" :dictionary="dictionaryYesNo" :is-editable="isEditable()" 
            >
                <p>Нужно ввести 1 из вариантов</p>
            </QuestionViewerEditorRadio>

            <QuestionViewerEditorDatepicker
                v-if="isViewQuestion('startDate')" 
                label="Дата начала страхования в ОМС" 
                v-model="store.startDate" :is-editable="isEditable()" 
            >
                <p></p>
            </QuestionViewerEditorDatepicker>

            <QuestionViewerEditorDatepicker
                v-if="isViewQuestion('firstWorkDate')" 
                label="Дата первого рабочего дня в Германии за все время" 
                v-model="store.firstWorkDate" :is-editable="isEditable()" 
            >
                <p></p>
            </QuestionViewerEditorDatepicker>

            <QuestionViewerEditorRadio
                v-if="isViewQuestion('grossSalary')" 
                label="Ваша полная годовая зарплата брутто с бонусами выше 66600€ (5550€ в месяц)?" 
                v-model="store.grossSalary" :dictionary="dictionaryYesNo" :is-editable="isEditable()" 
            >
                <p>Нужно ввести 1 из вариантов</p>
            </QuestionViewerEditorRadio>

            <QuestionViewerEditorRadio
                v-if="isViewQuestion('privateInsurance')" 
                label="Рассматриваете ли вы вариант частного страхования?" 
                v-model="store.privateInsurance" :dictionary="dictionaryYesNo" :is-editable="isEditable()" 
            >
                <p>Нужно ввести 1 из вариантов</p>
            </QuestionViewerEditorRadio>

            <QuestionViewerEditorRadio
                v-if="isViewQuestion('yourselfWork')" 
                label="Работаете ли вы на себя в Германии?" 
                v-model="store.yourselfWork" :dictionary="dictionaryYesNo" :is-editable="isEditable()"
            >
                <p>Нужно ввести 1 из вариантов</p>
            </QuestionViewerEditorRadio>
            
            <br>
            <br>
            <!-- block 4 -->
            <CardTitle>Персональные данные</CardTitle>
            
            <QuestionViewerEditorRadio
                v-if="isViewQuestion('gender')" 
                label="Укажите ваш пол" 
                v-model="store.gender" :dictionary="dictionaryGender" :is-editable="isEditable()"
            >
                <p>Нужно ввести 1 из вариантов</p>
            </QuestionViewerEditorRadio>
            
            <QuestionViewerEditor 
                v-if="isViewQuestion('firstName')"
                label="Введите ваше имя (как в загранпаспорте)"
                v-model="store.firstName" :is-editable="isEditable()"
            >
                <p>Латинскими буквами, как в загранпаспорте</p>
            </QuestionViewerEditor>

            <QuestionViewerEditor 
                v-if="isViewQuestion('lastName')"
                label="Введите вашу фамилию (как в загранпаспорте)"
                v-model="store.lastName" :is-editable="isEditable()"
            >
                <p>Латинскими буквами, как в загранпаспорте</p>
            </QuestionViewerEditor>

            <QuestionViewerEditor 
                v-if="isViewQuestion('birthLastName')"
                label="Фамилия при рождении"
                v-model="store.birthLastName" :is-editable="isEditable()"
            >
                <p>Заполните латинскими буквами</p>
            </QuestionViewerEditor>
            
            <QuestionViewerEditorDatepicker
                v-if="isViewQuestion('birthDate')" 
                label="Ваша дата рождения" 
                v-model="store.birthDate" :is-editable="isEditable()" 
            >
                <p></p>
            </QuestionViewerEditorDatepicker>

            <QuestionViewerEditor 
                v-if="isViewQuestion('birthPlace')"
                label="Место рождения (населенный пункт)"
                v-model="store.birthPlace" :is-editable="isEditable()"
            >
                <p>Заполните латинскими буквами</p>
            </QuestionViewerEditor>

            <QuestionViewerEditorSelect 
                v-if="isViewQuestion('birthCountry')"
                label="Страна рождения"
                v-model="store.birthCountry" :options="countriesList" :is-editable="isEditable()"
            >
                <p>Страна должны быть выбрана из списка (ISO 3166-1)</p>
            </QuestionViewerEditorSelect>

            <QuestionViewerEditorSelect 
                v-if="isViewQuestion('citizenship')"
                label="Гражданство"
                v-model="store.citizenship" :options="countriesList" :is-editable="isEditable()"
            >
                <p>Страна должны быть выбрана из списка (ISO 3166-1)</p>
            </QuestionViewerEditorSelect>
            
            <QuestionViewerEditorRadio
                v-if="isViewQuestion('hasPostAddress')" 
                label="Есть ли у вас почтовый адрес в Германии?" 
                v-model="store.hasPostAddress" :dictionary="dictionaryYesNo" :is-editable="isEditable()"
            >
                <p>Нужно ввести 1 из вариантов</p>
            </QuestionViewerEditorRadio>

            <QuestionViewerEditor 
                v-if="isViewQuestion('street')"
                label="Улица"
                v-model="store.street" :is-editable="isEditable()"
            >
                <p>Заполните латинскими буквами</p>
            </QuestionViewerEditor>

            <QuestionViewerEditor 
                v-if="isViewQuestion('houseNumber')"
                label="Номер дома"
                v-model="store.houseNumber" :is-editable="isEditable()"
            >
                <p>Латинские буквы и/или цифры</p>
            </QuestionViewerEditor>

            <QuestionViewerEditor 
                v-if="isViewQuestion('mailboxLastName')"
                label="Фамилия, как на почтовом ящике"
                v-model="store.mailboxLastName" :is-editable="isEditable()"
            >
                <p>Латинские буквы</p>
            </QuestionViewerEditor>

            <QuestionViewerEditor 
                v-if="isViewQuestion('postCode')"
                label="Индекс"
                v-model="store.postCode" :is-editable="isEditable()"
            >
                <p>Латинские буквы и/или цифры</p>
            </QuestionViewerEditor>

            <QuestionViewerEditor 
                v-if="isViewQuestion('city')"
                label="Город"
                v-model="store.city" :is-editable="isEditable()"
            >
                <p>Заполните латинскими буквами</p>
            </QuestionViewerEditor>

            <QuestionViewerEditor 
                v-if="isViewQuestion('email')"
                label="Укажите ваш e-mail"
                v-model="store.email" :is-editable="isEditable()"
            >
                <p>Заполните латинскими буквами</p>
            </QuestionViewerEditor>

            <QuestionViewerEditor 
                v-if="isViewQuestion('germanyPhone')"
                label="Укажите ваш номер телефона в Германии"
                v-model="store.germanyPhone" :is-editable="isEditable()"
            >
                <p></p>
            </QuestionViewerEditor>

            <QuestionViewerEditor 
                v-if="isViewQuestion('phone')"
                label="Укажите любой действующий номер телефона для связи с вами"
                v-model="store.phone" :is-editable="isEditable()"
            >
                <p></p>
            </QuestionViewerEditor>

            <QuestionViewerEditor 
                v-if="isViewQuestion('metaContacts')"
                label="Укажите дополнительный способ связи"
                v-model="store.metaContacts" :is-editable="isEditable()"
                type="textarea"
            >
                <p></p>
            </QuestionViewerEditor>

            <QuestionViewerEditorRadio
                v-if="isViewQuestion('hasChildren')" 
                label="Есть ли у вас дети?" 
                v-model="store.hasChildren" :dictionary="dictionaryYesNo" :is-editable="isEditable()"
            >
                <p>Нужно ввести 1 из вариантов</p>
            </QuestionViewerEditorRadio>

            <QuestionViewerEditorRadio
                v-if="isViewQuestion('contractForFamily')" 
                label="Вы хотите включить членов семьи в свой контракт?" 
                v-model="store.contractForFamily" :dictionary="dictionaryYesNo" :is-editable="isEditable()"
            >
                <p>Нужно ввести 1 из вариантов</p>
            </QuestionViewerEditorRadio>
            
            <QuestionViewerEditor 
                v-if="isViewQuestion('additionalLastName')"
                label="Введите фамилию и имя супруга/-и"
                v-model="store.additionalLastName" :is-editable="isEditable()"
            >
                <p>Заполните латинскими буквами</p>
            </QuestionViewerEditor>
            
            <QuestionViewerEditor 
                v-if="isViewQuestion('cyrillicName')"
                label="Как ваше имя пишется на русском языке?"
                v-model="store.cyrillicName" :is-editable="isEditable()"
            >
                <p>Заполните на русском языке</p>
            </QuestionViewerEditor>
            
            
            <br>
            <br>
            <!-- block 5 -->
            <CardTitle>Согласие и обработка персональных данных</CardTitle>

            <QuestionViewerEditorRadio
                v-if="isViewQuestion('target')" 
                label="Цель подачи заявки" 
                v-model="store.target" :dictionary="dictionaryTarget"
            >
                <p></p>
            </QuestionViewerEditorRadio>

            <QuestionViewerEditor 
                v-if="isViewQuestion('questions')"
                label="Если у вас есть вопросы"
                v-model="store.questions" :is-editable="isEditable()"
                type="textarea"
            >
                <p></p>
            </QuestionViewerEditor>

            <QuestionViewerEditor 
                v-if="isViewQuestion('source')"
                label="Как вы о нас узнали?"
                v-model="store.source" :is-editable="isEditable()"
                type="textarea"
            >
                <p></p>
            </QuestionViewerEditor>

            <QuestionViewerEditor 
                v-if="isViewQuestion('refid')"
                label="REF ID"
                v-model="store.refid"
            >
                <p></p>
            </QuestionViewerEditor>

            <QuestionViewerEditor
                label="Комментарий сотрудника ТГ"
                v-model="store.erp_comment" :is-editable="isEditable()"
                type="textarea"
            >
            </QuestionViewerEditor>

            <!-- <br>
            <br>
            <div class="cmi-btn-box">
                <button 
                    @click="sendTestRequestToTKHandler"
                    class="btn btn-inverse-primary btn-fw"
                    :disabled="!isSaveEnabled"
                >
                    Отправить тестовую заявку
                </button>
            </div> -->

        </template>
    </Card>

    <Card v-else>
        <CardTitle>Неверный ID заявки в URL страницы.</CardTitle>
        <router-link
        :to="{ name: routeNames.cmi, params: { status: cmiStatuses.all } }"
        >Вы можете вернуться к списку заявок ОМС.</router-link>
    </Card>
</template>

<style lang="scss">
.cmi-btn-box {
    display: flex;
    margin: 2rem -1rem;

    button {
        margin: 0 1rem;
    }
}

.cmi-save-btn-box {
    position: fixed;
    right: 1em;
    bottom: 1em;
}

.test-tk-result {
    margin: 2em 0 0.5em;
    color: #000;
}
</style>