<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
import { ITablePaginationButton } from '@/components/Table/types';

defineComponent({name: 'TPaginationBtn'});

const props = defineProps<ITablePaginationButton>();

</script>

<template>
  <button :class="props.isActive ? '--active' : ''">
    {{ props.index }}
  </button>
</template>

<style>
    button.--active {
        background-color: red;
    }
</style>