<script setup lang="ts">
import { defineComponent } from 'vue';


defineComponent({ name: "RowBox" });
</script>

<template>
  <div class="row">
    <slot />
  </div>
</template>
  