export const tkErrorList = {
	"10000": "'imAuslandGelebt' is mandatory",
	"10010": "'landLetzteVersicherung' is mandatory when 'imAuslandGelebt' true",
	"10012": "'landLetzteVersicherung' must be provided as ISO-3166-1",
	"10020": "'krankenversicherungName' is mandatory when 'imAuslandGelebt' false or the countr",
	"10022": "'krankenversicherungName' is limited to 45 characters",
	"10023": "'krankenversicherungName' contains invalid characters",
	"10030": "'versicherungsart' is mandatory when 'imAuslandGelebt' false or the country has",
	"10031": "'versicherungsart' must be 'gesetzlich' or 'privat'",
	"10040": "'selbstVersichert' is mandatory when 'imAuslandGelebt' false and versicherungsar",
	"10050": "'pflichtversichert' is mandatory when 'imAuslandGelebt' false and versicherungsar",
	"10060": "'innerhalbBindungsfrist' is mandatory when 'imAuslandGelebt' false an",
	"10080": "'gekuendigtZum' must be provided in German date format 'DD.MM.YYYY'",
	"10081": "'gekuendigtZum' must be at least 70 years in the past and no more than 18 months i",
	"20000": "'befreitKv' is mandatory",
	"20010": "'hochschule' is mandatory",
	"20011": "'hochschule' is limited to 45 characters",
	"20012": "'hochschule' contains invalid characters",
	"20040": "'studienbeginn' is mandatory",
	"20041": "'studienbeginn' must be provided in German date format 'DD.MM.YYYY'",
	"20042": "'studienbeginn' must be at least 20 years in the past and no more than 18 months i",
	"20043": "'versicherungsbeginn' must be at or after 'studienbeginn' when kundengrupp",
	"20050": "'leistungenAgenturFuerArbeit' is mandator",
	"20060": "'anspruchSachleistungen' is mandator",
	"20070": "'beschaeftigt' is mandator",
	"20080": "'beschaeftigtSelbstaendigDurchschnittlicheStudienzeit' is mandatory, whe",
	"20081": "'beschaeftigtSelbstaendigDurchschnittlicheStudienzeit' must be between 0 and 168.0",
	"20090": "'beschaeftigtDurchschnittlicheArbeitszeit' is mandatory, when 'beschaeftigt' tru",
	"20091": "'beschaeftigtDurchschnittlicheArbeitszeit' must be between 0 and 168.0",
	"20100": "'beschaeftigtSelbstaendigVorlesungsfreieZeit' is mandatory, when 'beschaeftigt' o",
	"20110": "'beschaeftigtPraktikum' is mandatory, when 'beschaeftigt' tru",
	"20120": "'beschaeftigtMonatlichesBruttogehalt' is mandatory, when 'beschaeftigt' an",
	"20121": "'beschaeftigtMonatlichesBruttogehalt' must be between 0 and 99999.9",
	"20130": "'rente' is mandator",
	"20140": "'renteArt' is mandatory, when 'rente' tru",
	"20141": "'renteArt' must be 'WAISENRENTE', 'HINTERBLIEBENENRENTE'",
	"20150": "'renteName' is mandatory, when 'renteArt' is 'SONSTIGE",
	"20151": "'renteName' is limited to 50 character",
	"20160": "'selbststaendig' is mandator",
	"20170": "'selbststaendigDurchschnittlicheArbeitszeit' is mandatory, when 'selbststaendig' tru",
	"20171": "'selbststaendigDurchschnittlicheArbeitszeit' must be between 0 and 168.0",
	"20180": "'selbststaendigMonatlicheEinkuenfte' is mandatory, when 'selbststaendig' tru",
	"20181": "'selbststaendigMonatlicheEinkuenfte' must be between 0 and 99999.9",
	"20190": "'selbststaendigBeschaeftigteArbeitnehmer' is mandatory, when 'selbststaendig' tru",
	"20200": "'selbststaendigBeschaeftigteArbeitnehmerAufMinijobBasis' is mandatory, whe",
	"21010": "'ausbildungsbeginn' is mandatory",
	"21011": "'ausbildungsbeginn' must be at least 42 months in the past and no more than 1",
	"21012": "'ausbildungsbeginn' must be provided in German date format 'DD.MM.YYYY'",
	"21020": "'arbeitgeber' is mandatory",
	"21021": "'arbeitgeber' is limited to 45 characters",
	"21022": "'arbeitgeber' contains invalid characters",
	"21030": "'arbeitgeberAdresse' is mandatory",
	"21040": "'sozAusweisBeantr\" is mandatory",
	"30000": "'name' is mandatory",
	"30010": "'adresse' is mandator",
	"30020": "'email' has an invalid format. Expected format: max.mustermann@some.domai",
	"30021": "'email' is limited to 65 characters",
	"30030": "'telefon' has an invalid format. Expected format: +1 123 456 789",
	"30031": "'telefon' is limited to 20 characters",
	"30040": "'geburtsdatum' is mandatory",
	"30041": "'geburtsdatum' must be in the past",
	"30042": "'geburtsdatum' must be provided in German date format 'DD.MM.YYYY'",
	"30043": "'geburtsdatum' may not be more than 120 years in the past",
	"30045": "'geburtsdatum' may not exceed 55 years (relative to the given 'versicherungsbeginn'",
	"30050": "'versichertennummer' is invalid (checksum mismatch)",
	"30051": "'versichertennummer' must be null when 'imAuslandGelebt' true",
	"30060": "'geburtsname' is mandatory when 'imAuslandGelebt' true or ('imAuslandGelebt' fals",
	"30061": "'geburtsname' is limited to 45 characters",
	"30062": "'geburtsname' contains invalid characters",
	"30070": "'geburtsort' is mandatory when 'imAuslandGelebt' true or ('imAuslandGelebt' false an",
	"30071": "'geburtsort' is limited to 24 characters",
	"30072": "'geburtsort' contains invalid characters",
	"30076": "'geburtsland' is mandator",
	"30077": "'geburtsland' must be provided as ISO-3166-1",
	"30080": "'staatsangehoerigkeit' is mandatory when 'imAuslandGelebt' true o",
	"30081": "'staatsangehoerigkeit' must be provided as ISO-3166-1",
	"30090": "'versorgungsbezuege' is mandatory",
	"30100": "'kinder' is mandatory",
	"30110": "'mitversicherungVonAngehoerigen' is mandatory",
	"30120": "'versicherungsbeginn' is mandatory",
	"30121": "'versicherungsbeginn' must be provided in German date format 'DD.MM.YYYY'",
	"30123": "'versicherungsbeginn' must be at least 12 months in the past and no more than 1",
	"30124": "'versicherungsbeginn' must be before or equal 'studienbeginn' or 'ausbildungsbeginn",
	"30130": "'rentenbezug' is mandatory when 'kundengruppe' is not student-typ",
	"30140": "'kvPvBefreit' is mandatory when 'kundengruppe' is 'STUDIERENDE'. Note that this fiel",
	"40000": "'antragId' is mandator",
	"40001": "'antragId' is invali",
	"40010": "'adresse' is mandatory",
	"50000": "'entgeltklasse' is mandatory",
	"50001": "'entgeltklasse' is invalid. Valid values are 'versicherungspflichtig', 'ueber-jaeg'",
	"50010": "'ersteBeschaeftigung' is mandatory",
	"50020": "'rechtsbelehrung' is mandatory",
	"50021": "'rechtsbelehrung' must be TRUE when ueberBBG is TRUE",
	"50030": "'arbeitgeber' is mandatory",
	"50031": "'arbeitgeber' is limited to 45 characters",
	"50032": "'arbeitgeber' contains invalid characters",
	"50040": "'arbeitgeberAdresse' is mandatory",
	"50060": "'beschaeftigtSeitAb' is mandatory",
	"50061": "'beschaeftigtSeitAb' must be provided in German date format 'DD.MM.YYYY'",
	"50062": "'beschaeftigtSeitAb' may not be more than 70 years in the past or 18 months in th",
	"50080": "'selbststaendig' is mandator",
	"50090": "'geschaeftsfuehrer' is mandator",
	"50100": "'existenzgruender' is mandatory when 'selbststaendig' = tru",
	"50110": "'beschaeftigtMehrereMinijobber' is mandatory when 'selbststaendig' = tru",
	"50111": "'beschaeftigtArbeitnehmer' is mandatory when 'selbststaendig' = tru",
	"50120": "'stundenSelbststaendigkeit' is mandatory when 'selbststaendig' = tru",
	"50121": "'stundenSelbststaendigkeit' must be between 0 and 999.9",
	"50130": "'einkommenSelbststaendigkeit' is mandatory when 'selbststaendig' = tru",
	"50131": "'einkommenSelbststaendigkeit' must be between 0 and 999999.9",
	"50140": "'stundenArbeitnehmer' is mandatory when 'selbststaendig' = tru",
	"50141": "'stundenArbeitnehmer' must be between 0 and 999.9",
	"50150": "'entgeltArbeitnehmer' is mandatory when 'selbststaendig' = tru",
	"50151": "'entgeltArbeitnehmer' must be between 0 and 999999.9",
	"60000": "'antragIds' is mandator",
	"60002": "Too many 'antragIds' provided. The maximum count for 'antragIds' is 1",
	"70000": "'selbststaendigSeit' is mandator",
	"70001": "'selbststaendigSeit' may not be more than 70 years in the past or more than 18 month",
	"70002": "'selbststaendigSeit' must be provided in German date format 'DD.MM.YYYY'",
	"70010": "'einkommensteuerbescheidVorhanden' is mandator",
	"70020": "'monatlicheEinkuenfte' is mandatory when 'einkommensteuerbescheidVorhanden'",
	"70021": "'monatlicheEinkuenfte' must be between 0 and 999999.9",
	"70030": "'argeLeistungen' is mandator",
	"70040": "'weiteresEinkommen' must be between 0 and 999999.9",
	"70041": "'weiteresEinkommenArt' is mandatory when 'weiteresEinkommen' is not nul",
	"70042": "'weiteresEinkommenArt' is limited to 50 character",
	"70050": "'partnerNichtGkv' is mandator",
	"70060": "'krankengeldAnspruch43' is mandator",
	"70070": "'krankengeldAb' is mandatory when 'krankengeldAnspruch43' = tru",
	"70071": "'krankengeldAb' may not be in the past or more than 18 months in the future",
	"70072": "'krankengeldAb' must be provided in German date format 'DD.MM.YYYY'",
	"70080": "'aktuellArbeitsunfaehigSeit' may not be more than 50 years in the past or anywhere i",
	"70081": "'aktuellArbeitsunfaehigSeit' must be provided in German date format 'DD.MM.YYYY'",
	"70090": "'aktuellKrankengeldAnspruchSeit' may not be more than 50 years in the past or late",
	"70091": "'aktuellKrankengeldAnspruchSeit' must be provided in German date forma",
	"70100": "'Rechtsbelehrung' is mandatory and expected to be 'true",
	"80000": "'antragId' is mandator",
	"80010": "'nachreichenKommntar' is limited to 250 character",
	"80011": "'nachreichenKommntar' contains invalid character",
	"80012": "at least 1 document is mandator",
	"81000": "'antragId' is mandatory and is limited to 10 character",
	"81010": "'sepaMandat' is mandator",
	"81011": "'iban' is mandatory for 'sepaNachreichen",
	"90000": "'anrede' is mandatory",
	"90001": "'anrede' must be 'Herr' or 'Frau'",
	"90010": "'vorname' is mandatory",
	"90011": "'vorname' must be between 2 and 27 characters",
	"90012": "'vorname' contains invalid characters",
	"90020": "'nachname' is mandatory",
	"90021": "'nachname' must be between 2 and 27 characters",
	"90022": "'nachname' contains invalid characters",
	"90030": "'titel' must be between 2 and 15 characters",
	"90031": "'titel' contains invalid characters",
	"90040": "'nameszusatz' must be between 2 and 15 characters",
	"90041": "'nameszusatz' contains invalid characters",
	"91000": "'strasse' is mandatory",
	"91001": "'strasse' must be between 2 and 23 characters",
	"91002": "'strasse' contains invalid characters",
	"91010": "'plz' is mandatory",
	"91011": "'plz' must be between 2 and 10 characters",
	"91012": "'plz' contains invalid characters",
	"91013": "'plz' for German addresses is limited to 5 character",
	"91020": "'ort' is mandatory",
	"91021": "'ort' must be between 2 and 35 characters",
	"91022": "'ort' contains invalid characters",
	"91030": "'land' is mandatory",
	"91031": "'land' must be provided as ISO-3166-1",
	"91032": "'land' must be Germany ('DE') because we only accept German addresses to b",
	"91040": "'hausnummer' is limited to 8 characters",
	"91041": "'hausnummer' contains invalid characters",
	"91042": "'hausnummer' may only contain 4 characters as number and 4 characters as numbe",
	"91050": "'adresszusatz' is limited to 35 characters",
	"91051": "'adresszusatz' contains invalid characters",
	"92000": "'iban' is not a valid IBA",
	"92001": "'iban' is mandatory if 'bic' or 'kontoinhaber' is se",
	"92010": "'bic' is mandatory if the given IBAN is not from Germany (DE",
	"92011": "'bic' is not a valid BI",
	"92012": "The country code of 'bic' does not match the country code of 'iban",
	"92020": "'kontoinhaber' is mandatoy if 'iban' is se",
	"92021": "'kontoinhaber' must be either 'VERSICHERUNGSNEHMER' or 'ABWEICHENDER",
	"92030": "'kontoinhaberName' is mandatory if 'Kontoinhaber' = 'ABWEICHENDER",
	"92040": "'kontoinhaberAdresse' is mandatory if 'Kontoinhaber' = 'ABWEICHENDER",
	"92050": "'einwilligungBankeinzug' must be tru",
	"98000": "'partnerId' is mandatory",
	"98010": "'token' is mandatory",
	"98015": "'token' or 'partnerId' is invalid",
	"98016": "The access token is not valid for the desired operatio",
	"98017": "'vorgangsId' is limited to 25 characters",
	"98018": "'vermittler' is limited to 10 characters",
	"98019": "'vermitler' is mandator",
	"99000": "'metaDaten' is mandatory",
	"99010": "'studierender' is mandatory when kundengruppe 'STUDIERENDE'",
	"99011": "'studierender' must me null when kundengruppe is not 'STUDIERENDE'",
	"99012": "'beschaeftigter' is mandatory when kundengruppe 'BERUFSTAETIGE' o",
	"99013": "'beschaeftigter' must me null when kundengruppe is not 'BERUFSTAETIGE' o",
	"99014": "'auszubildende' is mandatory when kundengruppe 'AUSZUBILDENDE'",
	"99015": "'auszubildende' must me null when kundengruppe is not 'AUSZUBILDENDE'",
	"99020": "'bestehendeVersicherung' is mandatory",
	"99030": "'persDaten' is mandatory",
	"99040": "'sprache' is mandatory",
	"99041": "'sprache' must be 'DE' or 'EN'",
	"99050": "'kundengruppe' is mandatory",
	"99051": "'kundengruppe' is invalid. For now use 'STUDIERENDE', 'BERUFSTAETIGE'",
	"99060": "No dataset (JSON) was found in the request",
	"99061": "More than 1 dataset was found in the request. Exactly 1 is expected",
	"99062": "The dataset (JSON) could not be parsed",
	"99064": "One of the attached files is too large. Maximum size for attachments is 10MB",
	"99065": "Too many attachments. The maximum number of allowed attachments is 10",
	"99066": "The attached passport photo does not meet the requirements. Resolution must b",
	"99070": "A confirmation of holding the mandate is mandatory. 'maklervollmacht' must be true",
	"99071": "The address of 'antragId' cannot be changed. Access denied",
	"99072": "'maklervollmacht' must be either 'BASIS' or 'ERWEITERT",
	"99073": "A direct debite mandate of 'antragId' cannot be changed. Access denied",
	"99074": "A later submit of documents is not allowed 'antragId'. Access denied",
	"99080": "The operation you are calling is currently not active for this endpoint",
	"99081": "The upload function for passport photos is not available for this 'kundengruppe' at thi",
	"99090": "'selbststaendige' is mandatory when kundengruppe 'SELBSTSTAENDIGE'",
	"99091": "'selbststaendige' must me null when kundengruppe is not 'SELBSTSTAENDIGE'"
} 