import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-scroller" }
const _hoisted_2 = { class: "container-fluid page-body-wrapper full-page-wrapper" }
const _hoisted_3 = { class: "content-wrapper d-flex align-items-center auth lock-full-bg" }
const _hoisted_4 = { class: "row w-100" }
const _hoisted_5 = { class: "col-lg-4 mx-auto" }
const _hoisted_6 = { class: "auth-form-transparent text-left p-5 text-center" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "mt-5" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = {
  key: 0,
  class: "mt-3 text-center"
}
const _hoisted_15 = { class: "auth-link text-white" }
const _hoisted_16 = {
  key: 0,
  class: "auth-link text-white"
}
const _hoisted_17 = {
  key: 1,
  class: "auth-link text-white"
}

import { ref } from 'vue';
import { ENavy, router } from '@/router';
import defaultUserImage from '@/assets/img/user.svg';
// store
import { useUserStore, loginUserByLoginPassword } from '@/store';



export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props) {

const userStore = useUserStore();

const login = ref<string>('');
const password = ref<string>('');

const isEmailValidator = (email?: string): boolean => {
    if (!email) { return false }
    const regex = /^[a-zA-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/ig;

    return regex.test(email);
}

const loginHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropogation && e.stopPropogation();

    if (userStore.loading) { return }

    // проверяем на почту или телефон
    let identifier = login.value.trim();
    if (!isEmailValidator(login.value.trim())) {
        identifier = (identifier.match(/\d+/g) || []).join('');
        if (identifier[0] === '8') {
            identifier = '7' + identifier.slice(1);
        }
    }

    loginUserByLoginPassword(identifier, password.value)
        .then((status) => status && router.push(ENavy.dashboard))
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("img", {
                src: _unref(defaultUserImage),
                class: "lock-profile-img",
                alt: "img"
              }, null, 8, _hoisted_7),
              _createElementVNode("form", {
                class: "pt-5",
                onSubmit: loginHandler
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[2] || (_cache[2] = _createElementVNode("label", { for: "login" }, "Login to unlock", -1)),
                  _withDirectives(_createElementVNode("input", {
                    id: "login",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((login).value = $event)),
                    type: "text",
                    class: "form-control text-center",
                    placeholder: "Login",
                    required: "",
                    disabled: _unref(userStore).loading
                  }, null, 8, _hoisted_9), [
                    [_vModelText, login.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[3] || (_cache[3] = _createElementVNode("label", { for: "password" }, "Password", -1)),
                  _withDirectives(_createElementVNode("input", {
                    id: "password",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
                    type: "password",
                    class: "form-control text-center",
                    placeholder: "Password",
                    required: "",
                    disabled: _unref(userStore).loading
                  }, null, 8, _hoisted_11), [
                    [_vModelText, password.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("button", {
                    type: "submit",
                    disabled: _unref(userStore).loading,
                    class: "btn btn-block btn-success btn-lg font-weight-medium"
                  }, " Login ", 8, _hoisted_13)
                ]),
                (_unref(userStore).errorMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "auth-link text-white" }, " Login error! ", -1)),
                      _createElementVNode("p", _hoisted_15, _toDisplayString(_unref(userStore).errorMessage.toLocaleUpperCase()), 1),
                      (_unref(userStore).errorRequestId)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_16, " request id: " + _toDisplayString(_unref(userStore).errorRequestId), 1))
                        : _createCommentVNode("", true),
                      (_unref(userStore).errorCode)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_17, " code: " + _toDisplayString(_unref(userStore).errorCode), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ], 32)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})