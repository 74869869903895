import { localStorage } from '@/utils';


const authKeyLocalStorageKey = 'xx-auth-key';

const decryptKey = (key: string): string => {
	let result = '';
	try {
		result = (window as Window).atob(key)
	}
	catch (e) { }

	return result;
}

const encryptKey = (key: string): string => {
	let result = '';
	try {
		result = (window as Window).btoa(key)
	}
	catch (e) { }

	return result;
}

export const getAuthKey = (): string => {
	const storageValue = localStorage.getItem(authKeyLocalStorageKey);
	if (!storageValue) { return '' }

	return decryptKey(storageValue);
}

export const setAuthKey = (key: string) => {
	localStorage.setItem(authKeyLocalStorageKey, encryptKey(key));
}