import {
	generateAsyncDataTableStore,
	definePromiseQueue,
	mapTableFiltersToSearchParams,
} from '@/components/Table';
import { getList, TPriceSearchParams } from '../bff-service';  // Импорт функции для получения мок-данных
import { TPagesGetListResDto } from '../types';

export type TPriceData = {
	id: number;
	title: string;
	url: string;
}

const allListPromiseQueue = definePromiseQueue<TPagesGetListResDto>();

export const useAsyncPriceAllListStore = generateAsyncDataTableStore<TPriceData>({
	initialState: {
			loading: true,
	},

	dataGetter: async (useStore) => {
		const store = useStore();

		await allListPromiseQueue.wait(allListGetter, useStore)
			.then(response=>{
				store.data = response.list.map( deal => {
					return {
						id: deal.id,
						title: deal.title,
						url: deal.url, 
					};
				});
				store.paginateTotal = response.paginate.total;
				store.paginateFiltered = response.paginate.total;
			})
			.catch(e=>{
				store.data = [];
				store.paginateTotal = 0;
			})
			.finally(()=>{
				store.loading = false;
			})
	}
});


// получение списка заявок
const allListGetter = (): Promise<TPagesGetListResDto> => {
	const store = useAsyncPriceAllListStore();

	// приводим данные фильтров стора таблиц 
	// к виду данных для поиска
	const search = mapTableFiltersToSearchParams<typeof store, TPriceSearchParams>(
		store, // указываем нужный стор
		// указываем данные поиска по дефолту
		{
			title: '',
			url: '',
		},
		// указываем соответствие ключей строра таблиц к ключам данных для поиска
		{
			title: 'title',
			url: 'url',
		}
	);

	return getList(store.paginatePage, store.paginatePerPage, search);
}