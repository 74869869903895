import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "aria-controls": "order-listing",
  class: "page-link"
}

import { defineComponent } from 'vue';
import { ITablePaginationButton } from '@/components/Table/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'PaginationBtn',
  props: {
    isActive: { type: Boolean },
    index: {}
  },
  setup(__props: any) {

defineComponent({name: 'KitTablePaginationBtn'});

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["paginate_button page-item", props.isActive ? 'active' : ''])
  }, [
    _createElementVNode("a", _hoisted_1, _toDisplayString(props.index), 1)
  ], 2))
}
}

})