<script setup lang="ts">
import { defineComponent, defineProps, ref } from 'vue';
import { ITableCellProps } from '@/components/Table/types';
import Input from '@/components/kit/Input.vue';
import { isUserCanEditPermission } from '@/store';
// store
import { usePermissionsApi } from './permissionsStore';


defineComponent({name: 'PermissionTitleCell'});

const props = defineProps<ITableCellProps>();

const isRuleEditorOpened = ref<boolean>(false);
const ruleTitle = ref<string>(props.value || '');

const isRuleEditor = isUserCanEditPermission();
const editClickHandler = () => {
    const ruleId = (props.rowData?.id && Number.isInteger(props.rowData.id)) ? props.rowData.id : false;
    if (!ruleId) { return }

    isRuleEditorOpened.value = true;
}

const save = (id: number) => {
    const permissionsApi = usePermissionsApi();
    permissionsApi.saveRule(id, ruleTitle.value);
    isRuleEditorOpened.value = false;
}

const submitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const ruleId = (props.rowData?.id && Number.isInteger(props.rowData.id)) ? props.rowData.id : false;
    if (!ruleId) { return }

    save(ruleId);
}
</script>

<template>
  <td
    v-if="isRuleEditor"
    :class="props.class || ''"
  >
    <label
      v-if="isRuleEditorOpened"
      class="rule-editor"
    >
      <form @submit="submitHandler">
        <Input v-model="ruleTitle" />
        <button
          type="submit"
          class="btn btn-success btn-rounded btn-icon"
        >
          <i class="mdi mdi-check" />
        </button>
      </form>
    </label>
    <span 
      v-else
      class="rule-editor-btn"
      @click="editClickHandler"
    >
      {{ props.value || '' }}
      <i class="fa fa-edit" />
    </span>
  </td>
  <td v-else>
    <span>
      {{ props.value || '' }}
    </span>
  </td>
</template>

<style lang="scss">
.rule-editor {

        width: 100%;

        input {
            width: 80%;
            display: inline-block;
        }

        button {
            margin-left: 15px;
        }
}

.rule-editor-btn {
    cursor: pointer;

    i.fa {
        opacity: .2;
        padding-left: 10px;
    }
}
</style>