import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "value-editor-preview"
}
const _hoisted_2 = ["value"]
const _hoisted_3 = { class: "value-editor-conrollers-input" }
const _hoisted_4 = { class: "value-editor-radio-list" }
const _hoisted_5 = ["for"]
const _hoisted_6 = ["id", "value", "onInput", "name"]
const _hoisted_7 = { class: "value-editor-conrollers-btn" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "value-editor-radio-viewer" }

import { ref, watch, onMounted } from 'vue';
import { getUniqId } from '@/utils';


// utils
type TValue = string | boolean | number | null | undefined;

interface IQuestionViewerEditorRadio {
    isEditable?: boolean;
    label?: string;
    dictionary: Record<string, TValue>;
    modelValue: TValue;
    required?: boolean;
}

// props
interface IDictionary {
    [k: string]: {
        id: string;
        value: TValue;
        label: string;
    }
}


export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionViewerEditorRadio',
  props: {
    isEditable: { type: Boolean },
    label: {},
    dictionary: {},
    modelValue: { type: [String, Boolean, Number, null] },
    required: { type: Boolean }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const validateRadio = (): boolean => {
    // валидно если не обязателен и пустой
    if (!props.required && value.value === void 0) {
        return true
    }
    // должен быть 1им из списка
    return !!Object.entries(props.dictionary).find(item=>item[1] == value.value);
}

const props = __props;
const groupName = getUniqId();
const value = ref<TValue>(props.modelValue);
const isValueValid = ref<boolean>(validateRadio());

// dictionary
const prepareDictionary = (): IDictionary => {
    const dict: IDictionary = {};
    for (const key in props.dictionary) {
        dict[key] = {
            id: getUniqId(),
            label: key,
            value: props.dictionary[key],
        }
    }

    return dict;
}

const getLabelByValueFromDictionary = (val: TValue): string => {
    return Object.entries(dictionary.value)
        .find(item=>item[1].value === val)?.[1].label ?? ''
}
const dictionary = ref<IDictionary>({});

onMounted(()=>{
    dictionary.value = prepareDictionary();
});

// actions
const emits = __emit;
watch(()=>props.modelValue, ()=>{
    const modelValue = props.modelValue;
    value.value = modelValue;
    isEditorOpened.value = false;
});

// handlers
const isEditorOpened = ref<boolean>(false);
const editBtnClickHandler = (e: Event)=>{
    e.preventDefault();
    e.stopPropagation();

    isEditorOpened.value = true;
}

const cancelBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    value.value = props.modelValue;
    isEditorOpened.value = false;
    isValueValid.value = validateRadio();
}

const saveValue = ()=>{
    emits('update:modelValue', value.value);
    isEditorOpened.value = false;
}

const saveBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    if ( !validateRadio() ) { return }
    saveValue();
}

const submitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    if ( !validateRadio() ) { return }
    saveValue();
}

const inputHandler = (e: Event, k: string)=>{
    const inputElement = e?.target as { checked: boolean } | null;

    const inputValue: IDictionary[string] | undefined = Object.entries(dictionary.value).find(item=>item[0] === k)?.[1];

    if (inputValue) {
        value.value = inputValue.value;
        inputElement && (inputElement.checked = true);
    }
    else {
        value.value = void 0;
        inputElement && (inputElement.checked = false);
    }

    isValueValid.value = validateRadio();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["value-editor-radio", !isValueValid.value && 'value-editor-error'])
  }, [
    _createElementVNode("label", null, _toDisplayString(props.label ?? ''), 1),
    (props.isEditable)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!isEditorOpened.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("input", {
                  value: getLabelByValueFromDictionary(value.value),
                  disabled: "true",
                  class: "form-control form-control-sm"
                }, null, 8, _hoisted_2),
                (props.isEditable)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      onClick: editBtnClickHandler,
                      class: "btn btn-outline-secondary btn-icon",
                      title: "Редактировать"
                    }, _cache[1] || (_cache[1] = [
                      _createElementVNode("i", { class: "mdi mdi-pencil" }, null, -1)
                    ])))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("form", {
                key: 1,
                onSubmit: submitHandler,
                class: "value-editor-conrollers"
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dictionary.value, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id,
                        class: "form-check form-check-info"
                      }, [
                        _createElementVNode("label", {
                          for: item.id,
                          class: "form-check-label"
                        }, [
                          _withDirectives(_createElementVNode("input", {
                            id: item.id,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
                            value: item.value,
                            onInput: (e)=>inputHandler(e, item.label),
                            type: "radio",
                            name: _unref(groupName),
                            class: "form-check-input"
                          }, null, 40, _hoisted_6), [
                            [_vModelRadio, value.value]
                          ]),
                          _createTextVNode(" " + _toDisplayString(item.label) + " ", 1),
                          _cache[2] || (_cache[2] = _createElementVNode("i", { class: "input-helper" }, null, -1))
                        ], 8, _hoisted_5)
                      ]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("button", {
                    onClick: saveBtnClickHandler,
                    disabled: !isValueValid.value,
                    class: "btn btn-outline-success btn-icon",
                    title: "Применить редактирование"
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "mdi mdi-check" }, null, -1)
                  ]), 8, _hoisted_8),
                  _createElementVNode("button", {
                    onClick: cancelBtnClickHandler,
                    class: "btn btn-outline-danger btn-icon",
                    title: "Отменить редактирование"
                  }, _cache[4] || (_cache[4] = [
                    _createElementVNode("i", { class: "mdi mdi-close" }, null, -1)
                  ]))
                ])
              ], 32))
        ], 64))
      : (_openBlock(), _createElementBlock("input", {
          key: 1,
          value: getLabelByValueFromDictionary(value.value),
          disabled: "true",
          class: "form-control form-control-sm"
        }, null, 8, _hoisted_9)),
    _createElementVNode("div", _hoisted_10, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
}

})