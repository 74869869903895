import {
	generateAsyncDataTableStore,
	definePromiseQueue,
	mapTableFiltersToSearchParams,
} from '@/components/Table';
import { getList, TCmiGetListResDto } from '../bff-service';
import { dateIsoToHumanDate } from '@/utils';
import { TCmiSearchParams } from '../bff-service';


export type TCmiData = {
	id: number;
	site_id: string;
	antrag_id: string;
	fname: string;
	lname: string;
	email: string;
	date: string;
	status: string;
}

const allListPromiseQueue = definePromiseQueue<TCmiGetListResDto>();

export const useAsyncCmiAllListStore = generateAsyncDataTableStore<TCmiData>({
	initialState: {
		loading: true,
	},

	dataGetter: async (useStore) => {
		const store = useStore();

		await allListPromiseQueue.wait(allListGetter, useStore)
			.then(response=>{
				store.data = response.list.map( deal => {
					return {
						id: deal.id,
						site_id: deal.uid,
						antrag_id: deal.antrag_id,
						fname: deal.firstName,
						lname: deal.lastName,
						email: deal.email,
						date: dateIsoToHumanDate(deal.createdAt),
						status: '' 
					};
				});
				store.paginateTotal = response.paginate.total;
				store.paginateFiltered = response.paginate.total;
			})
			.catch(e=>{
				store.data = [];
				store.paginateTotal = 0;
			})
			.finally(()=>{
				store.loading = false;
			})
	}
});

// получение списка заявок
const allListGetter = (): Promise<TCmiGetListResDto> => {
	const store = useAsyncCmiAllListStore();

	// приводим данные фильтров стора таблиц 
	// к виду данных для поиска
	const search = mapTableFiltersToSearchParams<typeof store, TCmiSearchParams>(
		store, // указываем нужный стор
		// указываем данные поиска по дефолту
		{
			uid: '',
			antrag_id: '',
			email: '',
			firstName: '',
			lastName: '',
		},
		// указываем соответствие ключей строра таблиц к ключам данных для поиска
		{
			site_id: 'uid',
			antrag_id: 'antrag_id',
			email: 'email',
			fname: 'firstName',
			lname: 'lastName',
		}
	);

	return getList(store.paginatePage, store.paginatePerPage, search);
}