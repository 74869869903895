<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
import { ITablePaginationPrevNextButton } from '@/components/Table/types';

defineComponent({name: 'KitTablePaginationPrevbtn'});

const props = defineProps<ITablePaginationPrevNextButton>();

</script>

<template>
  <li
    class="paginate_button page-item previous"
    :class="props.isActive ? '' : 'disabled'"
  >
    <a
      aria-controls="order-listing"
      class="page-link"
    >
      <i class="fa fa-arrow-left" />
    </a>
  </li>
</template>

<style lang="scss">
li.previous {
    & {
        user-select: none;
        cursor: pointer;
    }
    &.disabled {
        cursor: no-drop;
    }
}
</style>