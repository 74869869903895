<script setup lang="ts">
import { defineComponent } from 'vue';

defineComponent({ name: "CardTitle" });
</script>

<template>
  <h4 class="card-title">
    <slot />
  </h4>
</template>

<style lang="scss">
.card {
    h4.card-title {
        text-transform: initial;
    }
}
</style>