<script setup lang="ts">
import { defineProps, watch, ref, onUnmounted } from 'vue';

interface IFile {
    stringifedJson?: string | Record<string, any>;
    fileName: string;
}

const props = defineProps<IFile>();

// helpers
const prepareAtragID = (data: any): string => {
    if (!data) { return '' }

    let atragID: string = '';

    if (typeof data === 'string') {
        try {
            const json = JSON.parse(data);
            if (typeof json?.antragId === 'string') {
                atragID = json.antragId
            }
        }
        catch(e) {

        }
    }
    
    return atragID;
}

const prepareFileContent = (data: any): string => {
    if (!data) { return '' }

    let content: string = '';

    if (typeof data === 'string') {
        try {
            const json = JSON.parse(data);
            if (typeof json?.vorlBescheinigungData === 'string') {
                content = json.vorlBescheinigungData
            }
        }
        catch(e) {

        }
    }
    
    return content;
}

// data
const atragID = ref<string>(prepareAtragID(props.stringifedJson));
const fileContent = ref<string>(prepareFileContent(props.stringifedJson));

watch(props, (v) => {
    atragID.value = prepareAtragID(v);
    fileContent.value = prepareFileContent(v);
});
onUnmounted(() => {
    atragID.value = '';
    fileContent.value = '';
});
</script>

<template>
    <a 
        v-if="atragID && fileContent"
        class="btn btn-inverse-primary btn-fw"
        target="_blank"
        :download="props.fileName"
        :href="`data:application/pdf;base64,${fileContent}`"
    >
        Download file
    </a>
</template>


<style lang="scss">
</style>