import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import PageHeader from '@/components/kit/PageHeader.vue';
import PostTable from './components/PostTable.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'PostPage',
  setup(__props) {

// components

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Почтовые отправки ОМС")
      ])),
      _: 1
    }),
    _createVNode(PostTable)
  ], 64))
}
}

})