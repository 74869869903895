<script setup lang="ts">
import { defineComponent, ref, onUpdated } from 'vue';
// components
import PageHeader from '@/components/kit/PageHeader.vue';
import AllDealsTable from './AllDealsTable.vue';
// routes
import { useRoute } from 'vue-router';
import { redirectTo404, cmiStatuses } from '@/router';


const getListType = (): string => {
    const route = useRoute();
    const listType = (route.params?.status ?? '').toString();
    
    if ( !~(Object.values(cmiStatuses) as string[]).indexOf(listType) ) {
        redirectTo404();
        return '';
    }
    return listType;
}

const listType = ref<string>(getListType());

onUpdated(()=>{
    const type = getListType();
    if (type !== listType.value) {
        listType.value = type;
    }
})

defineComponent({ name: 'CmiListPage' });
</script>

<template>
  <PageHeader>ОМС</PageHeader>
  <AllDealsTable v-if="listType === cmiStatuses.all" />
  <p v-else>
    Page type: {{ listType }}
  </p>
</template>
  