import { createApp } from 'vue'
import App from './App.vue'
// stores
import { createPinia } from 'pinia';
// routes
import { router } from '@/router';
// components

// app 
export const app = createApp(App);

// add store
app.use(createPinia());
// add routes
app.use(router);

// mount app
app.mount('#app');

// для плагинов и т.д. экспортируем тип app
export type TApp = typeof app;
// export const rootApp = app;
// plugin example
// const plugin = {
//     install(ap: TApp) {
//         ap.use(router)
//     }
// }
// app.use(plugin);