import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "value-editor-preview"
}
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "value-editor-conrollers-input" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["id"]
const _hoisted_7 = { class: "value-editor-conrollers-btn" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "value-editor-viewer" }

import { ref, watch } from 'vue';
import { getUniqId } from '@/utils';
import { getValueFromEvent } from '@/components/kit';


interface IQuestionViewerEditor {
    isEditable?: boolean;
    label?: string;
    modelValue: string;
    validator?: (str: string) => boolean;
    type?: 'textarea';
}

// props

export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionViewerEditor',
  props: {
    isEditable: { type: Boolean },
    label: {},
    modelValue: {},
    validator: { type: Function },
    type: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const inputId = getUniqId();
const isValid = props.validator || (()=>{return true});
const isValueValid = ref<boolean>(isValid(props.modelValue));

// actions
const emits = __emit;
watch(()=>props.modelValue, ()=>{
    const modelValue = props.modelValue
    value.value = modelValue;
    prevValue.value = modelValue;
    isValueValid.value = isValid(modelValue);
    isEditorOpened.value = false;
});

// handlers
const isEditorOpened = ref<boolean>(false);
const editBtnClickHandler = (e: Event)=>{
    e.preventDefault();
    e.stopPropagation();

    isEditorOpened.value = true;
}

const cancelBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    value.value = props.modelValue;
    isEditorOpened.value = false;
}

const saveValue = ()=>{
    if (!isValueValid.value) { return }
    emits('update:modelValue', value.value);
    isEditorOpened.value = false;
}

const saveBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    saveValue();
}

const submitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    saveValue();
}

const prevValue = ref<string>(props.modelValue);
const inputHandler = (e: Event) => {
    const eventValue = getValueFromEvent(e);

    if (isValid(eventValue)) {
        prevValue.value = eventValue;
        value.value = eventValue;
        isValueValid.value = true;
    }
    else {
        // возвращаем предыдущее значение
        value.value = prevValue.value;
    }
}

const value = ref<string>(props.modelValue ?? '');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["value-editor", !isValueValid.value && 'value-editor-error'])
  }, [
    _createElementVNode("label", {
      for: _unref(inputId),
      class: _normalizeClass(props.isEditable && isEditorOpened.value && 'value-editor-editable')
    }, _toDisplayString(props.label ?? ''), 11, _hoisted_1),
    (props.isEditable)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!isEditorOpened.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("input", {
                  value: value.value.replace(/\s+/g, ' '),
                  disabled: "true",
                  class: "form-control form-control-sm"
                }, null, 8, _hoisted_3),
                (props.isEditable)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      onClick: editBtnClickHandler,
                      class: "btn btn-outline-secondary btn-icon",
                      title: "Редактировать"
                    }, _cache[2] || (_cache[2] = [
                      _createElementVNode("i", { class: "mdi mdi-pencil" }, null, -1)
                    ])))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("form", {
                key: 1,
                onSubmit: submitHandler,
                class: "value-editor-conrollers"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  (props.type)
                    ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                        key: 0,
                        id: _unref(inputId),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
                        onInput: inputHandler,
                        class: "form-control form-control-sm"
                      }, null, 40, _hoisted_5)), [
                        [_vModelText, value.value]
                      ])
                    : _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 1,
                        id: _unref(inputId),
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((value).value = $event)),
                        onInput: inputHandler,
                        class: "form-control form-control-sm"
                      }, null, 40, _hoisted_6)), [
                        [_vModelText, value.value]
                      ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("button", {
                    onClick: saveBtnClickHandler,
                    disabled: !isValueValid.value,
                    class: "btn btn-outline-success btn-icon",
                    title: "Применить редактирование"
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "mdi mdi-check" }, null, -1)
                  ]), 8, _hoisted_8),
                  _createElementVNode("button", {
                    onClick: cancelBtnClickHandler,
                    class: "btn btn-outline-danger btn-icon",
                    title: "Отменить редактирование"
                  }, _cache[4] || (_cache[4] = [
                    _createElementVNode("i", { class: "mdi mdi-close" }, null, -1)
                  ]))
                ])
              ], 32))
        ], 64))
      : (_openBlock(), _createElementBlock("input", {
          key: 1,
          value: value.value.replace(/\s+/g, ' '),
          disabled: "true",
          class: "form-control form-control-sm"
        }, null, 8, _hoisted_9)),
    _createElementVNode("div", _hoisted_10, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
}

})