<script setup lang="ts">
import { ref, watch } from 'vue';
// components
import Input from '@/components/kit/Input.vue';
// store 
import { useAsyncCmiAllListStore } from '../store';


const tableStore = useAsyncCmiAllListStore();
// tableStore;

const uid = ref<string>('');
const fname = ref<string>('');
const lname = ref<string>('');
const email = ref<string>('');

watch([uid, fname, lname, email], ()=>{
    // tableStore.searchBy
    tableStore.filterBy(2, [uid.value])
    tableStore.filterBy(4, [fname.value]);
    tableStore.filterBy(5, [lname.value]);
    tableStore.filterBy(6, [email.value]);
})
</script>

<template>
  <tbody class="cmi-deals-all-filters">
    <tr>
      <td colspan="2">
        Фильтры:
      </td>
      <td>
        <Input
          v-model="uid"
          type="search"
        />
      </td>
      <td />
      <td>
        <Input
          v-model="fname"
          type="search"
        />
      </td>
      <td>
        <Input
          v-model="lname"
          type="search"
        />
      </td>
      <td>
        <Input
          v-model="email"
          type="search"
        />
      </td>
      <td />
    </tr>
  </tbody>
  <tbody
    v-if="tableStore.loading"
    class="cmi-deals-all-filters"
  >
    <tr>
      <td colspan="8">
        Загрузка:
      </td>
    </tr>
  </tbody>
</template>

<style lang="scss">
.cmi-deals-all-filters {
    td {
        color: gray;

        input.form-control {
            padding: 5px;
            min-width: 100px;
        }
    }
}
</style>