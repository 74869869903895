import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

import { defineComponent, ref } from 'vue';
// types
import { ITableTHeadColumn } from './types';



export default /*@__PURE__*/_defineComponent({
  __name: 'THeadColumn',
  props: {
    title: {},
    class: {},
    component: {},
    devMode: { type: Boolean }
  },
  setup(__props: any) {

defineComponent({ name: 'TableTheadcolumn' });
const props = __props;

const componentMode = ref<boolean>(Boolean(props.component));

props.devMode && (() => {
    console.log('thead th', {
        ...props
    })
})();

return (_ctx: any,_cache: any) => {
  return (componentMode.value)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(props.component), {
        key: 0,
        title: props.title,
        class: _normalizeClass(props.class || '')
      }, null, 8, ["title", "class"]))
    : (_openBlock(), _createElementBlock("th", {
        key: 1,
        class: _normalizeClass(props.class || '')
      }, _toDisplayString(props.title), 3))
}
}

})