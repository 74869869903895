import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-lg-12" }
const _hoisted_2 = { class: "col-lg-6" }
const _hoisted_3 = { class: "col-lg-6" }
const _hoisted_4 = { class: "col-lg-12" }
const _hoisted_5 = { class: "col-lg-12" }
const _hoisted_6 = { class: "col-lg-12" }
const _hoisted_7 = { class: "col-lg-12" }

import { defineComponent, reactive, ref } from 'vue';
// boxes
import PageHeader from "@/components/kit/PageHeader.vue";
import Row from "@/components/kit/Row.vue";
import Card from "@/components/kit/Card.vue";
import CardTitle from "@/components/kit/CardTitle.vue";
import FormGroup from "@/components/kit/FormGroup.vue";
// inputs
import Label from "@/components/kit/Label.vue";
import LabelDescription from "@/components/kit/LabelDescription.vue";
import Input from "@/components/kit/Input.vue";
import Checkbox from "@/components/kit/Checkbox.vue";
import Radio from "@/components/kit/Radio.vue";
import Datepicker from "@/components/kit/Datepicker.vue";
import Select from "@/components/kit/Select.vue";
import { TSelectOption } from '@/components/kit/types';
// tables
import TestDataTable from '@/modules/dataTable/SyncDataTable.vue';
import AsyncDataTable from '@/modules/dataTable/AsyncDataTable.vue';
// smart inputs
import {
    QuestionViewer,
    QuestionViewerEditor,
    QuestionViewerEditorRadio,
    QuestionViewerEditorDatepicker,
    QuestionViewerEditorSelect
} from '@/components/kit/QuestionViewer';



export default /*@__PURE__*/_defineComponent({
  __name: 'KIT',
  setup(__props) {

const validator = (data: string) => data.length < 5;

defineComponent({
    name: "DevelopKit",
});

const radio = reactive({
    model: 'М',
    list: ['М', 'Ж']
});

const checkbox = reactive([false, true, true]);

const inputText = ref('test');
const inputTextWithValidation = ref('<5');

const selectOptions: TSelectOption[] = [
    {
        label: '1',
        value: '111'
    },
    {
        label: '2',
        value: '222'
    },
    {
        label: '3',
        value: '333'
    }
];


// dictionaries
const genderDict = {
    "Мужской": true,
    "Женский": false,
}
const genderTest = ref<null | undefined | boolean>(null);
const genderTestVoid0 = ref<null | undefined | boolean>();

// test data
const testDate = ref<Date>(new Date);

// test select
const selectTest = ref<string>('GB'); 
const selectTestMult = ref<string[]>(['GB', 'EN', 'RU']); 

const store = reactive({
    cyrillicName: 'АБВ',
    birthCountry: 'RU'
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, null, {
      default: _withCtx(() => _cache[26] || (_cache[26] = [
        _createTextVNode(" KIT ")
      ])),
      _: 1
    }),
    _createVNode(Row, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(Card, null, {
            default: _withCtx(() => [
              _createVNode(CardTitle, null, {
                default: _withCtx(() => _cache[27] || (_cache[27] = [
                  _createTextVNode(" Бокс ")
                ])),
                _: 1
              }),
              _createVNode(Row, null, {
                default: _withCtx(() => _cache[28] || (_cache[28] = [
                  _createElementVNode("p", null, " Бокс шириной 12 ", -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }),
    _createVNode(Row, { class: "tab-content tab-transparent-content" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(Card, null, {
            default: _withCtx(() => [
              _createVNode(CardTitle, null, {
                default: _withCtx(() => _cache[29] || (_cache[29] = [
                  _createTextVNode(" Бокс 6 ")
                ])),
                _: 1
              }),
              _createVNode(Row, null, {
                default: _withCtx(() => _cache[30] || (_cache[30] = [
                  _createElementVNode("p", null, " Бокс шириной 6 ", -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(Card, null, {
            default: _withCtx(() => [
              _createVNode(CardTitle, null, {
                default: _withCtx(() => _cache[31] || (_cache[31] = [
                  _createTextVNode(" Бокс 6 ")
                ])),
                _: 1
              }),
              _createVNode(Row, null, {
                default: _withCtx(() => _cache[32] || (_cache[32] = [
                  _createElementVNode("p", null, " Бокс шириной 6 ", -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }),
    _createVNode(Row, { class: "tab-content tab-transparent-content" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(Card, null, {
            default: _withCtx(() => [
              _createVNode(CardTitle, null, {
                default: _withCtx(() => _cache[33] || (_cache[33] = [
                  _createTextVNode(" Inputs ")
                ])),
                _: 1
              }),
              _createVNode(Row, null, {
                default: _withCtx(() => [
                  _createVNode(Input, {
                    placeholder: "placeholder & disabled",
                    disabled: true
                  })
                ]),
                _: 1
              }),
              _createVNode(Row, null, {
                default: _withCtx(() => [
                  _cache[48] || (_cache[48] = _createElementVNode("p", { class: "my-sm-3" }, " email ", -1)),
                  _createVNode(Input, {
                    type: "email",
                    placeholder: "email"
                  }),
                  _cache[49] || (_cache[49] = _createElementVNode("p", { class: "my-sm-3" }, " password ", -1)),
                  _createVNode(Input, {
                    type: "password",
                    placeholder: "password"
                  }),
                  _cache[50] || (_cache[50] = _createElementVNode("p", { class: "my-sm-3" }, " textarea ", -1)),
                  _createVNode(Input, {
                    type: "textarea",
                    placeholder: "textarea text"
                  }),
                  _cache[51] || (_cache[51] = _createElementVNode("p", { class: "my-sm-3" }, " Управляемый компонент ", -1)),
                  _createElementVNode("p", null, "Значение: " + _toDisplayString(inputText.value), 1),
                  _createVNode(Input, {
                    modelValue: inputText.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputText).value = $event)),
                    placeholder: "управляемый input"
                  }, null, 8, ["modelValue"]),
                  _cache[52] || (_cache[52] = _createElementVNode("p", { class: "my-sm-3" }, " Управляемый компонент c валидацией (меньше 5 символов) ", -1)),
                  _createElementVNode("p", null, "Значение: " + _toDisplayString(inputTextWithValidation.value), 1),
                  _createVNode(Input, {
                    modelValue: inputTextWithValidation.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((inputTextWithValidation).value = $event)),
                    placeholder: "управляемый input",
                    validator: validator
                  }, null, 8, ["modelValue"]),
                  _cache[53] || (_cache[53] = _createElementVNode("p", { class: "my-sm-3" }, " Input с label ", -1)),
                  _createVNode(FormGroup, null, {
                    default: _withCtx(({id}) => [
                      _createVNode(Label, { for: id }, {
                        default: _withCtx(() => _cache[34] || (_cache[34] = [
                          _createTextVNode("Текст лейбла")
                        ])),
                        _: 2
                      }, 1032, ["for"]),
                      _createVNode(Input, {
                        id: id,
                        placeholder: "input vs label"
                      }, null, 8, ["id"])
                    ]),
                    _: 1
                  }),
                  _cache[54] || (_cache[54] = _createElementVNode("p", { class: "my-sm-3" }, " Input с ошибкой ", -1)),
                  _createVNode(FormGroup, { class: "has-danger" }, {
                    default: _withCtx(({id}) => [
                      _createVNode(Label, { for: id }, {
                        default: _withCtx(() => [
                          _cache[36] || (_cache[36] = _createTextVNode(" Текст лейбла ")),
                          _createVNode(LabelDescription, null, {
                            default: _withCtx(() => _cache[35] || (_cache[35] = [
                              _createTextVNode("Описание для label")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["for"]),
                      _createVNode(Input, {
                        id: id,
                        placeholder: "с ошибкой"
                      }, null, 8, ["id"]),
                      _createVNode(Label, {
                        for: id,
                        class: "error mt-2 text-danger"
                      }, {
                        default: _withCtx(() => [
                          _cache[38] || (_cache[38] = _createTextVNode(" текст ошибки ")),
                          _createVNode(LabelDescription, null, {
                            default: _withCtx(() => _cache[37] || (_cache[37] = [
                              _createTextVNode(" описание текста ошибки ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["for"])
                    ]),
                    _: 1
                  }),
                  _cache[55] || (_cache[55] = _createElementVNode("p", { class: "my-sm-3" }, " Checkboxes ", -1)),
                  _createElementVNode("p", null, "status: " + _toDisplayString(checkbox[0]) + ", " + _toDisplayString(checkbox[1]) + ", " + _toDisplayString(checkbox[2]), 1),
                  _createVNode(FormGroup, null, {
                    default: _withCtx(() => [
                      _createVNode(Checkbox, {
                        modelValue: checkbox[0],
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((checkbox[0]) = $event))
                      }, {
                        default: _withCtx(() => _cache[39] || (_cache[39] = [
                          _createTextVNode(" blank ")
                        ])),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(Checkbox, {
                        modelValue: checkbox[1],
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((checkbox[1]) = $event))
                      }, {
                        default: _withCtx(() => _cache[40] || (_cache[40] = [
                          _createTextVNode(" checked ")
                        ])),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(Checkbox, {
                        modelValue: checkbox[2],
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((checkbox[2]) = $event)),
                        disabled: true
                      }, {
                        default: _withCtx(() => _cache[41] || (_cache[41] = [
                          _createTextVNode(" disabled ")
                        ])),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _cache[56] || (_cache[56] = _createElementVNode("p", { class: "my-sm-3" }, " Radio ", -1)),
                  _createElementVNode("p", null, "значение: " + _toDisplayString(radio.model), 1),
                  _createVNode(FormGroup, null, {
                    default: _withCtx(({id}) => [
                      _createVNode(Radio, {
                        modelValue: radio.model,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((radio.model) = $event)),
                        name: id,
                        value: radio.list[0]
                      }, {
                        default: _withCtx(() => _cache[42] || (_cache[42] = [
                          _createTextVNode(" Муж ")
                        ])),
                        _: 2
                      }, 1032, ["modelValue", "name", "value"]),
                      _createVNode(Radio, {
                        modelValue: radio.model,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((radio.model) = $event)),
                        name: id,
                        value: radio.list[1]
                      }, {
                        default: _withCtx(() => _cache[43] || (_cache[43] = [
                          _createTextVNode(" Жен ")
                        ])),
                        _: 2
                      }, 1032, ["modelValue", "name", "value"]),
                      _createVNode(Radio, {
                        modelValue: radio.model,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((radio.model) = $event)),
                        name: id,
                        value: 'a'
                      }, {
                        default: _withCtx(() => _cache[44] || (_cache[44] = [
                          _createTextVNode(" A ")
                        ])),
                        _: 2
                      }, 1032, ["modelValue", "name"])
                    ]),
                    _: 1
                  }),
                  _createVNode(FormGroup, null, {
                    default: _withCtx(() => [
                      _createVNode(Label, null, {
                        default: _withCtx(() => _cache[45] || (_cache[45] = [
                          _createTextVNode("Date picker")
                        ])),
                        _: 1
                      }),
                      _createVNode(Datepicker)
                    ]),
                    _: 1
                  }),
                  _createVNode(FormGroup, null, {
                    default: _withCtx(() => [
                      _createVNode(Label, { for: "kit-select" }, {
                        default: _withCtx(() => _cache[46] || (_cache[46] = [
                          _createTextVNode("Select")
                        ])),
                        _: 1
                      }),
                      _createVNode(Select, {
                        id: "kit-select",
                        options: selectOptions
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(FormGroup, null, {
                    default: _withCtx(() => [
                      _createVNode(Label, { for: "kit-select" }, {
                        default: _withCtx(() => _cache[47] || (_cache[47] = [
                          _createTextVNode("Select multiple")
                        ])),
                        _: 1
                      }),
                      _createVNode(Select, {
                        id: "kit-select",
                        options: selectOptions,
                        multiple: true
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }),
    _createVNode(Row, { class: "tab-content tab-transparent-content" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(Card, null, {
            default: _withCtx(() => [
              _createVNode(_unref(QuestionViewer), {
                label: "Имя кириллицей",
                value: store.cyrillicName
              }, null, 8, ["value"]),
              _cache[66] || (_cache[66] = _createElementVNode("br", null, null, -1)),
              _cache[67] || (_cache[67] = _createElementVNode("br", null, null, -1)),
              _createVNode(CardTitle, null, {
                default: _withCtx(() => _cache[57] || (_cache[57] = [
                  _createTextVNode("ТЕКСТОВЫЕ")
                ])),
                _: 1
              }),
              _createElementVNode("p", null, "example: " + _toDisplayString(JSON.stringify(store.cyrillicName)), 1),
              _createVNode(_unref(QuestionViewerEditor), {
                label: "Имя кириллицей",
                "is-editable": true,
                modelValue: store.cyrillicName,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((store.cyrillicName) = $event))
              }, null, 8, ["modelValue"]),
              _cache[68] || (_cache[68] = _createElementVNode("p", null, "validation only rus", -1)),
              _createVNode(_unref(QuestionViewerEditor), {
                label: "Имя кириллицей (без валидации)",
                "is-editable": true,
                modelValue: store.cyrillicName,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((store.cyrillicName) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_unref(QuestionViewerEditor), {
                label: "Имя кириллицей",
                "is-editable": true,
                type: "textarea",
                modelValue: store.cyrillicName,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((store.cyrillicName) = $event)),
                validator: (s)=>/^[а-яё\- ]{1,100}$/ig.test(s) || s === ''
              }, {
                default: _withCtx(() => _cache[58] || (_cache[58] = [
                  _createElementVNode("p", null, "Имя кириллицей должно содержать только буквы а-я, пробелы, знак -", -1)
                ])),
                _: 1
              }, 8, ["modelValue", "validator"]),
              _cache[69] || (_cache[69] = _createElementVNode("br", null, null, -1)),
              _cache[70] || (_cache[70] = _createElementVNode("br", null, null, -1)),
              _createVNode(CardTitle, null, {
                default: _withCtx(() => _cache[59] || (_cache[59] = [
                  _createTextVNode("РАДИО")
                ])),
                _: 1
              }),
              _createElementVNode("p", null, " значение store.gender: " + _toDisplayString(JSON.stringify(store.gender)), 1),
              _createVNode(_unref(QuestionViewerEditorRadio), {
                label: "Пол нередактируемый",
                modelValue: store.gender,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((store.gender) = $event)),
                dictionary: genderDict
              }, null, 8, ["modelValue"]),
              _createVNode(_unref(QuestionViewerEditorRadio), {
                label: "Пол",
                "is-editable": true,
                modelValue: store.gender,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((store.gender) = $event)),
                dictionary: genderDict
              }, null, 8, ["modelValue"]),
              _createVNode(_unref(QuestionViewerEditorRadio), {
                label: "Пол",
                "is-editable": true,
                modelValue: store.gender,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((store.gender) = $event)),
                dictionary: genderDict
              }, null, 8, ["modelValue"]),
              _createVNode(_unref(QuestionViewerEditorRadio), {
                label: "Пол NULL",
                "is-editable": true,
                modelValue: genderTest.value,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((genderTest).value = $event)),
                dictionary: genderDict
              }, {
                default: _withCtx(() => _cache[60] || (_cache[60] = [
                  _createElementVNode("p", null, "нужно выбрать 1 из вариантов", -1)
                ])),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_unref(QuestionViewerEditorRadio), {
                label: "Пол обязательный",
                "is-editable": true,
                modelValue: genderTestVoid0.value,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((genderTestVoid0).value = $event)),
                required: true,
                dictionary: genderDict
              }, {
                default: _withCtx(() => _cache[61] || (_cache[61] = [
                  _createElementVNode("p", null, "поле обязательно для заполнения", -1)
                ])),
                _: 1
              }, 8, ["modelValue"]),
              _cache[71] || (_cache[71] = _createElementVNode("br", null, null, -1)),
              _cache[72] || (_cache[72] = _createElementVNode("br", null, null, -1)),
              _createVNode(CardTitle, null, {
                default: _withCtx(() => _cache[62] || (_cache[62] = [
                  _createTextVNode("DATEPICKER")
                ])),
                _: 1
              }),
              _createVNode(_unref(QuestionViewerEditorDatepicker), {
                label: "Дата рождения обычная",
                "is-editable": true,
                modelValue: testDate.value,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((testDate).value = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_unref(QuestionViewerEditorDatepicker), {
                label: "Дата рождения обязательная",
                "is-editable": true,
                modelValue: testDate.value,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((testDate).value = $event)),
                required: true
              }, null, 8, ["modelValue"]),
              _createVNode(_unref(QuestionViewerEditorDatepicker), {
                label: "Дата рождения min 03.12.2023",
                "is-editable": true,
                modelValue: testDate.value,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((testDate).value = $event)),
                "min-date": new Date('03.12.2023')
              }, null, 8, ["modelValue", "min-date"]),
              _createVNode(_unref(QuestionViewerEditorDatepicker), {
                label: "Дата рождения max 03.12.2022",
                "is-editable": true,
                modelValue: testDate.value,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((testDate).value = $event)),
                "max-date": new Date('03.12.2022')
              }, {
                default: _withCtx(() => _cache[63] || (_cache[63] = [
                  _createElementVNode("p", null, "Дата должна быть не более 03.12.2022", -1)
                ])),
                _: 1
              }, 8, ["modelValue", "max-date"]),
              _createVNode(_unref(QuestionViewerEditorDatepicker), {
                label: "Дата рождения без редактирования",
                modelValue: testDate.value,
                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((testDate).value = $event))
              }, null, 8, ["modelValue"]),
              _cache[73] || (_cache[73] = _createElementVNode("br", null, null, -1)),
              _cache[74] || (_cache[74] = _createElementVNode("br", null, null, -1)),
              _createVNode(CardTitle, null, {
                default: _withCtx(() => _cache[64] || (_cache[64] = [
                  _createTextVNode("SELECT")
                ])),
                _: 1
              }),
              _createElementVNode("p", null, _toDisplayString(JSON.stringify(store.birthCountry)), 1),
              _cache[75] || (_cache[75] = _createElementVNode("br", null, null, -1)),
              _createVNode(_unref(QuestionViewerEditor), {
                label: "Имя кириллицей",
                "is-editable": true,
                modelValue: store.birthCountry,
                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((store.birthCountry) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_unref(QuestionViewerEditorSelect), {
                label: "select",
                modelValue: store.birthCountry,
                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((store.birthCountry) = $event)),
                "is-editable": true,
                options: _ctx.countriesList
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_unref(QuestionViewerEditorSelect), {
                label: "select no editable",
                modelValue: store.birthCountry,
                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((store.birthCountry) = $event)),
                options: _ctx.countriesList
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_unref(QuestionViewerEditorSelect), {
                label: "select required",
                modelValue: selectTest.value,
                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((selectTest).value = $event)),
                "is-editable": true,
                required: true,
                options: _ctx.countriesList
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_unref(QuestionViewerEditorSelect), {
                label: "select multiple",
                modelValue: selectTestMult.value,
                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((selectTestMult).value = $event)),
                "is-editable": true,
                multiple: true,
                options: _ctx.countriesList
              }, {
                default: _withCtx(() => _cache[65] || (_cache[65] = [
                  _createElementVNode("p", null, "Список должен содержать только валидные значения", -1)
                ])),
                _: 1
              }, 8, ["modelValue", "options"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }),
    _createVNode(Row, { class: "tab-content tab-transparent-content" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(Card, null, {
            default: _withCtx(() => [
              _createVNode(TestDataTable)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }),
    _createVNode(Row, { class: "tab-content tab-transparent-content" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(Card, null, {
            default: _withCtx(() => [
              _createVNode(AsyncDataTable)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ], 64))
}
}

})