import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { defineComponent } from 'vue';
// components
import { Table } from '@/components/Table';
// types
import { TTableStore } from '@/components/Table/tableStore';



export default /*@__PURE__*/_defineComponent({
  __name: 'Table',
  props: {
    useStore: {}
  },
  setup(__props: any) {

const props = __props;
const store = props.useStore();

// add sort classes for kit styles
store.columns.forEach(column => {
    if (!column.sortable) { return }

    const ordered = (column?.headOrderedClass || '').split(' ').filter(i => i && i!= '--sorting');
    ordered.push('--sorting');
    const orderedAsc = (column?.headOrderedClassAsc || '').split(' ').filter(i => i && i!= '--sorting_asc');
    orderedAsc.push('--sorting_asc');
    const orderedDesc = (column?.headOrderedClassDesc || '').split(' ').filter(i => i && i!= '--sorting_desc');
    orderedDesc.push('--sorting_desc');

    column.headOrderedClass = ordered.join(' ')
    column.headOrderedClassAsc = orderedAsc.join(' ')
    column.headOrderedClassDesc = orderedDesc.join(' ')
})

defineComponent({ name: 'KitTable'});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Table), {
    store: props.useStore,
    class: "table dataTable no-footer"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["store"]))
}
}

})