import { defineComponent as _defineComponent } from 'vue'
import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-check" }
const _hoisted_2 = { class: "form-check-label" }
const _hoisted_3 = ["required", "disabled"]

import { defineComponent, ref } from 'vue';
import { ICheckbox } from './types';
import { getCheckedFromEvent } from './helpers';


// props

export default /*@__PURE__*/_defineComponent({
  __name: 'Checkbox',
  props: {
    modelValue: { type: Boolean },
    disabled: { type: Boolean },
    required: { type: Boolean }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const value = ref(props.modelValue || false);

defineComponent({ name: 'CheckBox' });

// emits
const emits = __emit;

// methods
const inputHandler = (e: Event) => {
    const checked = getCheckedFromEvent(e);
    emits('update:modelValue', checked);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
        class: "form-check-input",
        type: "checkbox",
        required: Boolean(_ctx.required),
        disabled: Boolean(_ctx.disabled),
        onInput: inputHandler
      }, null, 40, _hoisted_3), [
        [_vModelCheckbox, value.value]
      ]),
      _renderSlot(_ctx.$slots, "default"),
      _cache[1] || (_cache[1] = _createElementVNode("i", { class: "input-helper" }, null, -1))
    ])
  ]))
}
}

})