<script setup lang="ts">
import { defineComponent, defineProps, defineEmits, ref } from 'vue';
import { ICheckbox } from './types';
import { getCheckedFromEvent } from './helpers';


// props
const props = defineProps<ICheckbox>();
const value = ref(props.modelValue || false);

defineComponent({ name: 'CheckBox' });

// emits
const emits = defineEmits(['update:modelValue']);

// methods
const inputHandler = (e: Event) => {
    const checked = getCheckedFromEvent(e);
    emits('update:modelValue', checked);
}
</script>

<template>
  <div class="form-check">
    <label class="form-check-label">
      <input
        v-model="value"
        class="form-check-input"
        type="checkbox" 
        :required="Boolean(required)"
                
        :disabled="Boolean(disabled)" 
        @input="inputHandler"
      >
      <slot />
      <i class="input-helper" />
    </label>
  </div>
</template>