import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-wrapper d-flex align-items-center text-center error-page" }
const _hoisted_2 = { class: "row flex-grow" }
const _hoisted_3 = { class: "col-lg-7 mx-auto" }
const _hoisted_4 = { class: "row mt-5" }
const _hoisted_5 = { class: "col-12 text-center mt-xl-2" }

import { defineComponent } from 'vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'NotFound',
  setup(__props) {

defineComponent({ name: 'NotFound' });

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"row align-items-center d-flex flex-row\"><div class=\"col-lg-6 text-lg-right pr-lg-4\"><h1 class=\"display-1 mb-0\"> 404 </h1></div><div class=\"col-lg-6 error-page-divider text-lg-left pl-lg-4\"><h2>SORRY!</h2><h3 class=\"font-weight-light\"> The page you’re looking for was not found. </h3></div></div>", 1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: { path: '/' },
              class: "text-gray font-weight-medium"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Back to home ")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}
}

})