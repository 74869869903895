import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { defineComponent } from 'vue';
// components
import TestCell from './TestCell.vue';
import TestRow from './TestRow.vue';
import TestThead from './TestThead.vue';
import TestTheadTh from './TestTheadTh.vue';
import TestPaginationBtn from './TestPaginationBtn.vue';
import TestPaginationBtnNext from './TestPaginationBtnNext.vue';
import TestEmptyBody from './TestEmptyBody.vue';
import Input from '@/components/kit/Input.vue';
import KitTablePagination from '@/components/kit/Table/TablePagination.vue';
import KitTable from '@/components/kit/Table/Table.vue';
import KitTableSearch from '@/components/kit/Table/TableSearch.vue';
//
// table
import { ETableOrder, Table, TablePagination, useTableSearch } from '@/components/Table';
import { useSyncDataStore, TTestSyncData } from './syncDataStore';


// store

export default /*@__PURE__*/_defineComponent({
  __name: 'SyncDataTable',
  setup(__props) {

const store = useSyncDataStore();

// search
const searchValue = useTableSearch(useSyncDataStore);

// установка данных
store.data = [
    {
        id: 100,
        name: 'first row',
        status: true,
        permissions: [1,2],
    },
    {
        id: 200,
        name: 'second row',
        status: true,
        permissions: [5,6],
    },
    {
        id: 300,
        name: 'third row',
        status: true,
        permissions: [8,9,99],
    },

    ...((): TTestSyncData[]=>{
        return new Array(100).fill(null).map((i,k)=>{
            i;
            return {
                id: 300 + k,
                name: Math.random().toString(36),
                permissions: [k + 100],
                status: Math.random() > .5
            }
        })
    })()
];

// store.data = [
//     {
//         id: 100,
//         name: '1 row',
//         status: true,
//         permissions: [1,2],
//     },
//     {
//         id: 100,
//         name: '2 row',
//         status: true,
//         permissions: [4,5],
//     },
//     {
//         id: 100,
//         name: '3 row',
//         status: true,
//         permissions: [1,3],
//     },
//     {
//         id: 300,
//         name: '4 row',
//         status: true,
//         permissions: [1,2],
//     },
// ];

(window as any).__r = store;

// columns
store.columns = [
    {
        // пример с рендером данных
        key: 'id',
        title: '#',
        render: (data, value, index) => {
            return index + 1 + ''
        },
        cellClass: '--index',
        headClass: '--headclass',

        filterable: true,
    },

    {
        // пример с дефолтным режимом
        key: 'id',
        title: 'ID',
        cellClass: '--id',
        sortable: true,
        searchable: true,

        headClass: '--headclass',
        headOrderedClass: '--sortable',
        headOrderedClassAsc: '--sortAsc',
        headOrderedClassDesc: '--sortDesc',
        headComponent: TestTheadTh,

        filterable: true,
    },

    {
        // с компонентом для th
        key: 'name',
        title: 'name',
        cellClass: '--name',
        headComponent: TestTheadTh,
        // фильтруемый
        filterable: true,
        filteringOrder: 2
    },

    {
        // пример с кастомным компонентом
        key: 'status',
        title: 'custom td',
        cellClass: '--custom-td',
        component: TestCell
    },

    {
        // c колбеком для сортировки
        key: 'permissions',
        title: 'perms',
        render(data, value) {
            if (value && Array.isArray(value)) {
                return '(' + value.join('; ') + ')'
            }
            return '()'
        },

        sortable: true,
        sortingCb: (a,b,asc) => {
            const resT = asc ? 1 : -1;
            const resF = ~resT;

            if (Array.isArray(a) && Array.isArray(b)) {
                return a?.[1] > b?.[1] ? resT : resF;
            }
            return 1;
        },

        searchable: true,
        searchingCb: (search: any, value: TTestSyncData['permissions'], row)=>{
            row;
            const int = parseInt(search);
            console.log('int', int)
            if (isNaN(int)) {
                return true;
            }
            // return true;
            return Boolean(~value.indexOf(int));
        },

        filterable: true,
        filteringOrder: 1,
        filteringCb: (search: number[], value: TTestSyncData['permissions'])=>{
            for (let val of value) {
                if (~search.indexOf(val)) { return true }
            }
            return false;
        }
    },
];
TestCell // @dev:

// сортировка
// setInterval(()=>{
//     if (Math.random() > .5) {
//         store.sortBy(1, ETableOrder.asc);
//     }
//     else {
//         store.sortBy(1, ETableOrder.desc);
//     }
// }, 3e3)
ETableOrder// @dev:

// настройка row
store.row = {
    class: 'row--class',
    // component: TestRow, // кастомный компонент
}
TestRow//@dev:

// настройка tbody
store.body = {
    class: '--tbody',
    emptyTitle: '---',
    // emptyComponent: TestEmptyBody // кастомный компонент
}
TestEmptyBody; // @dev:

// настройка thead
store.head = {
    class: '--class-thead',
    // component: TestThead // кастомный компонент
}
TestThead; //@dev:


// пример с динамичеким изменнением колонок таблиц
// const cold = [...store.columns];
// setInterval(()=>{
//     if (Math.random() > .5) {
//         store.columns = [cold[0], cold[1], cold[2]];
//     }
//     else {
//         store.columns = cold
//     }
// }, 3e3)

defineComponent({name: 'SyncDataTable'});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[13] || (_cache[13] = _createElementVNode("h1", null, "Sync data table", -1)),
    _cache[14] || (_cache[14] = _createElementVNode("p", null, "Данные для таблицы передаются синхронно", -1)),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ()=>_unref(store).sortBy(1, _unref(ETableOrder).asc))
    }, " id: asc "),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = ()=>_unref(store).sortBy(1, _unref(ETableOrder).desc))
    }, " id: desc "),
    _createElementVNode("div", null, [
      _cache[8] || (_cache[8] = _createTextVNode(" search: ")),
      _createVNode(Input, {
        modelValue: _unref(searchValue),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(searchValue) ? (searchValue).value = $event : null)),
        type: "search"
      }, null, 8, ["modelValue"]),
      _createTextVNode(" " + _toDisplayString(_unref(searchValue)), 1)
    ]),
    _createElementVNode("div", null, [
      _cache[9] || (_cache[9] = _createTextVNode(" filter by name: ")),
      _createElementVNode("input", {
        type: "search",
        onInput: _cache[3] || (_cache[3] = (e) => _unref(store).filterBy(2, e.target?.value ? [e.target.value] : null) )
      }, null, 32)
    ]),
    _createElementVNode("div", null, [
      _cache[10] || (_cache[10] = _createTextVNode(" filter by permissions: ")),
      _createElementVNode("button", {
        onClick: _cache[4] || (_cache[4] = ()=>_unref(store).filterBy(4, [1]))
      }, " 1 "),
      _createElementVNode("button", {
        onClick: _cache[5] || (_cache[5] = ()=>_unref(store).filterBy(4, [1, 99]))
      }, " 1, 99 ")
    ]),
    _createElementVNode("div", null, [
      _createTextVNode(" now per page: " + _toDisplayString(_unref(store).paginatePerPage) + "   ", 1),
      _createElementVNode("button", {
        onClick: _cache[6] || (_cache[6] = ()=>_unref(store).setPerPage(_unref(store).paginatePerPage + 10))
      }, " set " + _toDisplayString(_unref(store).paginatePerPage + 10) + " per page ", 1)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        onClick: _cache[7] || (_cache[7] = ()=>_unref(store).clearFilters())
      }, " clear all filters ")
    ]),
    _createVNode(_unref(Table), {
      store: _unref(useSyncDataStore),
      class: "--table"
    }, null, 8, ["store"]),
    _createElementVNode("p", null, _toDisplayString(_unref(store).paginateInfo), 1),
    _createVNode(_unref(TablePagination), {
      store: _unref(useSyncDataStore),
      btn: TestPaginationBtn,
      "next-btn": TestPaginationBtnNext,
      "prev-btn": TestPaginationBtnNext,
      delimeter: TestPaginationBtn
    }, null, 8, ["store"]),
    _cache[15] || (_cache[15] = _createElementVNode("p", null, " ", -1)),
    _cache[16] || (_cache[16] = _createElementVNode("h1", null, "Sync data table with tpl from KIT", -1)),
    _cache[17] || (_cache[17] = _createElementVNode("p", null, " ", -1)),
    _createElementVNode("div", null, [
      _createVNode(KitTableSearch, { "use-store": _unref(useSyncDataStore) }, null, 8, ["use-store"]),
      _cache[11] || (_cache[11] = _createElementVNode("p", null, " ", -1)),
      _createVNode(KitTable, { "use-store": _unref(useSyncDataStore) }, null, 8, ["use-store"]),
      _cache[12] || (_cache[12] = _createElementVNode("p", null, " ", -1)),
      _createVNode(KitTablePagination, { "use-store": _unref(useSyncDataStore) }, null, 8, ["use-store"])
    ])
  ], 64))
}
}

})