<script setup lang="ts">
import { defineComponent } from 'vue';


defineComponent({ name: "PageHeader" });
</script>


<template>
  <div class="page-header">
    <h3 class="page-title">
      <slot />
    </h3>
  </div>
</template>

<style lang="scss">
    .page-header {
        @media screen and (max-width: 540px) {
            & {
                margin: 0 0.5em 1em;
            }
        }
    }
</style>