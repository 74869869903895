import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loader" }

import { defineComponent } from 'vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'Loader',
  setup(__props) {

defineComponent({ name: 'LoaderView' });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "loader-box" }, [
      _createElementVNode("div", { class: "circle-loader" }, [
        _createElementVNode("span", null, "загрузка")
      ])
    ], -1)
  ])))
}
}

})