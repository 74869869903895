import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "value-editor-preview"
}
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "value-editor-conrollers-input" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "value-editor-conrollers-btn" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "value-editor-datepicker-viewer" }

import { ref, watch } from 'vue';
import { getValueFromEvent } from '@/components/kit';
// components
import VueDatePicker, { DatePickerInstance } from '@vuepic/vue-datepicker';
// utils
import {
    getUniqId,
    isPartOfDate,
    isStrDateValid,
    formatDateStr
} from '@/utils';


// utils
type TValue = Date | null;

interface IQuestionViewerEditorDatepicker {
    isEditable?: boolean;
    label?: string;
    modelValue: TValue;
    required?: boolean;
    type?: 'textarea';
    minDate?: Date;
    maxDate?: Date;
}

// props

export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionViewerEditorDatepicker',
  props: {
    isEditable: { type: Boolean },
    label: {},
    modelValue: {},
    required: { type: Boolean },
    type: {},
    minDate: {},
    maxDate: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const validateDate = (): boolean => {
    const minDate = props.minDate;
    const maxDate = props.maxDate;

    const date = value.value;

    // 
    if (props.required && date === null) { return false }

    if (date === null) {
        if (minDate || maxDate) { return false }
        return true;
    }

    if (minDate && minDate > date) { return false }
    if (maxDate && maxDate < date) { return false }

    return true;
}

// types
const props = __props;
const value = ref<TValue>(props.modelValue || null);
const inputValue = ref<string>(props.modelValue?.toLocaleDateString() || '');
const preValue = ref<string>(inputValue.value);
const isValueValid = ref<boolean>(validateDate());


// actions
const emits = __emit;
watch(() => props.modelValue, () => {
    const modelValue = props.modelValue;
    value.value = modelValue;
    inputValue.value = modelValue?.toLocaleDateString() || '';
    preValue.value = inputValue.value;
    isValueValid.value = validateDate();
    isEditorOpened.value = false;
});

watch(value, () => {
    isValueValid.value = validateDate();
});

// handlers
const isEditorOpened = ref<boolean>(false);
const editBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    isEditorOpened.value = true;
}

const cancelBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    value.value = props.modelValue;
    inputValue.value = props.modelValue && props.modelValue.toLocaleDateString() || '';
    preValue.value = inputValue.value;
    isValueValid.value = validateDate();
    isEditorOpened.value = false;
}

const saveValue = () => {
    if (!validateDate()) { return }
    emits('update:modelValue', value.value);
    isEditorOpened.value = false;
}

const saveBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    saveValue();
}

const submitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
}

// datepicker options
const dayNames = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
const inputId = getUniqId();
const datepicker = ref<DatePickerInstance>(null); // связываем компонент datepicker с переменной

// datepicker handlers
const updateHandler = (modelData: string) => {
    if (modelData) {
        const date = new Date(modelData);
        const formatedDate = date.toLocaleDateString();
        inputValue.value = formatedDate;
        preValue.value = formatedDate;
        value.value = date;
    }
    else {
        value.value = null;
    }
}

const clearHandler = () => {
    inputValue.value = '';
    preValue.value = '';
    value.value = null;
}

const blurHandler = () => {
    if (value.value) {
        inputValue.value = new Date(value.value).toLocaleDateString();
        preValue.value = inputValue.value
    }
    else {
        inputValue.value = '';
        preValue.value = '';
        value.value = null;
    }
}

const inputFocusHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    datepicker.value?.openMenu();
}

const inputEnterHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (!validateDate()) { return }
    saveBtnClickHandler(e);
}

const inputHandler = (e: Event) => {
    // при вставке даты c / заменим на точки
    const eventValue = getValueFromEvent(e).replace(/\//g, '.');

    // проверка, для того, чтобы можно было стирать точки
    if (eventValue + '.' === preValue.value) {
        preValue.value = eventValue;
        inputValue.value = eventValue;
        return
    }

    // если стёрли дату
    if (eventValue === '') {
        preValue.value = '';
        inputValue.value = '';
        value.value = null;
        return
    }

    if (isPartOfDate(eventValue)) {
        let doted = '';
        if (eventValue === preValue.value) {
            doted = eventValue;
        }
        else {
            // автодобавление точек
            doted = eventValue.length === 2 ? (eventValue + '.') : (
                eventValue.length === 5 ? (eventValue + '.') : eventValue
            );
        }

        // если дата полная, то отправляем её в дейтпикер
        if (doted.length === 10) {
            if (isStrDateValid(doted)) {
                let setDate: Date | null = new Date(formatDateStr(doted));

                // если указан диапазон дат
                if (props.minDate && props.maxDate) {
                    if (props.minDate > setDate || props.maxDate < setDate) {
                        setDate = null;
                    }
                }
                else {
                    // если указан только minDate
                    if (props.minDate) {
                        if (props.minDate > setDate) {
                            setDate = null;
                        }
                    }
                    else {
                        // если указан только maxDate
                        if (props.maxDate) {
                            if (props.maxDate < setDate) {
                                setDate = null;
                            }
                        }
                    }

                }

                if (setDate) {
                    value.value = setDate;
                    preValue.value = doted;
                    inputValue.value = doted;
                }
                else {
                    value.value = null;
                    inputValue.value = preValue.value;
                }
            }
            else {
                if (value.value) {
                    inputValue.value = value.value.toLocaleDateString();
                    preValue.value = inputValue.value;
                }
                else {
                    const lastValidStr = serachValidStr(doted);
                    inputValue.value = lastValidStr;
                    preValue.value = lastValidStr;
                }
            }
        }
        else {
            preValue.value = doted;
            inputValue.value = doted;
        }
    }
    else {
        // если введён недопустимый символ, то возвращаем предыдущее значение
        inputValue.value = preValue.value;
    }
}

// будем искать последнюю часть строки для даты
const serachValidStr = (date: string): string => {
    let str = date;
    while (str) {
        str = str.substring(0, str.length - 1);
        let strForMatch = str.padEnd(10, '1').split('');
        strForMatch[2] = '.';
        strForMatch[5] = '.';

        if (isStrDateValid(strForMatch.join(''))) {
            return str;
        }
    }
    return ''
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["value-editor-datepicker", !isValueValid.value && 'value-editor-error'])
  }, [
    _createElementVNode("label", {
      for: _unref(inputId),
      class: _normalizeClass(props.isEditable && isEditorOpened.value && 'value-editor-editable')
    }, _toDisplayString(props.label ?? ''), 11, _hoisted_1),
    (props.isEditable)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!isEditorOpened.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("input", {
                  value: value.value && value.value.toLocaleDateString() || '',
                  disabled: "true",
                  class: "form-control form-control-sm"
                }, null, 8, _hoisted_3),
                (props.isEditable)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      onClick: editBtnClickHandler,
                      class: "btn btn-outline-secondary btn-icon",
                      title: "Редактировать"
                    }, _cache[2] || (_cache[2] = [
                      _createElementVNode("i", { class: "mdi mdi-pencil" }, null, -1)
                    ])))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("form", {
                key: 1,
                onSubmit: submitHandler,
                class: "value-editor-conrollers"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_unref(VueDatePicker), {
                    modelValue: value.value,
                    "onUpdate:modelValue": [
                      _cache[1] || (_cache[1] = ($event: any) => ((value).value = $event)),
                      updateHandler
                    ],
                    utc: true,
                    onCleared: clearHandler,
                    "min-date": props.minDate,
                    "max-date": props.maxDate,
                    ref_key: "datepicker",
                    ref: datepicker,
                    locale: "ru",
                    placeholder: "dd.mm.yyyy",
                    format: "dd.MM.yyyy",
                    "enable-time-picker": false,
                    "auto-apply": "",
                    "day-names": dayNames,
                    uid: _unref(inputId),
                    "text-input": ""
                  }, {
                    "dp-input": _withCtx(({ }) => [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
                        onInput: inputHandler,
                        onBlur: blurHandler,
                        onKeydown: _withKeys(inputEnterHandler, ["enter"]),
                        onFocusin: inputFocusHandler,
                        onClick: inputFocusHandler,
                        placeholder: "dd.mm.YYYY",
                        autocomplete: "off",
                        id: _unref(inputId),
                        class: "form-control form-control-sm",
                        type: "text"
                      }, null, 40, _hoisted_5), [
                        [_vModelText, inputValue.value]
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "min-date", "max-date", "uid"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("button", {
                    onClick: saveBtnClickHandler,
                    disabled: !isValueValid.value,
                    class: "btn btn-outline-success btn-icon",
                    title: "Применить редактирование"
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "mdi mdi-check" }, null, -1)
                  ]), 8, _hoisted_7),
                  _createElementVNode("button", {
                    onClick: cancelBtnClickHandler,
                    class: "btn btn-outline-danger btn-icon",
                    title: "Отменить редактирование"
                  }, _cache[4] || (_cache[4] = [
                    _createElementVNode("i", { class: "mdi mdi-close" }, null, -1)
                  ]))
                ])
              ], 32))
        ], 64))
      : (_openBlock(), _createElementBlock("input", {
          key: 1,
          value: value.value && value.value.toLocaleDateString() || '',
          disabled: "true",
          class: "form-control form-control-sm"
        }, null, 8, _hoisted_8)),
    _createElementVNode("div", _hoisted_9, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
}

})