import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { defineComponent } from 'vue';
// components
import PageHeader from '@/components/kit/PageHeader.vue';
import Card from '@/components/kit/Card.vue';
import Row from '@/components/kit/Row.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import MinWidthAndScroll from '@/components/kit/MinWidthAndScroll.vue';
import Table from '@/components/kit/Table/Table.vue';
import TablePagination from '@/components/kit/Table/TablePagination.vue';
import TableSearch from '@/components/kit/Table/TableSearch.vue';
import Loader from '@/components/Loader.vue';
import UserAvatarTd from './UserAvatarTd.vue';
// import UserActionsTd from './UserActionsTd.vue';
import UserDescriptionTd from './UserDescriptionTd.vue';
// store
import { useSyncUserListStore, useUserApi } from './usersStore';



export default /*@__PURE__*/_defineComponent({
  __name: 'UserListPage',
  setup(__props) {

const tableStore = useSyncUserListStore();
const userApiStore = useUserApi();

defineComponent({ name: 'UserList' });

// настройка колонок
tableStore.columns = [
    {
        key: 'avatar',
        title: '#',
        component: UserAvatarTd,
    },

    {
        key: 'id',
        title: 'ID юзера',
        searchable: true,
        sortable: true,
        headClass: 'users-th-id',
    },

    {
        key: 'name',
        title: 'Фио',
        searchable: true,
        sortable: true,
    },

    {
        key: 'email',
        title: 'email',
        searchable: true,
        sortable: true,
    },

    {
        key: 'description',
        title: 'Описание',
        component: UserDescriptionTd,
    },

    // пересено до 1го релиза
    // {
    //     key: 'id',
    //     title: '#',
    //     component: UserActionsTd,
    // }
];

// получение данных сразу для всех юзеров
userApiStore.getList();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Управление пользователями")
      ])),
      _: 1
    }),
    _createVNode(Card, { style: {"position":"relative"} }, {
      default: _withCtx(() => [
        (_unref(userApiStore).loading)
          ? (_openBlock(), _createBlock(Loader, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(CardTitle, null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Cписок пользователей")
          ])),
          _: 1
        }),
        _createVNode(TableSearch, { "use-store": _unref(useSyncUserListStore) }, null, 8, ["use-store"]),
        _createVNode(Row, { class: "tab-content tab-transparent-content" }, {
          default: _withCtx(() => [
            _createVNode(MinWidthAndScroll, null, {
              default: _withCtx(() => [
                _createVNode(Table, { "use-store": _unref(useSyncUserListStore) }, null, 8, ["use-store"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(TablePagination, {
          class: "tab-content tab-transparent-content",
          "use-store": _unref(useSyncUserListStore)
        }, null, 8, ["use-store"])
      ]),
      _: 1
    })
  ], 64))
}
}

})