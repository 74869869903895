<script setup lang="ts">
import { defineComponent } from 'vue';
// components
import PageHeader from '@/components/kit/PageHeader.vue';
import Card from '@/components/kit/Card.vue';
import Row from '@/components/kit/Row.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import MinWidthAndScroll from '@/components/kit/MinWidthAndScroll.vue';
import Table from '@/components/kit/Table/Table.vue';
import TablePagination from '@/components/kit/Table/TablePagination.vue';
import TableSearch from '@/components/kit/Table/TableSearch.vue';
import Loader from '@/components/Loader.vue';
import PermissionTitleTd from './PermissionTitleTd.vue';
import PermissionAdd from './PermissionAdd.vue';
// import TableConstructor from '@/modules/tableConstructor/TableConstructor.vue';
// store
import { useSyncRulesStore, usePermissionsApi } from './permissionsStore';


const tableStore = useSyncRulesStore();
const permissionsApi = usePermissionsApi();

defineComponent({ name: 'UserPermissions' });

// настройка колонок
tableStore.columns = [
    {
        key: 'id',
        title: 'ID права',
        searchable: true,
        sortable: true,
        headClass: 'rules-th-id',
    },

    {
        key: 'title',
        title: 'Наименование действия',
        searchable: true,
        sortable: true,
        headClass: '',
        component: PermissionTitleTd,
    }
];

// получение данных сразу для всех прав
permissionsApi.getList();
</script>

<template>
  <PageHeader>Управление правами на действия</PageHeader>

  <Card style="position:relative">
    <Loader v-if="permissionsApi.loading" />
    <CardTitle>Список прав и действий</CardTitle>
            
    <TableSearch :use-store="useSyncRulesStore" />
        
    <Row class="tab-content tab-transparent-content">
      <MinWidthAndScroll :min-width="400">
        <Table :use-store="useSyncRulesStore">
          <PermissionAdd />
        </Table>
      </MinWidthAndScroll>
    </Row>

    <TablePagination
      class="tab-content tab-transparent-content"
      :use-store="useSyncRulesStore"
    />
  </Card>

  <!-- @dev:
    <Card>
        <TableConstructor />
    </Card> 
    -->
</template>

<style lang="scss">

.rules-th-id {
    width: 100px;
}
</style>