import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["colspan"]

import { ITableBodyEmpty } from '@/components/Table/types';
import { useAsyncPriceAllListStore } from '../store';


export default /*@__PURE__*/_defineComponent({
  __name: 'EmptyTr',
  props: {
    title: {}
  },
  setup(__props: any) {


const tableStore = useAsyncPriceAllListStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("tbody", null, [
    _createElementVNode("tr", null, [
      (!_unref(tableStore).loading)
        ? (_openBlock(), _createElementBlock("td", {
            key: 0,
            colspan: _unref(tableStore).columns.length
          }, " Ничего не найдено ", 8, _hoisted_1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})