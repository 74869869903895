<script setup lang="ts">
import { defineComponent, onMounted } from 'vue';
// components
import {
    Card,
    CardTitle,
    Row,
    MinWidthAndScroll,
    Table,
    TablePagination,
} from '@/components/kit';
import AllDealsFilters from './components/AllDealsFilters.vue';
import { EmptyTr, Row as TableRow } from '@/modules/prices/components';
// store
import { useAsyncPriceAllListStore } from './store/detailsListStore';
import { isUserCanEditPrices, isUserCanViewPrices } from '@/store';

const tableStore = useAsyncPriceAllListStore();

// настройки талицы
tableStore.columns = [
    {
        key: 'id',
        title: '#',
        // component: ActionsTd,
    },
    {
        key: 'title',
        title: 'Имя страницы',
        searchable: true,
        filterable: true,
    },

    {
        key: 'url',
        title: 'URL',
        searchable: true,
        filterable: true,
    },
];

tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
    tableStore.row = {};
}
tableStore.row.component = TableRow; 

onMounted(() => {
    // Очищаем фильтры при возвращении на главную
    tableStore.clearFilters(); 
    tableStore.getData(); 
});

// Получение данных при наличии прав
if (isUserCanViewPrices()) {
    tableStore.getData();
}

defineComponent({name: 'AllPriceTable'});
</script>

<template>
  <Card
    v-if="!isUserCanViewPrices()"
    style="position:relative"
  >
    <CardTitle>Нет прав на просмотр данных</CardTitle>
  </Card>

  <Card
    v-if="isUserCanEditPrices()"
    style="position:relative"
  >
    <CardTitle>Список страниц</CardTitle>

    <Row>
      <MinWidthAndScroll>
        <Table :use-store="useAsyncPriceAllListStore">
          <AllDealsFilters />
        </Table>
      </MinWidthAndScroll>
    </Row>

    <TablePagination
      class="tab-content tab-transparent-content"
      :use-store="useAsyncPriceAllListStore"
    />
  </Card>
</template>