import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { defineComponent } from 'vue';
import { ITableTHeadColumn } from '@/components/Table/types'



export default /*@__PURE__*/_defineComponent({
  __name: 'TestTheadTh',
  props: {
    title: {},
    class: {},
    component: {},
    devMode: { type: Boolean }
  },
  setup(__props: any) {

defineComponent({name: 'TTableth'});

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("th", {
    class: _normalizeClass(props.class || '')
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("i", { class: "mdi mdi-cube menu-icon" }, null, -1)),
    _createTextVNode(_toDisplayString(props.title), 1)
  ], 2))
}
}

})