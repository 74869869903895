
import { generateSyncDataTableStore } from '@/components/Table';
import { defineStore } from 'pinia';


export type TUserData = {
	id: number;
	name: string;
	email: string;
	avatar: string;
	description: string;
}

export const useSyncUserListStore = generateSyncDataTableStore<TUserData>({
	initialState: {
		loading: true,
		paginateInfoTpl: '_DATA_ отфильтровано: _FILTERED_ всего: _TOTAL_',

		body: {
			emptyTitle: 'Пользователи не найдены'
		},

		head: {
			class: 'head-no-select' 
		},
	},
});

// для апи таблицы
export const useUserApi = defineStore({
	id: 'user-list-api',
	state: (): { loading: boolean; list: TUserData[] } =>{
		return {
			loading: true,
			list: []
		}
	},

	actions: {
		getList(clearFilters?: boolean) {
			this.loading = true;
			const tableStore = useSyncUserListStore();
			tableStore.loading = true;
			getData()
				.then(list => {
					tableStore.data = list;
					clearFilters && tableStore.clearFilters();
				})
				.finally(()=>{
					this.loading = false;
					tableStore.loading = false;
				})
		},

		// saveRule(id: number, title: string) {
		//     this.loading = true;
		//     console.log('save rule', id, title); // @dev:
		//     new Promise(res=>{
		//         setTimeout(()=>res(1), 1e3)
		//     })
		//     .finally(()=>{
		//         this.getList()
		//     })
		// },

		// addRule(title: string) {
		//     this.loading = true;
		//     console.log('add new rule', title);
		//     new Promise(res=>{
		//         setTimeout(()=>res(1), 5e2)
		//     })
		//     .finally(()=>{
		//         this.getList()
		//     })
		// }
	}
});


// mock
const mockUsers: TUserData[] = [
	{
		id: 100,
		name: 'Иван Иванов',
		email: 'a@b.com',
		avatar: '/world',
		description: '',
	},
	{
		id: 101,
		name: 'Пётр Петров',
		email: 'c@d.com',
		avatar: '',
		description: 'sadasdas asdasdfsdf sadfsdfsadfsadf sdfsadfsadfsadfasdf sdfasdfasdfasdf',
	},
	{
		id: 102,
		name: 'Фёдор Конюхов',
		email: 'all@world.com',
		avatar: '',
		description: '',
	},
	...((): TUserData[]=>{
		return new Array(30).fill(null).map((i,k)=>{
			return {
				id: k + 1000,
				name: Math.random().toString(36).replace('0.', ''),
				email: Math.random().toString(36).replace('0.', ''),
				avatar: '',
				description: Math.random().toString(36).replace('0.', ''),
			}
		})
	})(),
]

export const getData = async (): Promise<TUserData[]> => {
	// mock
	return new Promise((res, rej) => {
		setTimeout(()=>{
			res(mockUsers);
		}, 1e3)
	})
}