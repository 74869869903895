<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
import { ITableCell } from './types';


const props = defineProps<ITableCell>();

defineComponent({ name: 'TableCell' });
</script>

<template>
  <td :class="props.class || ''">
    {{ props.value || '' }}
  </td>
</template>