import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-header" }
const _hoisted_2 = { class: "page-title" }

import { defineComponent } from 'vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'PageHeader',
  setup(__props) {

defineComponent({ name: "PageHeader" });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})