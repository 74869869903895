import {
	generateAsyncDataTableStore,
	definePromiseQueue,
	mapTableFiltersToSearchParams,
} from '@/components/Table';
import { getPostedList, TCmiGetListResDto, TCmiPostedGetListResDto } from '../bff-service';
import { dateIsoToHumanDate } from '@/utils';
import { TCmiSearchParams } from '../bff-service';


// export type

export type TPostCmiData = {
	id: number;
	uid: string;
	firstName: string;
	lastName: string;
	additionalLastName: string;
	email: string;
	status: boolean;
	postedDate?: string;
	postedUserName?: string;
}

const allListPromiseQueue = definePromiseQueue<TCmiPostedGetListResDto>();

export const useAsyncPostListStore = generateAsyncDataTableStore<TPostCmiData>({
	initialState: {
		loading: true,
	},

	dataGetter: async (useStore) => {
		const store = useStore();

		await allListPromiseQueue.wait(allListGetter, useStore)
			.then(response=>{
				store.data = response.list.map( deal => {

					return {
						id: deal.id,
						uid: deal.uid,
						firstName: deal.firstName,
						lastName: deal.lastName,
						additionalLastName: deal.additionalLastName,
						email: deal.email,
						status: deal.status,
						postedDate: deal.postedDate || void 0,
						postedUserName: deal.postedUserName || void 0,
					};
				});
				store.paginateTotal = response.paginate.total;
				store.paginateFiltered = response.paginate.total;
			})
			.catch(e=>{
				store.data = [];
				store.paginateTotal = 0;
			})
			.finally(()=>{
				store.loading = false;
			})
	}
});

type TCmiPostSearchParams = {
	uid?: string;
	firstName?: string;
	lastName?: string;
	additionalLastName?: string;
	email?: string;
}

// получение списка заявок
const allListGetter = (): Promise<TCmiPostedGetListResDto> => {
	const store = useAsyncPostListStore();

	// приводим данные фильтров стора таблиц 
	// к виду данных для поиска
	const search = mapTableFiltersToSearchParams<typeof store, TCmiPostSearchParams>(
		store, // указываем нужный стор
		// указываем данные поиска по дефолту
		{
			uid: '',
			firstName: '',
			lastName: '',
			additionalLastName: '',
			email: '',
		},
		// указываем соответствие ключей строра таблиц к ключам данных для поиска
		{
			uid: 'uid',
			firstName: 'firstName',
			lastName: 'lastName',
			additionalLastName: 'additionalLastName',
			email: 'email',
		}
	);

	return getPostedList(store.paginatePage, store.paginatePerPage, search);
}