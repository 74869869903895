import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }

import { defineComponent } from 'vue';
import { ILabel } from './types';
import { getUniqId } from '@/utils';


// props

export default /*@__PURE__*/_defineComponent({
  __name: 'FormGroup',
  props: {
    id: {}
  },
  setup(__props: any) {

const props = __props;
const idi = props.id || getUniqId()

defineComponent({ name: 'LabelInput' });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", { id: _unref(idi) })
  ]))
}
}

})