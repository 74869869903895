import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "value-editor-preview"
}
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "value-editor-conrollers-input" }
const _hoisted_5 = { class: "value-editor-conrollers-btn" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["value"]
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "value-editor-viewer" }

import { ref, watch } from 'vue';
import { getUniqId } from '@/utils';
import vSelect from "vue-select";


// utils
type TValue = string | boolean | number | null | undefined;

interface IQuestionViewerEditorSelect {
    isEditable?: boolean;
    label?: string;

    modelValue: TValue | TValue[];
    options: {
        label: string;
        value: TValue;
    }[];

    required?: boolean;
    multiple?: boolean;
    noSearchable?: boolean;
    nothingFoundText?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionViewerEditorSelect',
  props: {
    isEditable: { type: Boolean },
    label: {},
    modelValue: { type: [String, Boolean, Number, null, Array] },
    options: {},
    required: { type: Boolean },
    multiple: { type: Boolean },
    noSearchable: { type: Boolean },
    nothingFoundText: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const getUsedValues = (): IQuestionViewerEditorSelect['options'] => {
    if (props.multiple) {
        return Object.entries(props.options)
            .filter(item => ~(value.value as TValue[]).indexOf(item[1].value))
            .map(item => item[1]);
    }
    const item = Object.entries(props.options).find(item => item[1].value === value.value)?.[1];
    return item ? [item] : [];
}

const getUnusedValues = (): string[] => {
    if (props.multiple) {
        if (!(value.value as TValue[]).length) {
            return [];
        }
    }
    else if (!value.value) {
        return [];
    }

    const used = getUsedValues();

    if (props.multiple) {
        return (value.value as TValue[])
            .filter(item => !used.find(i => i.value === item))
            .map(i => i + '')
    }
    else {
        if (used[0] && used[0].value === (value.value + '')) {
            return [];
        }
        return [value.value + ''];
    }
}

const validateSelect = (): boolean => {
    // валидно если не обязателен и пустой
    if (!props.required) {
        if (props.multiple && (value.value as TValue[])?.length === 0) {
            return true;
        }
        else if (value.value === null) {
            return true;
        }
    }
    // должен быть 1им из списка
    if (props.multiple) {
        return getUsedValues().length === (value.value as TValue[])?.length;
    }
    return Boolean(getUsedValues().length);
}


const getValueHalper = (): TValue | TValue[] => {
    return props.modelValue === null ? null : (props.modelValue || (props.multiple ? [] : ''));
}

// props
const props = __props;
const inputId = getUniqId();
const value = ref<TValue | TValue[]>(getValueHalper());
const unusedValues = ref<string[]>(getUnusedValues());
const isValueValid = ref<boolean>(validateSelect());

// actions
const emits = __emit;
watch(() => props.modelValue, () => {
    value.value = getValueHalper();
    isValueValid.value = validateSelect();
    unusedValues.value = getUnusedValues();
    isEditorOpened.value = false;
});

watch(value, () => {
    isValueValid.value = validateSelect();
    unusedValues.value = getUnusedValues();
});

// handlers
const isEditorOpened = ref<boolean>(false);
const editBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    isEditorOpened.value = true;
}

const cancelBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    value.value = props.modelValue;
    isEditorOpened.value = false;
}

const saveValue = () => {
    if (!isValueValid.value) { return }
    emits('update:modelValue', value.value);
    isEditorOpened.value = false;
}

const saveBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    saveValue();
}

const submitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    saveValue();
}


// methods
const filter = (options: IQuestionViewerEditorSelect['options'], search: string) => {
    const regexp = new RegExp(search, 'i');

    return options.filter((item) => {
        if (typeof item.value === 'number' || typeof item.value === 'string') {
            const itemValue = item.value + '';
            return regexp.test(item.label) || regexp.test(itemValue)
        }
        return regexp.test(item.label);
    })
}

const reduce = (option: IQuestionViewerEditorSelect['options'][number]) => {
    if (option && !option?.value) { return option }
    return option.value && (option.value + '') || '';
}

const getValueLabel = (): string => {
    if (props.multiple) {
        return getUsedValues()
            .map(item => item.label)
            .join(', ');
    }
    const val = getUsedValues()?.[0];
    return val ? val.label : '';
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["value-editor-select", !isValueValid.value && 'value-editor-error'])
  }, [
    _createElementVNode("label", {
      for: _unref(inputId),
      class: _normalizeClass(props.isEditable && isEditorOpened.value && 'value-editor-editable')
    }, _toDisplayString(props.label ?? ''), 11, _hoisted_1),
    (props.isEditable)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!isEditorOpened.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("input", {
                  value: getValueLabel(),
                  disabled: "true",
                  class: "form-control form-control-sm"
                }, null, 8, _hoisted_3),
                (props.isEditable)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      onClick: editBtnClickHandler,
                      class: "btn btn-outline-secondary btn-icon",
                      title: "Редактировать"
                    }, _cache[1] || (_cache[1] = [
                      _createElementVNode("i", { class: "mdi mdi-pencil" }, null, -1)
                    ])))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("form", {
                key: 1,
                onSubmit: submitHandler,
                class: "value-editor-conrollers"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_unref(vSelect), {
                    modelValue: value.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
                    "input-id": _unref(inputId),
                    options: props.options,
                    multiple: props.multiple,
                    filter: filter,
                    searchable: !props.noSearchable,
                    reduce: reduce
                  }, {
                    "no-options": _withCtx(({}) => [
                      _createTextVNode(_toDisplayString(props.nothingFoundText ?? 'Ничего не найдено'), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "input-id", "options", "multiple", "searchable"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("button", {
                    onClick: saveBtnClickHandler,
                    disabled: !isValueValid.value,
                    class: "btn btn-outline-success btn-icon",
                    title: "Применить редактирование"
                  }, _cache[2] || (_cache[2] = [
                    _createElementVNode("i", { class: "mdi mdi-check" }, null, -1)
                  ]), 8, _hoisted_6),
                  _createElementVNode("button", {
                    onClick: cancelBtnClickHandler,
                    class: "btn btn-outline-danger btn-icon",
                    title: "Отменить редактирование"
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "mdi mdi-close" }, null, -1)
                  ]))
                ])
              ], 32))
        ], 64))
      : (_openBlock(), _createElementBlock("input", {
          key: 1,
          value: getValueLabel(),
          disabled: "true",
          class: "form-control form-control-sm"
        }, null, 8, _hoisted_7)),
    (unusedValues.value.length)
      ? (_openBlock(), _createElementBlock("p", _hoisted_8, "Невалидные значения: " + _toDisplayString(unusedValues.value.join(', ')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_9, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
}

})