<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
import { ITableTHeadColumn } from '@/components/Table/types'


defineComponent({name: 'TTableth'});

const props = defineProps<ITableTHeadColumn>();

</script>

<template>
  <th :class="props.class || ''">
    <i class="mdi mdi-cube menu-icon" />{{ props.title }}
  </th>
</template>