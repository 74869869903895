import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { defineComponent, ref, watch } from 'vue';
import vSelect from "vue-select";
// types
import { TSelectOption, ISelect } from '@/components/kit/types';
// utils
import { getUniqId } from '@/utils';


// props

export default /*@__PURE__*/_defineComponent({
  __name: 'Select',
  props: {
    modelValue: {},
    options: {},
    id: {},
    multiple: { type: Boolean },
    noSearch: { type: Boolean },
    disabled: { type: Boolean }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const idi = props.id || getUniqId();
const data = ref<string | string[]>(props.modelValue || []);
const noSearch = props.noSearch || false;
const multiple = Boolean(props.multiple || false);

defineComponent({ name: "custom-select" });

// emits
const emits = __emit;

watch(data, () => {
    if (multiple) {
        emits('update:modelValue', [...data.value]);
    }
    else {
        emits('update:modelValue', data.value);
    }
});

// reduce
const reduce = (option: TSelectOption | string): string => {
    if (typeof option === 'string') {
        return option
    }
    return option.value;
}

// methods
const filter = (options: TSelectOption[], search: string) => {
    const regexp = new RegExp(search, 'i');

    return options.filter((item) => {
        return regexp.test(item.label) || regexp.test(item.value)
    })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
        'formular-select',
        _unref(noSearch) ? '-no-search' : '',
        _unref(multiple) ? '-multiple' : ''
    ].join(' '))
  }, [
    _createVNode(_unref(vSelect), {
      inputId: _unref(idi),
      inputClass: "formular-input",
      options: _ctx.options,
      multiple: _unref(multiple),
      filter: filter,
      searchable: !_unref(noSearch),
      disabled: Boolean(_ctx.disabled),
      reduce: reduce,
      modelValue: data.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((data).value = $event))
    }, {
      "no-options": _withCtx(({}) => _cache[1] || (_cache[1] = [
        _createTextVNode(" Ничего не найдено ")
      ])),
      _: 1
    }, 8, ["inputId", "options", "multiple", "searchable", "disabled", "modelValue"])
  ], 2))
}
}

})