<script setup lang="ts">
import { defineComponent, defineProps, defineEmits, ref, watch } from 'vue';
import vSelect from "vue-select";
// types
import { TSelectOption, ISelect } from '@/components/kit/types';
// utils
import { getUniqId } from '@/utils';


// props
const props = defineProps<ISelect>();

const idi = props.id || getUniqId();
const data = ref<string | string[]>(props.modelValue || []);
const noSearch = props.noSearch || false;
const multiple = Boolean(props.multiple || false);

defineComponent({ name: "custom-select" });

// emits
const emits = defineEmits(['update:modelValue']);

watch(data, () => {
    if (multiple) {
        emits('update:modelValue', [...data.value]);
    }
    else {
        emits('update:modelValue', data.value);
    }
});

// reduce
const reduce = (option: TSelectOption | string): string => {
    if (typeof option === 'string') {
        return option
    }
    return option.value;
}

// methods
const filter = (options: TSelectOption[], search: string) => {
    const regexp = new RegExp(search, 'i');

    return options.filter((item) => {
        return regexp.test(item.label) || regexp.test(item.value)
    })
}
</script>

<template>
    <div :class="[
        'formular-select',
        noSearch ? '-no-search' : '',
        multiple ? '-multiple' : ''
    ].join(' ')">
        <vSelect 
            :inputId="idi" 
            inputClass="formular-input" 
            :options="options" 
            :multiple="multiple" 
            :filter="filter"
            :searchable="!noSearch" 
            :disabled="Boolean(disabled)" 
            :reduce="reduce" 

            v-model="data"
        >
            <template #no-options="{}">
                Ничего не найдено
            </template>
        </vSelect>
    </div>
</template>