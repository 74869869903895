import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tk-success-test"
}

import { watch, ref, onUnmounted } from 'vue';

interface IList {
    stringifedJson?: string | Record<string, any>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TKSuccessTest',
  props: {
    stringifedJson: {}
  },
  setup(__props: any) {

const props = __props;

// helpers
const prepareList = (data: any): string => {
    if (!data) { return '' }

    let atragID: string = '';

    if (typeof data === 'string') {
        try {
            const json = JSON.parse(data);
            if (typeof json?.antragId === 'string') {
                atragID = json.antragId
            }
        }
        catch(e) {

        }
    }
    
    return atragID;
}

// data
const atragID = ref<string>(prepareList(props.stringifedJson));

watch(props, (v) => { atragID.value = prepareList(v) });
onUnmounted(() => { atragID.value = '' });

return (_ctx: any,_cache: any) => {
  return (atragID.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "tk-success-test__title" }, "atragID:", -1)),
        _createElementVNode("p", null, _toDisplayString(atragID.value), 1)
      ]))
    : _createCommentVNode("", true)
}
}

})