<script setup lang="ts">
import { ref, defineEmits, watch, onMounted } from 'vue';
import { getUniqId } from '@/utils';


// utils
const validateRadio = (): boolean => {
    // валидно если не обязателен и пустой
    if (!props.required && value.value === void 0) {
        return true
    }
    // должен быть 1им из списка
    return !!Object.entries(props.dictionary).find(item=>item[1] == value.value);
}

type TValue = string | boolean | number | null | undefined;

interface IQuestionViewerEditorRadio {
    isEditable?: boolean;
    label?: string;
    dictionary: Record<string, TValue>;
    modelValue: TValue;
    required?: boolean;
}

// props
const props = defineProps<IQuestionViewerEditorRadio>();
const groupName = getUniqId();
const value = ref<TValue>(props.modelValue);
const isValueValid = ref<boolean>(validateRadio());

// dictionary
interface IDictionary {
    [k: string]: {
        id: string;
        value: TValue;
        label: string;
    }
}

const prepareDictionary = (): IDictionary => {
    const dict: IDictionary = {};
    for (const key in props.dictionary) {
        dict[key] = {
            id: getUniqId(),
            label: key,
            value: props.dictionary[key],
        }
    }

    return dict;
}

const getLabelByValueFromDictionary = (val: TValue): string => {
    return Object.entries(dictionary.value)
        .find(item=>item[1].value === val)?.[1].label ?? ''
}
const dictionary = ref<IDictionary>({});

onMounted(()=>{
    dictionary.value = prepareDictionary();
});

// actions
const emits = defineEmits(['update:modelValue']);
watch(()=>props.modelValue, ()=>{
    const modelValue = props.modelValue;
    value.value = modelValue;
    isEditorOpened.value = false;
});

// handlers
const isEditorOpened = ref<boolean>(false);
const editBtnClickHandler = (e: Event)=>{
    e.preventDefault();
    e.stopPropagation();

    isEditorOpened.value = true;
}

const cancelBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    value.value = props.modelValue;
    isEditorOpened.value = false;
    isValueValid.value = validateRadio();
}

const saveValue = ()=>{
    emits('update:modelValue', value.value);
    isEditorOpened.value = false;
}

const saveBtnClickHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    if ( !validateRadio() ) { return }
    saveValue();
}

const submitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    if ( !validateRadio() ) { return }
    saveValue();
}

const inputHandler = (e: Event, k: string)=>{
    const inputElement = e?.target as { checked: boolean } | null;

    const inputValue: IDictionary[string] | undefined = Object.entries(dictionary.value).find(item=>item[0] === k)?.[1];

    if (inputValue) {
        value.value = inputValue.value;
        inputElement && (inputElement.checked = true);
    }
    else {
        value.value = void 0;
        inputElement && (inputElement.checked = false);
    }

    isValueValid.value = validateRadio();
}
</script>

<template>
  <div class="value-editor-radio" :class="!isValueValid && 'value-editor-error'">
    <label>{{props.label ?? ''}}</label>
    <template v-if="props.isEditable">
      <div v-if="!isEditorOpened" class="value-editor-preview">
        <input :value="getLabelByValueFromDictionary(value)" disabled="true" class="form-control form-control-sm">
        <button v-if="props.isEditable" @click="editBtnClickHandler" 
          class="btn btn-outline-secondary btn-icon" title="Редактировать">
          <i class="mdi mdi-pencil"></i>
        </button>
      </div>

        <form v-else @submit="submitHandler" class="value-editor-conrollers">
          <div class="value-editor-conrollers-input">
            <div class="value-editor-radio-list">
              <div v-for="item in dictionary" :key="item.id" class="form-check form-check-info">
                <label :for="item.id" class="form-check-label">
                  <input :id="item.id" v-model="value" :value="item.value" @input="(e: Event)=>inputHandler(e, item.label)" 
                    type="radio" :name="groupName" class="form-check-input">
                  {{ item.label }}
                  <i class="input-helper"></i>
                </label>
              </div>
            </div>
          </div>
          
          <div class="value-editor-conrollers-btn">
            <button @click="saveBtnClickHandler" :disabled="!isValueValid" class="btn btn-outline-success btn-icon" title="Применить редактирование">
              <i class="mdi mdi-check"></i>
            </button>
            <button @click="cancelBtnClickHandler" class="btn btn-outline-danger btn-icon" title="Отменить редактирование">
              <i class="mdi mdi-close"></i>
            </button>
          </div>
        </form>

    </template>
    <input v-else :value="getLabelByValueFromDictionary(value)" disabled="true" class="form-control form-control-sm">
    <div class="value-editor-radio-viewer">
      <slot />
    </div>
  </div>
</template>


<style lang="scss">
.value-editor-radio {
  margin: 20px 0;

  .btn.btn-icon {
    height: 2.875rem;
  }

  .value-editor-radio-viewer {
    display: none;
  }

  &.value-editor-error {
    color: red;
    .value-editor-radio-viewer {
      display: initial;
    }
  }

  label {
    cursor: initial;
    color: #000007;
    margin-bottom: 5px;
  }

  .value-editor-preview {
    display: flex;
  }

  .value-editor-conrollers {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;
    align-content: normal;

    .value-editor-conrollers-btn {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: normal;
      align-content: normal;
    }

    .value-editor-conrollers-input {
      width: 100%;
    }
  }
}
</style>