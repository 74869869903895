import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["colspan"]

import { defineComponent, onMounted } from 'vue';
// components
import TestPaginationBtn from './TestPaginationBtn.vue';
import TestPaginationBtnNext from './TestPaginationBtnNext.vue';
//
// table
import { Table, TablePagination, useTableSearch } from '@/components/Table';
import { useAsyncDataStore } from './asyncDataStore';


// store

export default /*@__PURE__*/_defineComponent({
  __name: 'AsyncDataTable',
  setup(__props) {

const store = useAsyncDataStore();
// onMounted
store.clearFilters();
onMounted(()=>store.clearFilters())
// setTimeout(()=>store.getData(), 5e3)

// table

store.columns = [
    {
        key: 'id',
        title: '#id',

        // sorting
        sortable: true,
        headOrderedClass: '--ordered',
        headOrderedClassAsc: '--ordered-asc',
        headOrderedClassDesc: '--ordered-desc',

        // searching
        searchable: true,

        // filtering
        filterable: true,
    },

    {
        key: 'title',
        title: 'name',

        // sorting
        sortable: true,
        headOrderedClass: '--ordered',
        headOrderedClassAsc: '--ordered-asc',
        headOrderedClassDesc: '--ordered-desc',
    }
]

defineComponent({name: 'TAsyncTable'});

// search
const searchInput = useTableSearch(useAsyncDataStore);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[4] || (_cache[4] = _createElementVNode("h1", null, "Async data table", -1)),
    _cache[5] || (_cache[5] = _createElementVNode("p", null, [
      _createTextVNode("Данные для таблицы передаются "),
      _createElementVNode("strong", null, "ассинхронно")
    ], -1)),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(searchInput) ? (searchInput).value = $event : null)),
      type: "search",
      placeholder: "search"
    }, null, 512), [
      [_vModelText, _unref(searchInput)]
    ]),
    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = ()=>_unref(store).filterBy(0, [1,2,3]))
    }, " filter by id "),
    _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("button", {
      onClick: _cache[2] || (_cache[2] = ()=>{ console.log('click');_unref(store).clearFilters()})
    }, " clear filters "),
    _createElementVNode("div", null, [
      _createTextVNode(" now per page: " + _toDisplayString(_unref(store).paginatePerPage) + "   ", 1),
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = ()=>_unref(store).setPerPage(_unref(store).paginatePerPage + 10))
      }, " set " + _toDisplayString(_unref(store).paginatePerPage + 10) + " per page ", 1)
    ]),
    _cache[8] || (_cache[8] = _createElementVNode("p", null, " ", -1)),
    _createVNode(_unref(Table), { store: _unref(useAsyncDataStore) }, {
      default: _withCtx(() => [
        (_unref(store).loading)
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_1, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", {
                  colspan: _unref(store).columns.length
                }, " LOADING... ", 8, _hoisted_2)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["store"]),
    _createElementVNode("p", null, _toDisplayString(_unref(store).paginateInfo), 1),
    _createVNode(_unref(TablePagination), {
      store: _unref(useAsyncDataStore),
      btn: TestPaginationBtn,
      "next-btn": TestPaginationBtnNext,
      "prev-btn": TestPaginationBtnNext,
      delimeter: TestPaginationBtn
    }, null, 8, ["store"])
  ], 64))
}
}

})