<script setup lang="ts">
import { defineComponent, defineProps, ref, defineEmits, toRef, watch } from 'vue';
import { getUniqId } from '@/utils';
import { IInput } from './types';
import { getValueFromEvent } from './helpers';


// props
const props = defineProps<IInput>();

const type = props.type || 'text';
const idi = props.id || getUniqId();
const value = ref(props.modelValue || '');
let prevValue = value.value; // для предыдущего значения типута
const isValid = props.validator || (()=>{return true});
const elementRef = ref(null); // для получения ref к input
const name = toRef(props, 'name');
const onValid = props.onValid || null;
const onInvalid = props.onInvalid || null;

defineComponent({ name: 'KitInput' });

// emits
const emits = defineEmits(['update:modelValue']);
// watch
watch(()=>props.modelValue, ()=>{value.value = props.modelValue || ''})

// methods
const isTextarea = (type: string) => type === 'textarea';

const inputHandler = (e: Event) => {
    const eventValue = getValueFromEvent(e);

    if (isValid(eventValue)) {
        onValid && onValid();
        prevValue = eventValue;
        value.value = eventValue;
        emits('update:modelValue', value.value);
    }
    else {
        onInvalid && onInvalid(eventValue);
        // возвращаем предыдущее значение
        value.value = prevValue;
    }
}
</script>

<template>
  <input 
    v-if="!isTextarea(type)" 
    :id="idi"
    ref="elementRef"
    v-model="value"
    class="form-control"
    :type="type"
    :name="name"
    :placeholder="placeholder || ''" 
    :required="Boolean(required)" 
        
    :disabled="Boolean(disabled)" 
    @input="inputHandler"
  >
  <textarea
    v-if="isTextarea(type)"
    :id="idi"
    ref="elementRef"
    v-model="value"
    class="form-control"
    :name="name"
    :placeholder="placeholder || ''"
    :required="Boolean(required)"

    :disabled="Boolean(disabled)" 
    @input="inputHandler"
  />
</template>