<script setup lang="ts">
import { defineComponent, defineProps, ref } from 'vue';
// types
import { ITableTHeadColumn } from './types';


defineComponent({ name: 'TableTheadcolumn' });
const props = defineProps<ITableTHeadColumn>();

const componentMode = ref<boolean>(Boolean(props.component));

props.devMode && (() => {
    console.log('thead th', {
        ...props
    })
})();
</script>

<template>
  <component 
    :is="props.component" 
    v-if="componentMode"
        
    :title="props.title"
    :class="props.class || ''"
  />

  <th
    v-else
    :class="props.class || ''"
  >
    {{ props.title }}
  </th>
</template>