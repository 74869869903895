// так как юзаем спецификацию dd.MM.yyyy
// а js и Date принимает формат MM.dd.yyyy
// используем подобный хак
export const formatDateStr = (str: string): string => {
	const date = str.split('.');
	return [date[1], date[0], date[2]].join('.');
} 

export const isStrDateValid = (str: string): boolean => {
	const formated = formatDateStr(str);
	return isValidDate(new Date(formated));
}

export const isValidDate = (date: any): boolean => {
	return date instanceof Date && !isNaN(date as any)
}

const regex = /^(\d{1,2}(\.\d{0,2})?)?(\.\d{0,4})?$/;
export const isPartOfDate = (date: string): boolean => {
	return regex.test(date) && date != '.' && date.indexOf('..') < 0
}

export const dateToFormat = (date: Date) => {
	return date.toLocaleDateString('ru')
}

export const dateAddMonths = (date: Date, months: number): Date => {
	const resultDate = new Date(date);
	resultDate.setMonth(date.getMonth() + months);
	return resultDate;
}

export const dateAddYears = (date: Date, years: number): Date => {
	const resultDate = new Date(date);
	resultDate.setFullYear(date.getFullYear() + years);
	return resultDate;
}

export const dateAddDays = (date: Date, days: number): Date => {
	const resultDate = new Date(date);
	resultDate.setDate(date.getDate() + days);
	return resultDate;
}

export const dateToUtcWithTimezone = (date: Date): string => {
	return new Date(new Date(date).getTime() - date.getTimezoneOffset() * 60 * 1000 ).toISOString();
}

export const dateIsoToHumanDate = (iso: string, def?: string) => {
	let result = def || '';
	try {
		result = new Date(iso).toLocaleDateString();
	}
	catch(e) {}
	
	return result;
}

export const dateIsoToHumanTime = (iso: string, def?: string) => {
	let result = def || '';
	try {
		result = new Date(iso).toLocaleTimeString();
	}
	catch(e) {}
	
	return result;
}

export const dateIsoToHumanDateTime = (iso: string, def?: string) => {
	let result = def || '';
	try {
		result = new Date(iso).toLocaleString();
	}
	catch(e) {}
	
	return result;
}

export const dateTimestampToHumanDate = (ts: number, def?: string) => {
	let result = def || '';
	try {
		result = new Date(ts).toLocaleDateString();
	}
	catch(e) {}
	
	return result;
}

export const dateTimestampToHumanTime = (ts: number, def?: string) => {
	let result = def || '';
	try {
		result = new Date(ts).toLocaleTimeString();
	}
	catch(e) {}
	
	return result;
}

export const dateTimestampToHumanDateTime = (ts: number, def?: string) => {
	let result = def || '';
	try {
		result = new Date(ts).toLocaleString();
	}
	catch(e) {}
	
	return result;
}

export const isoToDate = (iso: any): Date | null => {
	let result: Date | null = null;

	if (typeof iso !== 'string') { return result }
	try {
		result = new Date(iso);
	}
	catch(e) {}

	return result instanceof Date && !isNaN(result as any) ? result : null;
}