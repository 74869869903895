<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
import { ITableCellProps } from '@/components/Table/types';


const props = defineProps<ITableCellProps>();
defineComponent({name: 'UserDescriptionTd'});
</script>

<template>
  <td class="user-description-td">
    <span :title="props.value ?? ''">{{ props.value ?? '' }}</span>
  </td>
</template>

<style lang="scss">
table {
    td.user-description-td {
        width: 120px;

        span {
            width: 120px;
            word-wrap: break-word;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }
    }
}
</style>