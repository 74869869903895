import { defineStore } from 'pinia';
import { getItem, updateItem, sendTestTK, sendProdTK, TBffResponseError } from '../bff-service';
import { isoToDate, dateToUtcWithTimezone } from '@/utils';
import { TCmiDealItemUpdateReqDto } from '../types';


type TCmiItemStore = {
	// system
	$loading: boolean;
	$error?: TBffResponseError;
	// data
	id: number;
	uid: string;
	germanyPolicy?: boolean | null;
	residenceCountry: string;
	insuranceType: string;
	insuranceCompany: string;
	payer?: boolean | null;
	insuranceNumber: string;
	insuranceFree?: boolean | null;
	visaType: string;
	firstGermanyWork?: boolean;
	jobChanged?: boolean | null;
	startDate: Date | null;
	firstWorkDate: Date | null;
	grossSalary?: boolean | null;
	privateInsurance?: boolean | null;
	yourselfWork?: boolean | null;
	gender?: boolean | null;
	firstName: string;
	lastName: string;
	birthLastName: string;
	birthDate: Date | null;
	birthPlace: string;
	birthCountry: string;
	citizenship: string;
	hasPostAddress?: boolean | null;
	street: string;
	houseNumber: string;
	mailboxLastName: string;
	postCode: string;
	city: string;
	email: string;
	hasGermanyPhone?: boolean | null;
	germanyPhone: string;
	phone: string;
	metaContacts: string;
	hasChildren?: boolean | null;
	contractForFamily: string;
	additionalLastName: string;
	cyrillicName: string;
	target: string;
	source: string;
	refid: string;
	questions: string;
	erp_updated: string;
	erp_user: number;
	createdAt: string;
	updatedAt: string;
	site_user: number | null;
	antrag_id: string | null;
	erp_comment: string;

	// TK tested data
	tkTestedResponse: {
		deal?: Record<string, any>;
		tk?: Record<string, any>;
		response?: string | Record<string, any>;
	},

	// TK prod data
	tkProdResponse: {
		deal?: Record<string, any>;
		tk?: Record<string, any>;
		response?: string | Record<string, any>;
	},
}

// store
export const useCmiItemStore = defineStore({
	id: 'cmi-item',

	state: (): TCmiItemStore => ({
		$loading: true,
		$error: void 0,
		// data
		id: NaN,
		uid: '',
		germanyPolicy: void 0,
		residenceCountry: '',
		insuranceType: '',
		insuranceCompany: '',
		payer: void 0,
		insuranceNumber: '',
		insuranceFree: void 0,
		visaType: '',
		firstGermanyWork: void 0,
		jobChanged: void 0,
		startDate: null,
		firstWorkDate: null,
		grossSalary: void 0,
		privateInsurance: void 0,
		yourselfWork: void 0,
		gender: void 0,
		firstName: '',
		lastName: '',
		birthLastName: '',
		birthDate: null,
		birthPlace: '',
		birthCountry: '',
		citizenship: '',
		hasPostAddress: void 0,
		street: '',
		houseNumber: '',
		mailboxLastName: '',
		postCode: '',
		city: '',
		email: '',
		hasGermanyPhone: void 0,
		germanyPhone: '',
		phone: '',
		metaContacts: '',
		hasChildren: void 0,
		contractForFamily: '',
		additionalLastName: '',
		cyrillicName: '',
		target: '',
		source: '',
		refid: '',
		questions: '',
		erp_updated: '',
		erp_user: NaN,
		createdAt: '',
		updatedAt: '',
		site_user: null,
		antrag_id: null,
		erp_comment: '',

		// TK tested data
		tkTestedResponse: {
			response: '',
		},

		tkProdResponse: {
			response: '',
		}
	}),

	actions: {
		getDataById(dealId: number) {
			this.$loading = true;
			this.id = NaN;

			getItem(dealId)
				.then((responseData)=>{
					this.$error = void 0;

					const {
						startDate,
						firstWorkDate,
						birthDate,
						...data
					} = responseData;

					// получаем даты и приводим их к типу Date
					const dates = {
						startDate: isoToDate(startDate),
						firstWorkDate: isoToDate(firstWorkDate),
						birthDate: isoToDate(birthDate),
					};
					

					Object.assign(
						this, 
						{
							...dates,
							...data,
							erp_comment: data.erp_comment ?? '',
						}
					);
				})
				.catch((e: TBffResponseError)=>{
					this.$error = e;
				})
				.finally(()=>{
					this.$loading = false;
				});
		},

		updateData(keyList: string[]) {
			this.$loading = true;

			const dealId = this.id;
			const dto = this.dto;
			const data = Object.fromEntries(keyList
				.filter(key=>key in this)
				.map(key=>{
					return [key, dto[key as keyof typeof dto]]
				}));

			updateItem(dealId, data)
				.then(()=>{
					this.$error = void 0;
				})
				.catch((e: TBffResponseError)=>{
					this.$error = e;
				})
				.finally(()=>{
					this.$loading = false;
				});
		},

		sendTestTK() {
			this.$loading = true;
			sendTestTK(this.id)
				.then((responseData)=>{
					console.log('sendTestTK responseData', responseData);
					this.$error = void 0;
					
					this.tkTestedResponse.deal = responseData.deal ?? void 0;
					this.tkTestedResponse.tk = responseData.data_to_tk ?? void 0;
					this.tkTestedResponse.response = responseData.result ?? void 0;
				})
				.catch((e: TBffResponseError)=>{
					this.$error = e;
					this.tkTestedResponse.deal = void 0;
					this.tkTestedResponse.tk = void 0;
					this.tkTestedResponse.response = void 0;
				})
				.finally(()=>{
					this.$loading = false;
				});
		},

		sendProdTK() {
			this.$loading = true;
			sendProdTK(this.id)
				.then((responseData)=>{
					console.log('sendProdTK responseData', responseData);
					this.$error = void 0;
					
					this.tkProdResponse.deal = responseData.deal ?? void 0;
					this.tkProdResponse.tk = responseData.data_to_tk ?? void 0;
					this.tkProdResponse.response = responseData.result ?? void 0;
				})
				.catch((e: TBffResponseError)=>{
					this.$error = e;
					this.tkProdResponse.deal = void 0;
					this.tkProdResponse.tk = void 0;
					this.tkProdResponse.response = void 0;
				})
				.finally(()=>{
					this.$loading = false;
				});
		}
	},


	getters: {
		dto(): TCmiDealItemUpdateReqDto {
			return {
				germanyPolicy: this.germanyPolicy ?? void 0,
				residenceCountry: this.residenceCountry,
				insuranceType: this.insuranceType,
				insuranceCompany: this.insuranceCompany,
				payer: this.payer ?? void 0,
				insuranceNumber: this.insuranceNumber,
				insuranceFree: this.insuranceFree ?? void 0,
				visaType: this.visaType,
				firstGermanyWork: this.firstGermanyWork ?? void 0,
				jobChanged: this.jobChanged ?? void 0,
				startDate: this.startDate ? dateToUtcWithTimezone(this.startDate) : void 0,
				firstWorkDate: this.firstWorkDate ? dateToUtcWithTimezone(this.firstWorkDate) : void 0,
				grossSalary: this.grossSalary ?? void 0,
				privateInsurance: this.privateInsurance ?? void 0,
				yourselfWork: this.yourselfWork ?? void 0,
				gender: this.gender ?? void 0,
				firstName: this.firstName,
				lastName: this.lastName,
				birthLastName: this.birthLastName,
				birthDate: this.birthDate ? dateToUtcWithTimezone(this.birthDate) : void 0,
				birthPlace: this.birthPlace,
				birthCountry: this.birthCountry,
				citizenship: this.citizenship,
				hasPostAddress: this.hasPostAddress ?? void 0,
				street: this.street,
				houseNumber: this.houseNumber,
				mailboxLastName: this.mailboxLastName,
				postCode: this.postCode,
				city: this.city,
				email: this.email,
				hasGermanyPhone: this.hasGermanyPhone ?? void 0,
				germanyPhone: this.germanyPhone,
				phone: this.phone,
				metaContacts: this.metaContacts,
				hasChildren: this.hasChildren ?? void 0,
				contractForFamily: this.contractForFamily,
				additionalLastName: this.additionalLastName,
				cyrillicName: this.cyrillicName,
				target: this.target,
				source: this.source,
				questions: this.questions,
				antrag_id: this.antrag_id ?? void 0,
				erp_comment: this.erp_comment,
			}
		}	
	},
});