import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["colspan"]

import { defineComponent, ref, watch } from 'vue';
// components
import Tbody from './Tbody.vue';
import Thead from './Thead.vue';
// types
import { ITable } from './types';



export default /*@__PURE__*/_defineComponent({
  __name: 'Table',
  props: {
    store: {},
    devMode: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

defineComponent({ name: 'TableTable' });

const store = props.store();

// создаём ref, чтобы не пересчитывать каждый раз
const viewData = ref<typeof store.viewData>(store.viewData);
watch(() => store.viewData, () => { viewData.value = store.viewData });

const isEmptyRowComponent = ref<boolean>(Boolean(store?.body?.emptyComponent));
watch(() => store?.body?.emptyComponent, () => {
    isEmptyRowComponent.value = Boolean(store?.body?.emptyComponent);
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", null, [
    _createVNode(Thead, {
      store: props.store,
      "dev-mode": props.devMode
    }, null, 8, ["store", "dev-mode"]),
    _renderSlot(_ctx.$slots, "default"),
    (viewData.value.length && !_unref(store).loading)
      ? (_openBlock(), _createBlock(Tbody, {
          key: 0,
          store: props.store,
          "dev-mode": props.devMode
        }, null, 8, ["store", "dev-mode"]))
      : _createCommentVNode("", true),
    (!viewData.value.length && !isEmptyRowComponent.value)
      ? (_openBlock(), _createElementBlock("tbody", _hoisted_1, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", {
              colspan: _unref(store).columns.length
            }, _toDisplayString(_unref(store)?.body?.emptyTitle || ''), 9, _hoisted_2)
          ])
        ]))
      : _createCommentVNode("", true),
    (!viewData.value.length && isEmptyRowComponent.value)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(store)?.body?.emptyComponent), {
          key: 2,
          title: _unref(store)?.body?.emptyTitle || ''
        }, null, 8, ["title"]))
      : _createCommentVNode("", true)
  ]))
}
}

})