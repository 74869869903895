import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "paginate_button page-item delimeter" }

import { defineComponent } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PaginationDelimeter',
  setup(__props) {

defineComponent({name: 'KitTablePaginationDelimeter'});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("a", { class: "page-link delimeter" }, [
      _createElementVNode("i", { class: "fa fa-ellipsis-h" })
    ], -1)
  ])))
}
}

})