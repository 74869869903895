<script lang="ts">
import { defineComponent } from 'vue';
import Wrap from '@/components/Wrap.vue';

import { version } from '../package.json';
(window as any)._app = { version };

export default defineComponent({
    name: 'App',
    components: {
        Wrap
    }
});
</script>

<template>
  <Wrap />
</template>

<style lang="scss">
  @import "./assets/css/master.css";
  @import "./assets/css/loader.scss";
</style>
