import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = ["disabled"]

import { onMounted, onUnmounted, ref, reactive, watch } from 'vue';
import { isUserCanEditPostCmi } from '@/store';
// routes
import { useRoute } from 'vue-router';
// components
import { Card, CardTitle, PageHeader, Loader } from '@/components/kit';
import {
    QuestionViewer,
    QuestionViewerEditorRadio,
    QuestionViewerEditorDatepicker,
} from '@/components/kit/QuestionViewer';
// store
import { useCmiPostedItemStore } from './store'; 



export default /*@__PURE__*/_defineComponent({
  __name: 'PostViewerEditorPage',
  setup(__props) {

const dictionaryPosted = {
	"Отправлено": true,
	"Не отправлено": false
}

const store = useCmiPostedItemStore();

const dealId = ref<number>(NaN);
onMounted(()=>{
    const route = useRoute();
    const deal = parseInt((route?.params?.id ?? '') + '', 10);
    if ( !Number.isNaN(deal) ) {
        dealId.value = deal;
        store.getDataById(deal);
    }
});

const initialStatus = ref<boolean>();
const initialDate = ref<Date>();

onUnmounted(()=>{
    store.$reset();
});

const isSaveEnabled = ref(false);
watch(()=>[ store.$loading, store.status, store.postedDate ], ({0: loading})=>{
    if (loading) {
        initialStatus.value = store.status;
        initialDate.value = store.postedDate;
        return
    }

    isSaveEnabled.value = store.status !== initialStatus.value || store.postedDate !== initialDate.value;
})

// handlers
const savePostDataHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    store.saveDataById();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(PageHeader), null, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode("Почтовая отправка ОМС")
      ])),
      _: 1
    }),
    (!_unref(isUserCanEditPostCmi)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_unref(store).$error)
      ? (_openBlock(), _createBlock(_unref(Card), { key: 1 }, {
          default: _withCtx(() => [
            (_unref(store).$loading)
              ? (_openBlock(), _createBlock(_unref(Loader), { key: 0 }))
              : _createCommentVNode("", true),
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => [
                _createTextVNode("Заявка ОМС № " + _toDisplayString(dealId.value), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_unref(QuestionViewer), {
                label: "ID заявки на сайте",
                value: _unref(store).uid,
                class: "value-viewer col-sm-12 col-md-4"
              }, null, 8, ["value"]),
              _createVNode(_unref(QuestionViewer), {
                label: "Email",
                value: _unref(store).email,
                class: "value-viewer col-sm-12 col-md-4"
              }, null, 8, ["value"])
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(QuestionViewer), {
                label: "Имя",
                value: _unref(store).firstName,
                class: "value-viewer col-sm-12 col-md-4"
              }, null, 8, ["value"]),
              _createVNode(_unref(QuestionViewer), {
                label: "Фамилия",
                value: _unref(store).lastName,
                class: "value-viewer col-sm-12 col-md-4"
              }, null, 8, ["value"]),
              _createVNode(_unref(QuestionViewer), {
                label: "Фамилия супруга",
                value: _unref(store).additionalLastName,
                class: "value-viewer col-sm-12 col-md-4"
              }, null, 8, ["value"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(QuestionViewerEditorRadio), {
                label: "Статус",
                class: "col-lg-4 col-md-4 col-sm-12",
                modelValue: _unref(store).status,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(store).status) = $event)),
                dictionary: dictionaryPosted,
                "is-editable": true
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createElementVNode("p", null, null, -1)
                ])),
                _: 1
              }, 8, ["modelValue"]),
              (_unref(store).status && _unref(store).postedDate)
                ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorDatepicker), {
                    key: 0,
                    label: "Дата отправки",
                    class: "col-lg-4 col-md-4 col-sm-12",
                    modelValue: _unref(store).postedDate,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(store).postedDate) = $event)),
                    "is-editable": true
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("p", null, null, -1)
                    ])),
                    _: 1
                  }, 8, ["modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_unref(store).status && _unref(store).postedUserName)
                ? (_openBlock(), _createBlock(_unref(QuestionViewer), {
                    key: 0,
                    label: "Отправил",
                    value: _unref(store).postedUserName,
                    class: "value-viewer"
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("button", {
              onClick: savePostDataHandler,
              class: "btn btn-inverse-primary btn-fw",
              disabled: !isSaveEnabled.value
            }, " Сохранить ", 8, _hoisted_5)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_unref(Card), { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Произошла ошибка")
              ])),
              _: 1
            }),
            _createElementVNode("p", null, _toDisplayString(_unref(store).$error), 1)
          ]),
          _: 1
        }))
  ], 64))
}
}

})