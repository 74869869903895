<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
// components
import { Table } from '@/components/Table';
// types
import { TTableStore } from '@/components/Table/tableStore';


const props = defineProps<{ useStore: TTableStore<any> }>();
const store = props.useStore();

// add sort classes for kit styles
store.columns.forEach(column => {
    if (!column.sortable) { return }

    const ordered = (column?.headOrderedClass || '').split(' ').filter(i => i && i!= '--sorting');
    ordered.push('--sorting');
    const orderedAsc = (column?.headOrderedClassAsc || '').split(' ').filter(i => i && i!= '--sorting_asc');
    orderedAsc.push('--sorting_asc');
    const orderedDesc = (column?.headOrderedClassDesc || '').split(' ').filter(i => i && i!= '--sorting_desc');
    orderedDesc.push('--sorting_desc');

    column.headOrderedClass = ordered.join(' ')
    column.headOrderedClassAsc = orderedAsc.join(' ')
    column.headOrderedClassDesc = orderedDesc.join(' ')
})

defineComponent({ name: 'KitTable'});
</script>

<template>
  <Table 
    :store="props.useStore"
    class="table dataTable no-footer"
  >
    <slot />
  </Table>
</template>

<style lang="scss">
.dataTable {

    & th {
        font-weight: 600;
    }

    .--sorting {
        padding-right: 30px;
        cursor: pointer;
        position: relative;

        &::before {
            line-height: 2.5;
            font-family: Material Design Icons;
            font-size: .65rem;
            content: "\F05D";
            right: 1.2em;
            position: absolute;
            bottom: 0.9em;
            display: block;
            opacity: 0.3;
        }

        &::after {
            line-height: 2.5;
            font-family: Material Design Icons;
            font-size: .65rem;
            content: "\F045";
            right: 0.2em;
            position: absolute;
            bottom: 0.9em;
            display: block;
            opacity: 0.3;
        }
    }

    .--sorting_asc {
        &::before {
            opacity: 1;
        }
    }

    .--sorting_desc {
        &::after {
            opacity: 1;
        }
    }

    .head-no-select {
        th {
            -webkit-user-select: none;
            /* Safari */
            user-select: none;
        }
    }
}
</style>