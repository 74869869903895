import { 
	generateAsyncDataTableStore, 
	definePromiseQueue,
	TAsyncDataTableStore, 
} from '@/components/Table/tableStore';


export type TTestAsyncData = {
	id: number;
	title: string;
	// status: boolean;
	// permissions: number[];
}

type TTestDto = {
	completed: boolean;
	id: number;
	title: string;
	userId: number;
}[];


const testAsyncPromiseQueue = definePromiseQueue<TTestDto>(300);


export const useAsyncDataStore = generateAsyncDataTableStore<TTestAsyncData>({
	initialState: {    
		paginateInfoTpl: 'total: _TOTAL_ filtered: _FILTERED_',
	},

	onDataStateChange(useStore) {
		const store = useStore();
		console.log('on Async DataStateChange', store.paginateFiltered)
	},

	dataGetter: async (useStore) => {
		console.log('get data from async store'); //@dev:

		const store = useStore();

		await testAsyncPromiseQueue.wait(testGetter, useStore)
			.then((r)=>{
				// устанавливаем данные стора
				store.data = r;
				store.paginateTotal = r.length;
				store.paginateFiltered = r.length;
			})
			.catch((e: Error)=>{
				// выводим ошибку
				console.error('error::', e)
				// если ошибка не по таймауту, то чистим стор
				if (e) {
					store.data = [];
					store.paginateTotal = 0;
					store.paginateFiltered = 0;
				}
			})

		// await mockdata(
		//     store.paginatePerPage, // limit
		//     store.paginatePerPage * (store.paginatePage - 1), // offset
		//     sorting, // sorting
		//     search, // searching
		//     filters, // filters
		// ).then((data)=>{
		//     store.data = data.data;
		//     store.paginateTotal = mockData.length;
		//     store.paginateFiltered = data.total;
		// })
	}
});


// MOCK DATA

const mock = {
	total: 100,
	limit: 10,
	offset: 0,
	data: [

	] as TTestAsyncData[]
};// as const;

const mockData = [
	...((): TTestAsyncData[]=>{
		return new Array(300).fill(null).map((i,k)=>{
			i;
			return {
				id: 1000 + k,
				title: Math.random().toString(36),
				permissions: [k + 100],
				status: Math.random() > .5
			}
		})
	})()
]

const mockdata = async (limit: number, offset: number, sorting: any, search: string, filters: any[]): Promise<typeof mock> => {

	let resultData = mockData;

	if (sorting) {
		const resT = sorting.type === 'asc' ? 1 : -1;
		const resF = ~resT;

		resultData = resultData.sort((a,b)=>{
			return a.id > b.id ? resT : resF
		})
	}

	console.log('%csearch', 'background: green', search);
	console.log('%c:filtering', 'background:gray', filters);
	console.log('%c:sorted', 'background:red', sorting);

	resultData = resultData.slice(offset, offset+limit);

	return new Promise(res=>{
		setTimeout(()=>{
			res({
				total: mockData.length,
				limit,
				offset: offset,
				data: resultData
			})
		}, 5e2)
	})
}


const testGetter = async (useStore: TAsyncDataTableStore<any>): Promise<TTestDto> => {
	const store = useStore();
	console.log(
		'%c::page',
		'background: red',
		store.paginatePage
	)

	// // @dev: 
	// // вот тут нужны хелперы
	// const sortingList = [...store.order].filter(i=>i);
	// const sorting = sortingList[0];
	// const search = store.search;
	// const filters = [...store.filters].filter(i=>i);
	const search = store.search ? ('&userId=' + store.search) : ''

	// const endpoint = 'https://asd';
	// const endpoint = 'asd';
	// const endpoint = '/dashboard';
	const endpoint = 'https://jsonplaceholder.typicode.com/todos?' + search;

    
	return new Promise((res, rej)=>{
		fetch(endpoint)
			.then(async response=>{
            
				if (response.status === 200) {
					let result: TTestDto = [];
					try {
						const text = await response.text();
						result = JSON.parse(text);
					}
					catch(e: any) {
						console.log('parse error', e)
						// result = {e: }
						rej('parse error:: ' + (e?.message || '') );
						return;
					}

					res(result)
				}
				else {
					console.log('net err', response.status );
					rej('net err, code:: ' + response.status)
				}
			})
			.catch((e: Error)=>{
				rej('net err 2:: ' + e.message)
			});
	})
}

// // // @dev: promise queue
// const promiseQueue = {
//     list: [] as number[],

//     last: 0 as number,

//     // set(getter: Promise<>) {
//     set() {

//         const id = this.last + 1;
//         this.last = id;

//         return new Promise((res, rej) => {
//             setTimeout(()=>{

//                 // выполняем указанный коллбек
//                 // или не выполняем, если уже пришёл другой
    
//                 if (this.last === id) {
//                     // тут вызываем колбек
//                     // а в его ответе проверяем, можно ли отдавать его ответ
//                     testGetter(id)
//                         .then((r)=>res(r))
//                         .catch((e: Error)=>{rej(e)})
//                 }
//                 else {
//                     // сразу блокируем и в ответ кетч
//                     rej()
//                 }
    
                
//             }, 1e3)
//         });
//     },
// };

// (async ()=>{
//     await new Promise(r=>setTimeout(()=>{console.log('queue started'); r(1)}, 5e3));

//     promiseQueue.set().then((d)=>console.log('d', d)).catch(e=>console.error('e', e));
//     promiseQueue.set().then((d)=>console.log('d', d)).catch(e=>console.error('e', e));
//     promiseQueue.set().then((d)=>console.log('d', d)).catch(e=>console.error('e', e));
//     promiseQueue.set().then((d)=>console.log('d', d)).catch(e=>console.error('e', e));
//     promiseQueue.set().then((d)=>console.log('d', d)).catch(e=>console.error('e', e));
// })()