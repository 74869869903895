<script setup lang="ts">
import { defineComponent } from 'vue';
// components
import {
    Card,
    CardTitle,
    Row,
    // tables
    MinWidthAndScroll,
    Table,
    TablePagination,
} from '@/components/kit';
import { 
    AllDealsFilters,
    EmptyTr,
    // ActionsTd,
    Row as TableRow
} from './components';
// store 
import { useAsyncCmiAllListStore } from './store';
import { isUserCanViewCmi } from '@/store';


const tableStore = useAsyncCmiAllListStore();

// настройки талицы
tableStore.columns = [
    {
        key: 'id',
        title: '#',
        // component: ActionsTd,
    },
    {
        key: 'status',
        title: 'Статус'
    },

    {
        key: 'site_id',
        title: 'ID',
        searchable: true,
        filterable: true,
    },
    
    {
        key: 'antrag_id',
        title: 'Antrag ID',
        searchable: true,
    },
    
    {
        key: 'fname',
        title: 'Имя',
        searchable: true,
        filterable: true,
    },
    
    {
        key: 'lname',
        title: 'Фамилия',
        searchable: true,
        filterable: true,
    },
    
    {
        key: 'email',
        title: 'email',
        searchable: true,
        filterable: true,
    },
    {
        key: 'date',
        title: 'Дата',
        // sortable: true,
    },

];

tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
    tableStore.row = {};
}
tableStore.row.component = TableRow;

// получение данных
if (isUserCanViewCmi()) {
    tableStore.getData()
}

defineComponent({name: 'AllDealTable'});
</script>

<template>
  <Card
    v-if="!isUserCanViewCmi()"
    style="position:relative"
  >
    <CardTitle>Нет прав на просмотр данных</CardTitle>
  </Card>

  <Card
    v-if="isUserCanViewCmi()"
    style="position:relative"
  >
    <CardTitle>Полный список заявок</CardTitle>

    <Row>
      <MinWidthAndScroll>
        <Table :use-store="useAsyncCmiAllListStore">
          <AllDealsFilters />
        </Table>
      </MinWidthAndScroll>
    </Row>

    <TablePagination
      class="tab-content tab-transparent-content"
      :use-store="useAsyncCmiAllListStore"
    />
  </Card>
</template>