import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "cmi-btn-box" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["value"]
const _hoisted_7 = ["value"]
const _hoisted_8 = ["value"]
const _hoisted_9 = { key: 0 }

import { onMounted, onUnmounted, ref, watch, computed, reactive } from 'vue';
import { isUserCanViewCmi } from '@/store';
import { downloadCmiPdf } from '@/service/bffService';
// components
import { Card, CardTitle, PageHeader, Loader } from '@/components/kit';
import {
    QuestionViewer,
    QuestionViewerEditor,
    QuestionViewerEditorRadio,
    QuestionViewerEditorDatepicker,
    QuestionViewerEditorSelect
} from '@/components/kit/QuestionViewer';
import {
    TKErrorList,
    TKSuccessTest,
    DownloadFileBtn
} from './components';
// routes
import { useRoute } from 'vue-router';
// store
import  { useCmiItemStore } from './store';
// router
import { routeNames, cmiStatuses } from "@/router";
// utils
import { dateIsoToHumanDateTime, countriesList } from '@/utils';
// types
import { 
    dictionaryYesNo,
    dictionaryInsurance, 
    dictionaryVisaType,
    dictionaryGender,
    dictionaryTarget,
} from './dictionaries';


// store

export default /*@__PURE__*/_defineComponent({
  __name: 'CmiDealViewerEditor',
  setup(__props) {

const store = useCmiItemStore();

const dealId = ref<number>(NaN);
onMounted(()=>{
    const route = useRoute();
    const deal = parseInt((route?.params?.id ?? '') + '', 10);
    if ( !Number.isNaN(deal) ) {
        dealId.value = deal;
        store.getDataById(deal);
    }
});

onUnmounted(() => {
    store.$reset();
});

// просмотр вопросов
const isAllQuestions = ref<boolean>(false);
const isViewQuestion = (storeKey: string): boolean => {
    if (isAllQuestions.value) { return true };

    if (storeKey in store) {
        const value = (store as Record<string, any>)[storeKey];
        return !(!value && value !== false);
    }
    return false;
}

const clickViewedBtnHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    isAllQuestions.value = !isAllQuestions.value;
}

// редактирование вопросов
const isEditable = (): boolean => {
    return !store.antrag_id;
}

// следим за списков вопросов и запоминаем изменяющиеся
const questionToWatcherList: (keyof typeof store)[] = [
    // block 1
    'germanyPolicy', 'residenceCountry', 'insuranceType', 'insuranceCompany', 
    'payer', 'insuranceNumber', 'insuranceFree',

    // block 2
    'visaType', 'firstGermanyWork', 'jobChanged', 'startDate', 
    'firstWorkDate', 'grossSalary', 'privateInsurance', 'yourselfWork', 

    // block 4
    'gender', 'firstName', 'lastName', 'birthLastName', 
    'birthDate', 'birthPlace', 'birthCountry', 'citizenship', 
    'hasPostAddress', 'street', 'houseNumber', 'mailboxLastName', 
    'postCode', 'city', 'email', 'hasGermanyPhone', 'germanyPhone', 
    'phone', 'metaContacts', 'hasChildren', 'contractForFamily', 
    'additionalLastName', 'cyrillicName', 
    
    // block 5
    'target', 'source', 'refid', 'questions',

    // block 6
    'erp_comment'
];

// const changeQuestionList: Set<keyof typeof store> = new Set(); //@dev:
let questionsWatcherCbList: Function[] = [];



const saveDealDataHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    // останавливаем вотчеры за вопросами
    questionsWatcherCbList.forEach(cb=>cb());
    store.updateData([...changeQuestionList.values()]);
}

watch(()=>store.$loading, () => {
    if ( !store.$loading ) {
        // устанавливаем вотчеры за вопросами
        changeQuestionList.clear();
        questionsWatcherCbList = questionToWatcherList.map(key=>watch(()=>store[key], ()=>changeQuestionList.add(key)));
    }
    else {
        // обнуляем вотчеры за вопросами
        questionsWatcherCbList = [];
        changeQuestionList.clear();
    }

    console.log('%cstore::', 'background: red', {...store}) // @dev: dev - helper
});

const changeQuestionList = reactive<Set<keyof typeof store>>(new Set());
const isSaveEnabled = computed<boolean>(()=>changeQuestionList.size === 0);

const sendTestRequestToTKHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isSaveEnabled.value) { return }

    store.sendTestTK();
}

const sendProdRequestToTKHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isSaveEnabled.value) { return }

    store.sendProdTK();
}

const testTkResponseHelper = (data: string | Record<string, any> = ''): string=>{
    if (typeof data === 'string') {
        return data;
    }
    let result = '';
    try {
        result = JSON.stringify(data, null, "\t");
    }
    catch(e) {
        //
    }
    return result;
}

const fullTestResult = ref<boolean>(false);
const toggleFullTestResult = ()=>{ fullTestResult.value = !fullTestResult.value }

// file download
const downloadFile  = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (store.antrag_id) {
        downloadCmiPdf(store.antrag_id)
            .then(r => r.blob())
            .then(blob => {
                var a = Object.assign(document.createElement('a'), {
                    href: URL.createObjectURL(blob),
                    download: `${store.antrag_id}_${store.uid}.pdf`,
                });
            
                document.body.appendChild(a);
                a.click();
    
                setTimeout(()=>{a.remove()}, 1e3);
            })
            .catch(error => {
                console.error(error);
            });
    }

}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(PageHeader), null, {
      default: _withCtx(() => _cache[43] || (_cache[43] = [
        _createTextVNode("Заявка ОМС")
      ])),
      _: 1
    }),
    (!_unref(isUserCanViewCmi)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[44] || (_cache[44] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(isUserCanViewCmi)() && dealId.value)
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 1,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            (_unref(store).$loading)
              ? (_openBlock(), _createBlock(_unref(Loader), { key: 0 }))
              : (_unref(store).$error)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_unref(CardTitle), null, {
                      default: _withCtx(() => [
                        _createTextVNode("Заявка ОМС № " + _toDisplayString(dealId.value), 1)
                      ]),
                      _: 1
                    }),
                    _cache[49] || (_cache[49] = _createElementVNode("h3", null, "Произошла ошибка получения данных.", -1)),
                    _cache[50] || (_cache[50] = _createElementVNode("p", null, [
                      _createElementVNode("strong", null, "Попробуйте перезагрузить страницу"),
                      _createTextVNode(", если это не поможет "),
                      _createElementVNode("strong", null, "обратитесь в поддержку"),
                      _createTextVNode(".")
                    ], -1)),
                    _cache[51] || (_cache[51] = _createElementVNode("p", null, [
                      _createElementVNode("strong", null, "Данные об ошибке.")
                    ], -1)),
                    _createElementVNode("p", null, [
                      _cache[45] || (_cache[45] = _createElementVNode("strong", null, "Обозначение ошибки: ", -1)),
                      _createTextVNode(_toDisplayString(_unref(store).$error.error), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[46] || (_cache[46] = _createElementVNode("strong", null, "ID запроса: ", -1)),
                      _createTextVNode(_toDisplayString(_unref(store).$error.requestId), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[47] || (_cache[47] = _createElementVNode("strong", null, "Код ответа: ", -1)),
                      _createTextVNode(_toDisplayString(_unref(store).$error.code ?? ''), 1)
                    ]),
                    _cache[52] || (_cache[52] = _createElementVNode("p", null, " ", -1)),
                    _createVNode(_component_router_link, {
                      to: { name: _unref(routeNames).cmi, params: { status: _unref(cmiStatuses).all } }
                    }, {
                      default: _withCtx(() => _cache[48] || (_cache[48] = [
                        _createTextVNode("Вернуться к списку заявок ОМС.")
                      ])),
                      _: 1
                    }, 8, ["to"])
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createVNode(_unref(CardTitle), null, {
                      default: _withCtx(() => [
                        _createTextVNode("Заявка ОМС № " + _toDisplayString(dealId.value), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_unref(QuestionViewer), {
                        label: "ID заявки на сайте",
                        value: _unref(store).uid,
                        class: "value-viewer col-sm-12 col-md-4"
                      }, null, 8, ["value"]),
                      _createVNode(_unref(QuestionViewer), {
                        label: "Заявка создана",
                        value: _unref(dateIsoToHumanDateTime)(_unref(store).createdAt),
                        class: "value-viewer col-sm-12 col-md-4"
                      }, null, 8, ["value"]),
                      _createVNode(_unref(QuestionViewer), {
                        label: "Последнее изменение",
                        value: _unref(dateIsoToHumanDateTime)(_unref(store).updatedAt),
                        class: "value-viewer col-sm-12 col-md-4"
                      }, null, 8, ["value"])
                    ]),
                    _cache[104] || (_cache[104] = _createElementVNode("br", null, null, -1)),
                    _cache[105] || (_cache[105] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("button", {
                        onClick: clickViewedBtnHandler,
                        class: "btn btn-inverse-primary btn-fw"
                      }, _toDisplayString(isAllQuestions.value ? 'Скрыть незаполненные вопросы' : 'Показать все вопросы'), 1),
                      (isEditable())
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            onClick: saveDealDataHandler,
                            class: "btn btn-inverse-primary btn-fw",
                            disabled: isSaveEnabled.value
                          }, " Сохранить ", 8, _hoisted_3))
                        : _createCommentVNode("", true),
                      (!_unref(store).antrag_id)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createElementVNode("button", {
                              onClick: sendTestRequestToTKHandler,
                              class: "btn btn-inverse-primary btn-fw",
                              disabled: !!_unref(store).antrag_id || !isSaveEnabled.value
                            }, " Отправить тестовую заявку ", 8, _hoisted_4),
                            _createElementVNode("button", {
                              onClick: sendProdRequestToTKHandler,
                              class: "btn btn-inverse-primary btn-fw",
                              disabled: !!_unref(store).antrag_id || !isSaveEnabled.value
                            }, " Отправить заявку в TK ", 8, _hoisted_5)
                          ], 64))
                        : (_openBlock(), _createElementBlock("a", {
                            key: 2,
                            class: "btn btn-inverse-primary btn-fw",
                            onClick: downloadFile
                          }, " Cкачать PDF "))
                    ]),
                    (_unref(store).tkTestedResponse.deal || _unref(store).tkTestedResponse.tk || _unref(store).tkTestedResponse.response)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_unref(CardTitle), null, {
                            default: _withCtx(() => _cache[53] || (_cache[53] = [
                              _createTextVNode("Результаты теста")
                            ])),
                            _: 1
                          }),
                          _createElementVNode("button", {
                            class: "btn btn-inverse-primary btn-fw",
                            onClick: toggleFullTestResult
                          }, _toDisplayString(fullTestResult.value ? 'Скрыть полные результаты теста' : 'Показать полные результаты теста'), 1),
                          (fullTestResult.value)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _cache[54] || (_cache[54] = _createElementVNode("p", { class: "test-tk-result" }, "Данные заявки", -1)),
                                _createElementVNode("textarea", {
                                  value: testTkResponseHelper(_unref(store).tkTestedResponse.deal),
                                  disabled: "true",
                                  class: "form-control form-control-sm",
                                  style: {"resize":"vertical"}
                                }, null, 8, _hoisted_6),
                                _cache[55] || (_cache[55] = _createElementVNode("p", { class: "test-tk-result" }, "Данные отправленные в TK", -1)),
                                _createElementVNode("textarea", {
                                  value: testTkResponseHelper(_unref(store).tkTestedResponse.tk),
                                  disabled: "true",
                                  class: "form-control form-control-sm",
                                  style: {"resize":"vertical"}
                                }, null, 8, _hoisted_7),
                                _cache[56] || (_cache[56] = _createElementVNode("p", { class: "test-tk-result" }, "Результат запроса в TK", -1)),
                                _createElementVNode("textarea", {
                                  value: testTkResponseHelper(_unref(store).tkTestedResponse.response),
                                  disabled: "true",
                                  class: "form-control form-control-sm",
                                  style: {"resize":"vertical"}
                                }, null, 8, _hoisted_8)
                              ], 64))
                            : _createCommentVNode("", true),
                          (_unref(store).tkTestedResponse.response)
                            ? (_openBlock(), _createBlock(_unref(TKErrorList), {
                                key: 1,
                                stringifedJson: _unref(store).tkTestedResponse.response
                              }, null, 8, ["stringifedJson"]))
                            : _createCommentVNode("", true),
                          (_unref(store).tkTestedResponse.response)
                            ? (_openBlock(), _createBlock(_unref(TKSuccessTest), {
                                key: 2,
                                stringifedJson: _unref(store).tkTestedResponse.response
                              }, null, 8, ["stringifedJson"]))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true),
                    (_unref(store).tkProdResponse.deal || _unref(store).tkProdResponse.tk || _unref(store).tkProdResponse.response)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createVNode(_unref(CardTitle), null, {
                            default: _withCtx(() => _cache[57] || (_cache[57] = [
                              _createTextVNode("Результаты отправки заявки")
                            ])),
                            _: 1
                          }),
                          (_unref(store).tkProdResponse.response)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString((()=>{
                        if (~Object.keys(_unref(store).tkProdResponse.response).indexOf('message')) {
                            return (_unref(store).tkProdResponse.response as any).message
                        }
                        return ''
                    })()), 1))
                            : _createCommentVNode("", true),
                          (_unref(store).tkProdResponse.response)
                            ? (_openBlock(), _createBlock(_unref(TKErrorList), {
                                key: 1,
                                stringifedJson: _unref(store).tkProdResponse.response
                              }, null, 8, ["stringifedJson"]))
                            : _createCommentVNode("", true),
                          (_unref(store).tkProdResponse.response)
                            ? (_openBlock(), _createBlock(_unref(TKSuccessTest), {
                                key: 2,
                                stringifedJson: _unref(store).tkProdResponse.response
                              }, null, 8, ["stringifedJson"]))
                            : _createCommentVNode("", true),
                          (_unref(store).tkProdResponse.response)
                            ? (_openBlock(), _createBlock(_unref(DownloadFileBtn), {
                                key: 3,
                                stringifedJson: _unref(store).tkProdResponse.response,
                                fileName: `${_unref(store).firstName}_${_unref(store).lastName}.pdf`
                              }, null, 8, ["stringifedJson", "fileName"]))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true),
                    _cache[106] || (_cache[106] = _createElementVNode("br", null, null, -1)),
                    _cache[107] || (_cache[107] = _createElementVNode("br", null, null, -1)),
                    _createVNode(_unref(CardTitle), null, {
                      default: _withCtx(() => _cache[58] || (_cache[58] = [
                        _createTextVNode("Информация о страховке")
                      ])),
                      _: 1
                    }),
                    (isViewQuestion('target'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 2,
                          label: "Цель подачи заявки",
                          modelValue: _unref(store).target,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(store).target) = $event)),
                          dictionary: _unref(dictionaryTarget)
                        }, {
                          default: _withCtx(() => _cache[59] || (_cache[59] = [
                            _createElementVNode("p", null, null, -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('germanyPolicy'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 3,
                          label: "Есть ли у вас сейчас действующий полис медицинского страхования в Германии?",
                          modelValue: _unref(store).germanyPolicy,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(store).germanyPolicy) = $event)),
                          dictionary: _unref(dictionaryYesNo),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[60] || (_cache[60] = [
                            _createElementVNode("p", null, "Нужно ввести 1 из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('residenceCountry'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorSelect), {
                          key: 4,
                          label: "В какой стране перед текущим страхованием вы длительно проживали и имеете полис медицинского страхования?",
                          modelValue: _unref(store).residenceCountry,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(store).residenceCountry) = $event)),
                          options: _unref(countriesList),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[61] || (_cache[61] = [
                            _createElementVNode("p", null, "Страна должны быть выбрана из списка (ISO 3166-1)", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "options", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('insuranceType'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 5,
                          label: "Вид действующего страхования",
                          modelValue: _unref(store).insuranceType,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(store).insuranceType) = $event)),
                          dictionary: _unref(dictionaryInsurance),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[62] || (_cache[62] = [
                            _createElementVNode("p", null, "Один из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('insuranceCompany'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 6,
                          label: "Введите название страховой компании, в которой вы сейчас застрахованы",
                          modelValue: _unref(store).insuranceCompany,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(store).insuranceCompany) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[63] || (_cache[63] = [
                            _createElementVNode("p", null, "Только латинские буквы, макс. 25 символов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('payer'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 7,
                          label: "Вы платите взносы сами или состоите в семейном страховании?",
                          modelValue: _unref(store).payer,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(store).payer) = $event)),
                          dictionary: _unref(dictionaryYesNo),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[64] || (_cache[64] = [
                            _createElementVNode("p", null, "Нужно ввести 1 из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('insuranceNumber'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 8,
                          label: "Введите номер медицинского страхования / Versichertennummer",
                          modelValue: _unref(store).insuranceNumber,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(store).insuranceNumber) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[65] || (_cache[65] = [
                            _createElementVNode("p", null, "Номер состоит из 10 символов: первая латинская буква и 9 цифр.", -1),
                            _createElementVNode("p", null, "Максимальная длина 10 символов.", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('insuranceFree'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 9,
                          label: "Освобождены ли вы от уплаты страховых взносов?",
                          modelValue: _unref(store).insuranceFree,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(store).insuranceFree) = $event)),
                          dictionary: _unref(dictionaryYesNo),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[66] || (_cache[66] = [
                            _createElementVNode("p", null, "Нужно ввести 1 из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    _cache[108] || (_cache[108] = _createElementVNode("br", null, null, -1)),
                    _cache[109] || (_cache[109] = _createElementVNode("br", null, null, -1)),
                    _createVNode(_unref(CardTitle), null, {
                      default: _withCtx(() => _cache[67] || (_cache[67] = [
                        _createTextVNode("Данные о работе")
                      ])),
                      _: 1
                    }),
                    (isViewQuestion('visaType'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 10,
                          label: "На каком вы этапе?",
                          modelValue: _unref(store).visaType,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(store).visaType) = $event)),
                          dictionary: _unref(dictionaryVisaType),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[68] || (_cache[68] = [
                            _createElementVNode("p", null, "Нужно ввести 1 из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('firstGermanyWork'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 11,
                          label: "Это ваша первая работа в Германии?",
                          modelValue: _unref(store).firstGermanyWork,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(store).firstGermanyWork) = $event)),
                          dictionary: _unref(dictionaryYesNo),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[69] || (_cache[69] = [
                            _createElementVNode("p", null, "Нужно ввести 1 из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('jobChanged'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 12,
                          label: "Вы хотите сменить страховку в связи со сменой работы?",
                          modelValue: _unref(store).jobChanged,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(store).jobChanged) = $event)),
                          dictionary: _unref(dictionaryYesNo),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[70] || (_cache[70] = [
                            _createElementVNode("p", null, "Нужно ввести 1 из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('startDate'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorDatepicker), {
                          key: 13,
                          label: "Дата начала страхования в ОМС",
                          modelValue: _unref(store).startDate,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(store).startDate) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[71] || (_cache[71] = [
                            _createElementVNode("p", null, null, -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('firstWorkDate'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorDatepicker), {
                          key: 14,
                          label: "Дата первого рабочего дня в Германии за все время",
                          modelValue: _unref(store).firstWorkDate,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(store).firstWorkDate) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[72] || (_cache[72] = [
                            _createElementVNode("p", null, null, -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('grossSalary'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 15,
                          label: "Ваша полная годовая зарплата брутто с бонусами выше 66600€ (5550€ в месяц)?",
                          modelValue: _unref(store).grossSalary,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(store).grossSalary) = $event)),
                          dictionary: _unref(dictionaryYesNo),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[73] || (_cache[73] = [
                            _createElementVNode("p", null, "Нужно ввести 1 из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('privateInsurance'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 16,
                          label: "Рассматриваете ли вы вариант частного страхования?",
                          modelValue: _unref(store).privateInsurance,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(store).privateInsurance) = $event)),
                          dictionary: _unref(dictionaryYesNo),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[74] || (_cache[74] = [
                            _createElementVNode("p", null, "Нужно ввести 1 из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('yourselfWork'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 17,
                          label: "Работаете ли вы на себя в Германии?",
                          modelValue: _unref(store).yourselfWork,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(store).yourselfWork) = $event)),
                          dictionary: _unref(dictionaryYesNo),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[75] || (_cache[75] = [
                            _createElementVNode("p", null, "Нужно ввести 1 из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    _cache[110] || (_cache[110] = _createElementVNode("br", null, null, -1)),
                    _cache[111] || (_cache[111] = _createElementVNode("br", null, null, -1)),
                    _createVNode(_unref(CardTitle), null, {
                      default: _withCtx(() => _cache[76] || (_cache[76] = [
                        _createTextVNode("Персональные данные")
                      ])),
                      _: 1
                    }),
                    (isViewQuestion('gender'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 18,
                          label: "Укажите ваш пол",
                          modelValue: _unref(store).gender,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(store).gender) = $event)),
                          dictionary: _unref(dictionaryGender),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[77] || (_cache[77] = [
                            _createElementVNode("p", null, "Нужно ввести 1 из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('firstName'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 19,
                          label: "Введите ваше имя (как в загранпаспорте)",
                          modelValue: _unref(store).firstName,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(store).firstName) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[78] || (_cache[78] = [
                            _createElementVNode("p", null, "Латинскими буквами, как в загранпаспорте", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('lastName'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 20,
                          label: "Введите вашу фамилию (как в загранпаспорте)",
                          modelValue: _unref(store).lastName,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_unref(store).lastName) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[79] || (_cache[79] = [
                            _createElementVNode("p", null, "Латинскими буквами, как в загранпаспорте", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('birthLastName'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 21,
                          label: "Фамилия при рождении",
                          modelValue: _unref(store).birthLastName,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_unref(store).birthLastName) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[80] || (_cache[80] = [
                            _createElementVNode("p", null, "Заполните латинскими буквами", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('birthDate'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorDatepicker), {
                          key: 22,
                          label: "Ваша дата рождения",
                          modelValue: _unref(store).birthDate,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_unref(store).birthDate) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[81] || (_cache[81] = [
                            _createElementVNode("p", null, null, -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('birthPlace'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 23,
                          label: "Место рождения (населенный пункт)",
                          modelValue: _unref(store).birthPlace,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_unref(store).birthPlace) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[82] || (_cache[82] = [
                            _createElementVNode("p", null, "Заполните латинскими буквами", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('birthCountry'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorSelect), {
                          key: 24,
                          label: "Страна рождения",
                          modelValue: _unref(store).birthCountry,
                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_unref(store).birthCountry) = $event)),
                          options: _unref(countriesList),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[83] || (_cache[83] = [
                            _createElementVNode("p", null, "Страна должны быть выбрана из списка (ISO 3166-1)", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "options", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('citizenship'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorSelect), {
                          key: 25,
                          label: "Гражданство",
                          modelValue: _unref(store).citizenship,
                          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_unref(store).citizenship) = $event)),
                          options: _unref(countriesList),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[84] || (_cache[84] = [
                            _createElementVNode("p", null, "Страна должны быть выбрана из списка (ISO 3166-1)", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "options", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('hasPostAddress'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 26,
                          label: "Есть ли у вас почтовый адрес в Германии?",
                          modelValue: _unref(store).hasPostAddress,
                          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_unref(store).hasPostAddress) = $event)),
                          dictionary: _unref(dictionaryYesNo),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[85] || (_cache[85] = [
                            _createElementVNode("p", null, "Нужно ввести 1 из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('street'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 27,
                          label: "Улица",
                          modelValue: _unref(store).street,
                          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_unref(store).street) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[86] || (_cache[86] = [
                            _createElementVNode("p", null, "Заполните латинскими буквами", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('houseNumber'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 28,
                          label: "Номер дома",
                          modelValue: _unref(store).houseNumber,
                          "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_unref(store).houseNumber) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[87] || (_cache[87] = [
                            _createElementVNode("p", null, "Латинские буквы и/или цифры", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('mailboxLastName'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 29,
                          label: "Фамилия, как на почтовом ящике",
                          modelValue: _unref(store).mailboxLastName,
                          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_unref(store).mailboxLastName) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[88] || (_cache[88] = [
                            _createElementVNode("p", null, "Латинские буквы", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('postCode'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 30,
                          label: "Индекс",
                          modelValue: _unref(store).postCode,
                          "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_unref(store).postCode) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[89] || (_cache[89] = [
                            _createElementVNode("p", null, "Латинские буквы и/или цифры", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('city'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 31,
                          label: "Город",
                          modelValue: _unref(store).city,
                          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_unref(store).city) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[90] || (_cache[90] = [
                            _createElementVNode("p", null, "Заполните латинскими буквами", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('email'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 32,
                          label: "Укажите ваш e-mail",
                          modelValue: _unref(store).email,
                          "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_unref(store).email) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[91] || (_cache[91] = [
                            _createElementVNode("p", null, "Заполните латинскими буквами", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('germanyPhone'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 33,
                          label: "Укажите ваш номер телефона в Германии",
                          modelValue: _unref(store).germanyPhone,
                          "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_unref(store).germanyPhone) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[92] || (_cache[92] = [
                            _createElementVNode("p", null, null, -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('phone'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 34,
                          label: "Укажите любой действующий номер телефона для связи с вами",
                          modelValue: _unref(store).phone,
                          "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_unref(store).phone) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[93] || (_cache[93] = [
                            _createElementVNode("p", null, null, -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('metaContacts'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 35,
                          label: "Укажите дополнительный способ связи",
                          modelValue: _unref(store).metaContacts,
                          "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_unref(store).metaContacts) = $event)),
                          "is-editable": isEditable(),
                          type: "textarea"
                        }, {
                          default: _withCtx(() => _cache[94] || (_cache[94] = [
                            _createElementVNode("p", null, null, -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('hasChildren'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 36,
                          label: "Есть ли у вас дети?",
                          modelValue: _unref(store).hasChildren,
                          "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_unref(store).hasChildren) = $event)),
                          dictionary: _unref(dictionaryYesNo),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[95] || (_cache[95] = [
                            _createElementVNode("p", null, "Нужно ввести 1 из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('contractForFamily'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 37,
                          label: "Вы хотите включить членов семьи в свой контракт?",
                          modelValue: _unref(store).contractForFamily,
                          "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_unref(store).contractForFamily) = $event)),
                          dictionary: _unref(dictionaryYesNo),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[96] || (_cache[96] = [
                            _createElementVNode("p", null, "Нужно ввести 1 из вариантов", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('additionalLastName'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 38,
                          label: "Введите фамилию и имя супруга/-и",
                          modelValue: _unref(store).additionalLastName,
                          "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_unref(store).additionalLastName) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[97] || (_cache[97] = [
                            _createElementVNode("p", null, "Заполните латинскими буквами", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('cyrillicName'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 39,
                          label: "Как ваше имя пишется на русском языке?",
                          modelValue: _unref(store).cyrillicName,
                          "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_unref(store).cyrillicName) = $event)),
                          "is-editable": isEditable()
                        }, {
                          default: _withCtx(() => _cache[98] || (_cache[98] = [
                            _createElementVNode("p", null, "Заполните на русском языке", -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    _cache[112] || (_cache[112] = _createElementVNode("br", null, null, -1)),
                    _cache[113] || (_cache[113] = _createElementVNode("br", null, null, -1)),
                    _createVNode(_unref(CardTitle), null, {
                      default: _withCtx(() => _cache[99] || (_cache[99] = [
                        _createTextVNode("Согласие и обработка персональных данных")
                      ])),
                      _: 1
                    }),
                    (isViewQuestion('target'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditorRadio), {
                          key: 40,
                          label: "Цель подачи заявки",
                          modelValue: _unref(store).target,
                          "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_unref(store).target) = $event)),
                          dictionary: _unref(dictionaryTarget)
                        }, {
                          default: _withCtx(() => _cache[100] || (_cache[100] = [
                            _createElementVNode("p", null, null, -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "dictionary"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('questions'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 41,
                          label: "Если у вас есть вопросы",
                          modelValue: _unref(store).questions,
                          "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_unref(store).questions) = $event)),
                          "is-editable": isEditable(),
                          type: "textarea"
                        }, {
                          default: _withCtx(() => _cache[101] || (_cache[101] = [
                            _createElementVNode("p", null, null, -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('source'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 42,
                          label: "Как вы о нас узнали?",
                          modelValue: _unref(store).source,
                          "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_unref(store).source) = $event)),
                          "is-editable": isEditable(),
                          type: "textarea"
                        }, {
                          default: _withCtx(() => _cache[102] || (_cache[102] = [
                            _createElementVNode("p", null, null, -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue", "is-editable"]))
                      : _createCommentVNode("", true),
                    (isViewQuestion('refid'))
                      ? (_openBlock(), _createBlock(_unref(QuestionViewerEditor), {
                          key: 43,
                          label: "REF ID",
                          modelValue: _unref(store).refid,
                          "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_unref(store).refid) = $event))
                        }, {
                          default: _withCtx(() => _cache[103] || (_cache[103] = [
                            _createElementVNode("p", null, null, -1)
                          ])),
                          _: 1
                        }, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    _createVNode(_unref(QuestionViewerEditor), {
                      label: "Комментарий сотрудника ТГ",
                      modelValue: _unref(store).erp_comment,
                      "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_unref(store).erp_comment) = $event)),
                      "is-editable": isEditable(),
                      type: "textarea"
                    }, null, 8, ["modelValue", "is-editable"])
                  ], 64))
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_unref(Card), { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[114] || (_cache[114] = [
                _createTextVNode("Неверный ID заявки в URL страницы.")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: { name: _unref(routeNames).cmi, params: { status: _unref(cmiStatuses).all } }
            }, {
              default: _withCtx(() => _cache[115] || (_cache[115] = [
                _createTextVNode("Вы можете вернуться к списку заявок ОМС.")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _: 1
        }))
  ], 64))
}
}

})