<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
import { ITableCellProps } from '@/components/Table/types';


defineComponent({name: 'TCell'});

const props = defineProps<ITableCellProps>();

</script>

<template>
  <td :class="props.class || ''">
    <span>value: {{ props.value || '' }}, <strong>index: {{ props.rowIndex }}</strong>, 
      <strong>keys: {{ Object.keys(props.rowData) }}</strong>, <strong>dataKey: {{ props.dataKey }}</strong></span>
  </td>
</template>