import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-description-td" }
const _hoisted_2 = ["title"]

import { defineComponent } from 'vue';
import { ITableCellProps } from '@/components/Table/types';



export default /*@__PURE__*/_defineComponent({
  __name: 'UserDescriptionTd',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;
defineComponent({name: 'UserDescriptionTd'});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", _hoisted_1, [
    _createElementVNode("span", {
      title: props.value ?? ''
    }, _toDisplayString(props.value ?? ''), 9, _hoisted_2)
  ]))
}
}

})