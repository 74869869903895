import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["colspan"]
const _hoisted_3 = {
  key: 0,
  class: "rule-add-form"
}
const _hoisted_4 = { key: 1 }

import { defineComponent, ref } from 'vue';
import Input from '@/components/kit/Input.vue';
// store
import { usePermissionsApi, useSyncRulesStore } from './permissionsStore';
import { isUserCanEditPermission } from '@/store';



export default /*@__PURE__*/_defineComponent({
  __name: 'PermissionAdd',
  setup(__props) {

defineComponent({ name: 'PermissionAddForm' });

const tableStore = useSyncRulesStore();
const apiStore = usePermissionsApi();

const isFormOpen = ref<boolean>(false);
const ruleTitle = ref<string>('');

const submitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const title = ruleTitle.value.trim();
    if (!title) { return }
    apiStore.addRule(title);
    isFormOpen.value = false;
    ruleTitle.value = '';
}

return (_ctx: any,_cache: any) => {
  return (_unref(isUserCanEditPermission)())
    ? (_openBlock(), _createElementBlock("tbody", _hoisted_1, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", {
            colspan: _unref(tableStore).columns.length,
            class: "permission-add-form-box"
          }, [
            (isFormOpen.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("form", { onSubmit: submitHandler }, [
                    _createVNode(Input, {
                      modelValue: ruleTitle.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ruleTitle).value = $event)),
                      placeholder: "Наименование действия"
                    }, null, 8, ["modelValue"]),
                    _cache[2] || (_cache[2] = _createElementVNode("button", {
                      type: "submit",
                      class: "btn btn-success btn-rounded btn-icon"
                    }, [
                      _createElementVNode("i", { class: "mdi mdi-check" })
                    ], -1))
                  ], 32)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-danger btn-icon-text",
                    onClick: _cache[1] || (_cache[1] = () => { isFormOpen.value = true })
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "mdi mdi-plus btn-icon-prepend" }, null, -1),
                    _createTextVNode(" Добавить правило ")
                  ]))
                ]))
          ], 8, _hoisted_2)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})