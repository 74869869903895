<script setup lang="ts">
import { defineComponent, defineProps, defineEmits, ref } from 'vue';
import { IRadio } from './types';
import { getValueFromEvent } from './helpers';

// props
const props = defineProps<IRadio>();
const inputValue = ref(props.modelValue || '');

defineComponent({ name: 'RadioBox' });

// emits
const emits = defineEmits(['update:modelValue']);

// methods
const inputHandler = (e: Event) => {
    const value = getValueFromEvent(e);
    emits('update:modelValue', value);
}
</script>

<template>
  <div class="form-check">
    <label class="form-check-label">
      <input
        v-model="inputValue"
        class="form-check-input"
        type="radio" 
        :required="Boolean(required)"
        :disabled="Boolean(disabled)"
                
        :name="name"
        :value="value" 
        @input="inputHandler"
      >
      <slot />
      <i class="input-helper" />
    </label>
  </div>
</template>