<script setup lang="ts">
// components
import Loader from '@/components/Loader.vue';
import Header from '@/components/Header.vue';
import Sidebar from '@/components/Sidebar.vue';
import { LoginPage } from '@/modules/login';
// store
import {
    useUserStore,
    useNavyStore
} from '../store';


// store
const userStore = useUserStore();
const navyStore = useNavyStore();
</script>

<template>
  <section
    v-if="userStore.status"
    class="container-scroller"
    :class="navyStore.leftNavyOpen && 'sidebar-icon-only'"
  >
    <Header />
    <div class="container-fluid page-body-wrapper">
      <Sidebar />
      <div class="main-panel">
        <div class="content-wrapper kit-content-wrapper">
          <Loader v-if="userStore.loading" />
          <router-view v-if="!userStore.loading" />
        </div>
      </div>
    </div>
  </section>

  <LoginPage v-if="!userStore.status && !userStore.loading" />
</template>

<style lang="scss">
.content-wrapper.kit-content-wrapper {
  position: relative;
  @media screen and (max-width: 540px) {
    & {
      padding: 2rem 0.5rem;
    }
  }
}
</style>