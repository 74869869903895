<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
import { ITablePaginationButton } from '@/components/Table/types';

defineComponent({name: 'KitTablePaginationBtn'});

const props = defineProps<ITablePaginationButton>();

</script>

<template>
  <li
    class="paginate_button page-item"
    :class="props.isActive ? 'active' : ''"
  >
    <a
      aria-controls="order-listing"
      class="page-link"
    >{{ props.index }}</a>
  </li>
</template>

<style lang="scss">
li.paginate_button.page-item {
    & a {
        user-select: none;
        cursor: pointer;
    }
    &.active a {
        cursor: initial;
    }
}
</style>