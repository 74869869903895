<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import { isUserCanEditPrices } from '@/store';
import PageHeader from '@/components/kit/PageHeader.vue';
import Card from '@/components/kit/Card.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import { routeNames, cmiStatuses } from "@/router";
import { usePageEditItemStore } from './store/dealItemStore';
import { useRoute } from 'vue-router';
import { TJsonUseStore } from 'litsystems-vue-json-editor-dev/src';
import JsonViewer from 'litsystems-vue-json-editor-dev/src/JsonViewer.vue';
import PriceEditInput from './PriceEditInput.vue';

const isDevMode = new URL(window.location.toString()).searchParams.has('_dev'); // Отладочная информация


// store
const store = usePageEditItemStore();
const dealId = ref<number>(NaN);

var usePriceStore: TJsonUseStore['useStore'];

const getUseStoreCb = (useStore: TJsonUseStore['useStore']) => {
    usePriceStore = useStore;

    const jsonStore = useStore();

    // устанавливаем расширения
    jsonStore.addExtensionComponent('price-editor', PriceEditInput);

    // устанавливаем расширения
    if (store.editorOptions.extensions) {
        jsonStore.updateExtensions(store.editorOptions.extensions)
    }

    // устанавливаем классы
    if (store.editorOptions.classes) {
        jsonStore.updateClassNames(store.editorOptions.classes);
    }

    // устанавливаем скрытие элементов
    if (store.editorOptions.hiddens) {
        jsonStore.updateHiddens(store.editorOptions.hiddens);
    }

    // изначальная установка даннных
    jsonStore.setReference(store.pageContent);
}


onMounted(() => {
    const route = useRoute();
    const deal = parseInt((route?.params?.id ?? '') + '', 10);

    // получаем данные по id страницы
    if (Number.isInteger(deal)) {
        dealId.value = deal;
        store.getDataById(deal); // Дождитесь получения данных
    }
});

onUnmounted(() => {
    store.$reset();
    usePriceStore?.().$reset();
});

const savePriceHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();


    try {
        const priceStore = usePriceStore();
        const referenceData = JSON.stringify(priceStore.reference, null, 2);
        store.updateData(JSON.parse(referenceData));

    } catch (error) {
        console.error('Ошибка при сохранении изменений:', error);
    }
};


</script>

<template>
    <PageHeader>Редактирование цены</PageHeader>

    <Card v-if="!isUserCanEditPrices()" style="position:relative">
        <CardTitle>Нет прав на просмотр данных</CardTitle>
    </Card>

    <Card v-if="isUserCanEditPrices() && dealId" style="position:relative">
        <Loader v-if="store.$loading" />
        <template v-else-if="store.$error">
            <h3>Произошла ошибка получения данных.</h3>
            <p><strong>Попробуйте перезагрузить страницу</strong>, если это не поможет <strong>обратитесь в
                    поддержку</strong>.
            </p>
            <p><strong>Данные об ошибке.</strong></p>
            <p><strong>Обозначение ошибки: </strong>{{ store.$error.error }}</p>
            <p><strong>ID запроса: </strong>{{ store.$error.requestId }}</p>
            <p><strong>Код ответа: </strong>{{ store.$error.code ?? '' }}</p>
            <p>&nbsp;</p>
            <router-link :to="{ name: routeNames.prices, params: { status: cmiStatuses.all } }">Вернуться к списку
                страниц.</router-link>
        </template>
        <template v-else>
            <CardTitle>Информация о странице</CardTitle>
            <div class="row">
                <p>Наименование страницы</p>
                <p style="color:#000">{{ store.title }}</p>
                <p>URL страницы</p>
                <p><a style="color:var(--bs-body-color);text-decoration: none;" target="_blank"
                        :href="'https://www.tupa-germania.ru/' + store.url">{{ store.url }}</a></p>
            </div>

            <template v-if="isDevMode">
                <p>только для отладки:</p>
                <textarea :value="JSON.stringify(store.storage_config, null, `\t`)" class="form-control form-control-sm"
                    style="resize:vertical" />
                <textarea :value="JSON.stringify(store.pageContent, null, `\t`)" class="form-control form-control-sm"
                    style="resize:vertical" />
                <textarea :value="JSON.stringify(store.editor_options, null, `\t`)" class="form-control form-control-sm"
                    style="resize:vertical" />
                <textarea :value="JSON.stringify(store, null, `\t`)" class="form-control form-control-sm"
                    style="resize:vertical" />
                <p>{{ store.newPriceValue }}</p>
                <p></p>
            </template>

            <!-- Рендеринг редактора -->
            <CardTitle>Цена</CardTitle>
            <JsonViewer :getUseStoreCb="getUseStoreCb" />


            <button v-if="store.isUpdated" class="btn btn-inverse-primary btn-fw" @click="savePriceHandler">Сохранить
                изменения</button>

        </template>
    </Card>
</template>
  

<style lang="scss">
.title-price {
    margin-bottom: 1rem;
}

.--visible-row {
    margin-bottom: 1rem;
    display: initial !important;
}

.--hidden-row {
    display: none;
}</style>