<script setup lang="ts">
import { defineComponent, reactive, ref } from 'vue';
// boxes
import PageHeader from "@/components/kit/PageHeader.vue";
import Row from "@/components/kit/Row.vue";
import Card from "@/components/kit/Card.vue";
import CardTitle from "@/components/kit/CardTitle.vue";
import FormGroup from "@/components/kit/FormGroup.vue";
// inputs
import Label from "@/components/kit/Label.vue";
import LabelDescription from "@/components/kit/LabelDescription.vue";
import Input from "@/components/kit/Input.vue";
import Checkbox from "@/components/kit/Checkbox.vue";
import Radio from "@/components/kit/Radio.vue";
import Datepicker from "@/components/kit/Datepicker.vue";
import Select from "@/components/kit/Select.vue";
import { TSelectOption } from '@/components/kit/types';
// tables
import TestDataTable from '@/modules/dataTable/SyncDataTable.vue';
import AsyncDataTable from '@/modules/dataTable/AsyncDataTable.vue';
// smart inputs
import {
    QuestionViewer,
    QuestionViewerEditor,
    QuestionViewerEditorRadio,
    QuestionViewerEditorDatepicker,
    QuestionViewerEditorSelect
} from '@/components/kit/QuestionViewer';


const validator = (data: string) => data.length < 5;

defineComponent({
    name: "DevelopKit",
});

const radio = reactive({
    model: 'М',
    list: ['М', 'Ж']
});

const checkbox = reactive([false, true, true]);

const inputText = ref('test');
const inputTextWithValidation = ref('<5');

const selectOptions: TSelectOption[] = [
    {
        label: '1',
        value: '111'
    },
    {
        label: '2',
        value: '222'
    },
    {
        label: '3',
        value: '333'
    }
];


// dictionaries
const genderDict = {
    "Мужской": true,
    "Женский": false,
}
const genderTest = ref<null | undefined | boolean>(null);
const genderTestVoid0 = ref<null | undefined | boolean>();

// test data
const testDate = ref<Date>(new Date);

// test select
const selectTest = ref<string>('GB'); 
const selectTestMult = ref<string[]>(['GB', 'EN', 'RU']); 

const store = reactive({
    cyrillicName: 'АБВ',
    birthCountry: 'RU'
})
</script>


<template>
  <PageHeader>
    KIT
  </PageHeader>

  <!-- Бокс шириной 12-->
  <Row>
    <div class="col-lg-12">
      <Card>
        <CardTitle>
          Бокс
        </CardTitle>
        <Row>
          <p>
            Бокс шириной 12
          </p>
        </Row>
      </Card>
    </div>
  </Row>

  <!-- Бокс шириной 6 -->
  <Row class="tab-content tab-transparent-content">
    <div class="col-lg-6">
      <Card>
        <CardTitle>
          Бокс 6
        </CardTitle>
        <Row>
          <p>
            Бокс шириной 6
          </p>
        </Row>
      </Card>
    </div>

    <div class="col-lg-6">
      <Card>
        <CardTitle>
          Бокс 6
        </CardTitle>
        <Row>
          <p>
            Бокс шириной 6
          </p>
        </Row>
      </Card>
    </div>
  </Row>

  <Row class="tab-content tab-transparent-content">
    <div class="col-lg-12">
      <Card>
        <CardTitle>
          Inputs
        </CardTitle>
        <Row>
          <Input
            placeholder="placeholder & disabled"
            :disabled="true"
          />
        </Row>

        <Row>
          <p class="my-sm-3">
            email
          </p>
          <Input
            type="email"
            placeholder="email"
          />

          <p class="my-sm-3">
            password
          </p>

          <Input
            type="password"
            placeholder="password"
          />

          <p class="my-sm-3">
            textarea
          </p>

          <Input
            type="textarea"
            placeholder="textarea text"
          />

          <p class="my-sm-3">
            Управляемый компонент
          </p>
          <p>Значение: {{ inputText }}</p>
          <Input
            v-model="inputText"
            placeholder="управляемый input"
          />

          <p class="my-sm-3">
            Управляемый компонент c валидацией (меньше 5 символов)
          </p>
          <p>Значение: {{ inputTextWithValidation }}</p>
          <Input
            v-model="inputTextWithValidation"
            placeholder="управляемый input"
            :validator="validator"
          />

          <p class="my-sm-3">
            Input с label
          </p>
          <FormGroup>
            <!-- Здесь мы используем <template #default="{id}">, чтобы получить id для связи label и input-->
            <!-- Можно передать id без <template #default="{id}">, напрямую-->
            <template #default="{id}">
              <Label :for="id">Текст лейбла</Label>
              <Input
                :id="id"
                placeholder="input vs label"
              />
            </template>
          </FormGroup>

          <p class="my-sm-3">
            Input с ошибкой
          </p>
          <FormGroup class="has-danger">
            <template #default="{id}">
              <Label :for="id">
                Текст лейбла
                <LabelDescription>Описание для label</LabelDescription>
              </Label>
              <Input
                :id="id"
                placeholder="с ошибкой"
              />
              <!-- Здесь место под текст ошибки -->
              <Label
                :for="id"
                class="error mt-2 text-danger"
              >
                текст ошибки
                <LabelDescription>
                  описание текста ошибки
                </LabelDescription>
              </Label>
            </template>
          </FormGroup>

          <p class="my-sm-3">
            Checkboxes
          </p>
          <p>status: {{ checkbox[0] }}, {{ checkbox[1] }}, {{ checkbox[2] }}</p>
          <FormGroup>
            <Checkbox v-model="checkbox[0]">
              blank
            </Checkbox>
            <Checkbox v-model="checkbox[1]">
              checked
            </Checkbox>
            <Checkbox
              v-model="checkbox[2]"
              :disabled="true"
            >
              disabled
            </Checkbox>
          </FormGroup>

          <p class="my-sm-3">
            Radio
          </p>
          <p>значение: {{ radio.model }}</p>
          <FormGroup>
            <template #default="{id}">
              <Radio
                v-model="radio.model"
                :name="id"
                :value="radio.list[0]"
              >
                Муж
              </Radio>
              <Radio
                v-model="radio.model"
                :name="id"
                :value="radio.list[1]"
              >
                Жен
              </Radio>
              <Radio
                v-model="radio.model"
                :name="id"
                :value="'a'"
              >
                A
              </Radio>
            </template>
          </FormGroup>

          <FormGroup>
            <Label>Date picker</Label>
            <Datepicker />
          </FormGroup>

          <FormGroup>
            <Label for="kit-select">Select</Label>
            <Select
              id="kit-select"
              :options="selectOptions"
            />
          </FormGroup>

          <FormGroup>
            <Label for="kit-select">Select multiple</Label>
            <Select
              id="kit-select"
              :options="selectOptions"
              :multiple="true"
            />
          </FormGroup>
        </Row>
      </Card>
    </div>
  </Row>

  <Row class="tab-content tab-transparent-content">
    <div class="col-lg-12">
      <Card>
            <QuestionViewer label="Имя кириллицей" :value="store.cyrillicName"/>
            <br>
            <br>
            <CardTitle>ТЕКСТОВЫЕ</CardTitle>
            <p>example: {{ JSON.stringify(store.cyrillicName) }}</p>
            <QuestionViewerEditor label="Имя кириллицей" :is-editable="true" v-model="store.cyrillicName" />

            <p>validation only rus</p>
            <QuestionViewerEditor 
                label="Имя кириллицей (без валидации)"
                :is-editable="true"
                v-model="store.cyrillicName"
            />

            <QuestionViewerEditor 
                label="Имя кириллицей"
                :is-editable="true"
                type="textarea"
                v-model="store.cyrillicName"
                :validator="(s: string)=>/^[а-яё\- ]{1,100}$/ig.test(s) || s === ''"
            >
                <p>Имя кириллицей должно содержать только буквы а-я, пробелы, знак -</p>
            </QuestionViewerEditor>

            <br>
            <br>
            <CardTitle>РАДИО</CardTitle>
            <p>
                значение store.gender:
                {{ JSON.stringify(store.gender) }}
            </p>
            <QuestionViewerEditorRadio label="Пол нередактируемый" v-model="store.gender" :dictionary="genderDict" />
            <QuestionViewerEditorRadio label="Пол" :is-editable="true" v-model="store.gender" :dictionary="genderDict" />
            <QuestionViewerEditorRadio label="Пол" :is-editable="true" v-model="store.gender" :dictionary="genderDict" />
            <QuestionViewerEditorRadio label="Пол NULL" :is-editable="true" v-model="genderTest" :dictionary="genderDict">
                <p>нужно выбрать 1 из вариантов</p>
            </QuestionViewerEditorRadio>
            <QuestionViewerEditorRadio label="Пол обязательный" 
                :is-editable="true" 
                v-model="genderTestVoid0" 
                :required="true" 
                :dictionary="genderDict">
                <p>поле обязательно для заполнения</p>
            </QuestionViewerEditorRadio>

            <br>
            <br>
            <CardTitle>DATEPICKER</CardTitle>

            <QuestionViewerEditorDatepicker label="Дата рождения обычная" :is-editable="true" v-model="testDate" />
            <QuestionViewerEditorDatepicker label="Дата рождения обязательная" :is-editable="true" v-model="testDate" :required="true"/>
            <QuestionViewerEditorDatepicker label="Дата рождения min 03.12.2023" :is-editable="true" v-model="testDate" :min-date="new Date('03.12.2023')"/>
            <QuestionViewerEditorDatepicker label="Дата рождения max 03.12.2022" :is-editable="true" v-model="testDate" :max-date="new Date('03.12.2022')">
                <p>Дата должна быть не более 03.12.2022</p>
            </QuestionViewerEditorDatepicker>
            <QuestionViewerEditorDatepicker label="Дата рождения без редактирования" v-model="testDate" />

            <br>
            <br>

            <CardTitle>SELECT</CardTitle>
            <p>{{ JSON.stringify(store.birthCountry) }}</p>
            <br>
            <QuestionViewerEditor label="Имя кириллицей" :is-editable="true" v-model="store.birthCountry" />
            <QuestionViewerEditorSelect label="select" v-model="store.birthCountry" :is-editable="true" :options="countriesList"/>
            <QuestionViewerEditorSelect label="select no editable" v-model="store.birthCountry" :options="countriesList" />
            <QuestionViewerEditorSelect label="select required" v-model="selectTest" :is-editable="true" :required="true" :options="countriesList"/>
            <QuestionViewerEditorSelect label="select multiple" v-model="selectTestMult" :is-editable="true" :multiple="true" :options="countriesList">
                <p>Список должен содержать только валидные значения</p>
            </QuestionViewerEditorSelect>
      </Card>
    </div>
  </Row>

  <Row class="tab-content tab-transparent-content">
    <div class="col-lg-12">
      <Card>
        <TestDataTable />
      </Card>
    </div>
  </Row>

  <Row class="tab-content tab-transparent-content">
    <div class="col-lg-12">
      <Card>
        <AsyncDataTable />
      </Card>
    </div>
  </Row>
</template>