import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "rule-editor"
}
const _hoisted_2 = { key: 1 }

import { defineComponent, ref } from 'vue';
import { ITableCellProps } from '@/components/Table/types';
import Input from '@/components/kit/Input.vue';
import { isUserCanEditPermission } from '@/store';
// store
import { usePermissionsApi } from './permissionsStore';



export default /*@__PURE__*/_defineComponent({
  __name: 'PermissionTitleTd',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

defineComponent({name: 'PermissionTitleCell'});

const props = __props;

const isRuleEditorOpened = ref<boolean>(false);
const ruleTitle = ref<string>(props.value || '');

const isRuleEditor = isUserCanEditPermission();
const editClickHandler = () => {
    const ruleId = (props.rowData?.id && Number.isInteger(props.rowData.id)) ? props.rowData.id : false;
    if (!ruleId) { return }

    isRuleEditorOpened.value = true;
}

const save = (id: number) => {
    const permissionsApi = usePermissionsApi();
    permissionsApi.saveRule(id, ruleTitle.value);
    isRuleEditorOpened.value = false;
}

const submitHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const ruleId = (props.rowData?.id && Number.isInteger(props.rowData.id)) ? props.rowData.id : false;
    if (!ruleId) { return }

    save(ruleId);
}

return (_ctx: any,_cache: any) => {
  return (_unref(isRuleEditor))
    ? (_openBlock(), _createElementBlock("td", {
        key: 0,
        class: _normalizeClass(props.class || '')
      }, [
        (isRuleEditorOpened.value)
          ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
              _createElementVNode("form", { onSubmit: submitHandler }, [
                _createVNode(Input, {
                  modelValue: ruleTitle.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ruleTitle).value = $event))
                }, null, 8, ["modelValue"]),
                _cache[1] || (_cache[1] = _createElementVNode("button", {
                  type: "submit",
                  class: "btn btn-success btn-rounded btn-icon"
                }, [
                  _createElementVNode("i", { class: "mdi mdi-check" })
                ], -1))
              ], 32)
            ]))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "rule-editor-btn",
              onClick: editClickHandler
            }, [
              _createTextVNode(_toDisplayString(props.value || '') + " ", 1),
              _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa fa-edit" }, null, -1))
            ]))
      ], 2))
    : (_openBlock(), _createElementBlock("td", _hoisted_2, [
        _createElementVNode("span", null, _toDisplayString(props.value || ''), 1)
      ]))
}
}

})