import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id", "type", "name", "placeholder", "required", "disabled"]
const _hoisted_2 = ["id", "name", "placeholder", "required", "disabled"]

import { defineComponent, ref, toRef, watch } from 'vue';
import { getUniqId } from '@/utils';
import { IInput } from './types';
import { getValueFromEvent } from './helpers';


// props

export default /*@__PURE__*/_defineComponent({
  __name: 'Input',
  props: {
    modelValue: {},
    placeholder: {},
    disabled: { type: Boolean },
    required: { type: Boolean },
    name: {},
    id: {},
    type: {},
    validator: { type: Function },
    onValid: { type: Function },
    onInvalid: { type: Function }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const type = props.type || 'text';
const idi = props.id || getUniqId();
const value = ref(props.modelValue || '');
let prevValue = value.value; // для предыдущего значения типута
const isValid = props.validator || (()=>{return true});
const elementRef = ref(null); // для получения ref к input
const name = toRef(props, 'name');
const onValid = props.onValid || null;
const onInvalid = props.onInvalid || null;

defineComponent({ name: 'KitInput' });

// emits
const emits = __emit;
// watch
watch(()=>props.modelValue, ()=>{value.value = props.modelValue || ''})

// methods
const isTextarea = (type: string) => type === 'textarea';

const inputHandler = (e: Event) => {
    const eventValue = getValueFromEvent(e);

    if (isValid(eventValue)) {
        onValid && onValid();
        prevValue = eventValue;
        value.value = eventValue;
        emits('update:modelValue', value.value);
    }
    else {
        onInvalid && onInvalid(eventValue);
        // возвращаем предыдущее значение
        value.value = prevValue;
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isTextarea(_unref(type)))
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          id: _unref(idi),
          ref_key: "elementRef",
          ref: elementRef,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
          class: "form-control",
          type: _unref(type),
          name: name.value,
          placeholder: _ctx.placeholder || '',
          required: Boolean(_ctx.required),
          disabled: Boolean(_ctx.disabled),
          onInput: inputHandler
        }, null, 40, _hoisted_1)), [
          [_vModelDynamic, value.value]
        ])
      : _createCommentVNode("", true),
    (isTextarea(_unref(type)))
      ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 1,
          id: _unref(idi),
          ref_key: "elementRef",
          ref: elementRef,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((value).value = $event)),
          class: "form-control",
          name: name.value,
          placeholder: _ctx.placeholder || '',
          required: Boolean(_ctx.required),
          disabled: Boolean(_ctx.disabled),
          onInput: inputHandler
        }, null, 40, _hoisted_2)), [
          [_vModelText, value.value]
        ])
      : _createCommentVNode("", true)
  ], 64))
}
}

})