<script setup lang="ts">
import { defineComponent, defineProps } from 'vue';
// components
import Row from '@/components/kit/Row.vue';
import { TablePagination } from '@/components/Table';
import PaginationBtn from './PaginationBtn.vue';
import PaginationPrevBtn from './PaginationPrevBtn.vue';
import PaginationNextBtn from './PaginationNextBtn.vue';
import PaginationDelimeter from './PaginationDelimeter.vue';
import MinWidthAndScroll from '@/components/kit/MinWidthAndScroll.vue';
// types
import { TTableStore } from '@/components/Table/tableStore';


const props = defineProps<{ useStore: TTableStore<any> }>();
const store = props.useStore();

defineComponent({ name: 'KitTablePagination'});
</script>

<template>
  <Row>
    <MinWidthAndScroll class="kit-table-paginate">
      <div class="col-sm-12 col-md-5 table-view-info">
        <div>
          {{ store.paginateInfo }}
        </div>
      </div>

      <div class="col-sm-12 col-md-7">
        <div class="dataTables_paginate paging_simple_numbers">
          <ul class="pagination">
            <TablePagination 
              :store="props.useStore"
              :btn="PaginationBtn"
              :prev-btn="PaginationPrevBtn"
              :next-btn="PaginationNextBtn"
              :delimeter="PaginationDelimeter"
            />
          </ul>
        </div>
      </div>
    </MinWidthAndScroll>
  </Row>
</template>

<style lang="scss">
.kit-table-paginate {
    @media screen and (max-width: 540px) {
        & {
            padding-bottom: 10px;
        }
    }
}

.dataTables_paginate ul.pagination {
    margin: 0;
    white-space: nowrap;
    justify-content: flex-end;

    @media screen and (max-width: 540px) {
        & {
            justify-content: left;
        }
    }
}

.table-view-info {
    @media screen and (max-width: 540px) {
        & {
            width: 100%;
            margin-bottom: 1rem;
        }
    }
}
</style>