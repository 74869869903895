import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { style: {"color":"#000"} }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["value"]
const _hoisted_7 = ["value"]

import { onMounted, onUnmounted, ref } from 'vue';
import { isUserCanEditPrices } from '@/store';
import PageHeader from '@/components/kit/PageHeader.vue';
import Card from '@/components/kit/Card.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import { routeNames, cmiStatuses } from "@/router";
import { usePageEditItemStore } from './store/dealItemStore';
import { useRoute } from 'vue-router';
import { TJsonUseStore } from 'litsystems-vue-json-editor-dev/src';
import JsonViewer from 'litsystems-vue-json-editor-dev/src/JsonViewer.vue';
import PriceEditInput from './PriceEditInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PriceEdit',
  setup(__props) {

const isDevMode = new URL(window.location.toString()).searchParams.has('_dev'); // Отладочная информация


// store
const store = usePageEditItemStore();
const dealId = ref<number>(NaN);

var usePriceStore: TJsonUseStore['useStore'];

const getUseStoreCb = (useStore: TJsonUseStore['useStore']) => {
    usePriceStore = useStore;

    const jsonStore = useStore();

    // устанавливаем расширения
    jsonStore.addExtensionComponent('price-editor', PriceEditInput);

    // устанавливаем расширения
    if (store.editorOptions.extensions) {
        jsonStore.updateExtensions(store.editorOptions.extensions)
    }

    // устанавливаем классы
    if (store.editorOptions.classes) {
        jsonStore.updateClassNames(store.editorOptions.classes);
    }

    // устанавливаем скрытие элементов
    if (store.editorOptions.hiddens) {
        jsonStore.updateHiddens(store.editorOptions.hiddens);
    }

    // изначальная установка даннных
    jsonStore.setReference(store.pageContent);
}


onMounted(() => {
    const route = useRoute();
    const deal = parseInt((route?.params?.id ?? '') + '', 10);

    // получаем данные по id страницы
    if (Number.isInteger(deal)) {
        dealId.value = deal;
        store.getDataById(deal); // Дождитесь получения данных
    }
});

onUnmounted(() => {
    store.$reset();
    usePriceStore?.().$reset();
});

const savePriceHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();


    try {
        const priceStore = usePriceStore();
        const referenceData = JSON.stringify(priceStore.reference, null, 2);
        store.updateData(JSON.parse(referenceData));

    } catch (error) {
        console.error('Ошибка при сохранении изменений:', error);
    }
};



return (_ctx: any,_cache: any) => {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(PageHeader, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Редактирование цены")
      ])),
      _: 1
    }),
    (!_unref(isUserCanEditPrices)())
      ? (_openBlock(), _createBlock(Card, {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(CardTitle, null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(isUserCanEditPrices)() && dealId.value)
      ? (_openBlock(), _createBlock(Card, {
          key: 1,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            (_unref(store).$loading)
              ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
              : (_unref(store).$error)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Произошла ошибка получения данных.", -1)),
                    _cache[7] || (_cache[7] = _createElementVNode("p", null, [
                      _createElementVNode("strong", null, "Попробуйте перезагрузить страницу"),
                      _createTextVNode(", если это не поможет "),
                      _createElementVNode("strong", null, "обратитесь в поддержку"),
                      _createTextVNode(". ")
                    ], -1)),
                    _cache[8] || (_cache[8] = _createElementVNode("p", null, [
                      _createElementVNode("strong", null, "Данные об ошибке.")
                    ], -1)),
                    _createElementVNode("p", null, [
                      _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Обозначение ошибки: ", -1)),
                      _createTextVNode(_toDisplayString(_unref(store).$error.error), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[3] || (_cache[3] = _createElementVNode("strong", null, "ID запроса: ", -1)),
                      _createTextVNode(_toDisplayString(_unref(store).$error.requestId), 1)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Код ответа: ", -1)),
                      _createTextVNode(_toDisplayString(_unref(store).$error.code ?? ''), 1)
                    ]),
                    _cache[9] || (_cache[9] = _createElementVNode("p", null, " ", -1)),
                    _createVNode(_component_router_link, {
                      to: { name: _unref(routeNames).prices, params: { status: _unref(cmiStatuses).all } }
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("Вернуться к списку страниц.")
                      ])),
                      _: 1
                    }, 8, ["to"])
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createVNode(CardTitle, null, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("Информация о странице")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_1, [
                      _cache[11] || (_cache[11] = _createElementVNode("p", null, "Наименование страницы", -1)),
                      _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(store).title), 1),
                      _cache[12] || (_cache[12] = _createElementVNode("p", null, "URL страницы", -1)),
                      _createElementVNode("p", null, [
                        _createElementVNode("a", {
                          style: {"color":"var(--bs-body-color)","text-decoration":"none"},
                          target: "_blank",
                          href: 'https://www.tupa-germania.ru/' + _unref(store).url
                        }, _toDisplayString(_unref(store).url), 9, _hoisted_3)
                      ])
                    ]),
                    (_unref(isDevMode))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _cache[13] || (_cache[13] = _createElementVNode("p", null, "только для отладки:", -1)),
                          _createElementVNode("textarea", {
                            value: JSON.stringify(_unref(store).storage_config, null, `\t`),
                            class: "form-control form-control-sm",
                            style: {"resize":"vertical"}
                          }, null, 8, _hoisted_4),
                          _createElementVNode("textarea", {
                            value: JSON.stringify(_unref(store).pageContent, null, `\t`),
                            class: "form-control form-control-sm",
                            style: {"resize":"vertical"}
                          }, null, 8, _hoisted_5),
                          _createElementVNode("textarea", {
                            value: JSON.stringify(_unref(store).editor_options, null, `\t`),
                            class: "form-control form-control-sm",
                            style: {"resize":"vertical"}
                          }, null, 8, _hoisted_6),
                          _createElementVNode("textarea", {
                            value: JSON.stringify(_unref(store), null, `\t`),
                            class: "form-control form-control-sm",
                            style: {"resize":"vertical"}
                          }, null, 8, _hoisted_7),
                          _createElementVNode("p", null, _toDisplayString(_unref(store).newPriceValue), 1),
                          _cache[14] || (_cache[14] = _createElementVNode("p", null, null, -1))
                        ], 64))
                      : _createCommentVNode("", true),
                    _createVNode(CardTitle, null, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createTextVNode("Цена")
                      ])),
                      _: 1
                    }),
                    _createVNode(JsonViewer, { getUseStoreCb: getUseStoreCb }),
                    (_unref(store).isUpdated)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          class: "btn btn-inverse-primary btn-fw",
                          onClick: savePriceHandler
                        }, "Сохранить изменения"))
                      : _createCommentVNode("", true)
                  ], 64))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})