<script setup lang="ts">
import { defineComponent, defineProps, ref, defineEmits } from 'vue';
// components
import VueDatePicker from '@vuepic/vue-datepicker';
import { DatePickerInstance } from "@vuepic/vue-datepicker";
import '@vuepic/vue-datepicker/dist/main.css';
// types
import { IDatepicker } from './types';
// utils
import {
    getUniqId,
    isStrDateValid,
    isPartOfDate,
    dateToFormat,
    formatDateStr
} from '@/utils';
// helpers
import { getValueFromEvent } from './helpers';


// props 
const props = defineProps<IDatepicker>();

const idi = props.id || getUniqId();
const date = ref<Date | null>(props.modelValue || null);
const inputValue = ref<string>( props.modelValue && dateToFormat(props.modelValue) || '' );
let preValue: string = inputValue.value;

// настройки datepicker
const datepicker = ref<DatePickerInstance>(null); // связываем компонент datepicker с переменной

const textInputOptions = ref({
    format: 'dd.MM.yyyy',
});

const dayNames = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

defineComponent({
    name: "DatePicker",
});

// emits
const emits = defineEmits(['update:modelValue']);

// methods
const inputHandler = (e: Event) => {
    let eventValue = getValueFromEvent(e);

    // проверка, для того, чтобы можно было стирать точки
    if (eventValue + '.' === preValue) {
        preValue = eventValue;
        inputValue.value = eventValue;
        return
    }

    // если стёрли дату
    if (eventValue === '') {
        preValue = ''
        inputValue.value = ''
        const setDate = null;
        emits('update:modelValue', setDate);
        date.value = setDate;
        return
    }

    // при вставке даты c / заменим на точки
    if (eventValue.length === 10) {
        eventValue = eventValue.replace(/\//g, '.')
    }

    // проверяем строку, на часть даты
    if (isPartOfDate(eventValue)) {
        let doted = '';
        if (eventValue === preValue) {
            doted = eventValue;
        }
        else {
            // автодобавление точек
            doted = eventValue.length === 2 ? (eventValue + '.') : (
                eventValue.length === 5 ? (eventValue + '.') : eventValue
            );
        }

        // если дата полная, то отправляем её в дейтпикер
        if (doted.length === 10) {
            if (isStrDateValid(doted)) {
                let setDate: Date | null = new Date(formatDateStr(doted));
                let newValue = doted;
                // если указан диапазон дат
                if (props.minDate && props.maxDate) {
                    if (props.minDate > setDate || props.maxDate < setDate) {
                        setDate = null;
                        newValue = '';
                    }
                }
                else {
                    // если указан только minDate
                    if (props.minDate) {
                        if (props.minDate > setDate) {
                            setDate = null;
                            newValue = '';
                        }
                    }
                    else {
                        // если указан только maxDate
                        if (props.maxDate) {
                            if (props.maxDate < setDate) {
                                setDate = null;
                                newValue = '';
                            }
                        }
                    }

                }

                emits('update:modelValue', setDate);
                date.value = setDate;
                preValue = newValue;
                inputValue.value = newValue;
            }
            else {
                if (date.value) {
                    inputValue.value = dateToFormat(date.value);
                    preValue = inputValue.value;
                }
                else {
                    const lastValidStr = serachValidStr(doted);
                    inputValue.value = lastValidStr;
                    preValue = lastValidStr;
                }
            }
        }
        else {
            preValue = doted;
            inputValue.value = doted;
        }
    }
    else {
        // если введён недопустимый символ, то возврацаем предыдущее значение
        inputValue.value = preValue;
    }
}

// будем искать последнюю часть строки для даты
const serachValidStr = (date: string): string => {
    let str = date;
    while (str) {
        str = str.substring(0, str.length - 1);
        let strForMatch = str.padEnd(10, '1').split('');
        strForMatch[2] = '.';
        strForMatch[5] = '.';

        if (isStrDateValid(strForMatch.join(''))) {
            return str;
        }
    }
    return ''
}

const blurHandler = () => {
    if (date.value) {
        inputValue.value = dateToFormat(new Date(date.value));
        preValue = inputValue.value
    }
    else {
        inputValue.value = '';
        preValue = '';
        date.value = null;
        emits('update:modelValue', null);
    }

    datepicker.value?.closeMenu();
}

const clearHandler = () => {
    inputValue.value = '';
    preValue = '';
}

const updateHandler = (modelData: string) => {
    if (modelData) {
        const formatedDate = dateToFormat(new Date(modelData));
        inputValue.value = formatedDate;
        preValue = formatedDate;
        emits('update:modelValue', new Date(modelData));
    }
    else {
        emits('update:modelValue', null);
    }
}

</script>

<template>
  <div class="erp-datepicker">
    <VueDatePicker
      v-model="date"
      placeholder="dd.mm.yyyy"
      format="dd.MM.yyyy"
      :text-input-options="textInputOptions"
      :enable-time-picker="false"
      :disabled="Boolean(disabled)"
      text-input
      :ref="datepicker"
      auto-apply
      :day-names="dayNames"
      :uid="idi"
      :utc="true"
      :min-date="minDate"
      :max-date="maxDate"

      locale="ru"
      @update:model-value="updateHandler"
      @cleared="clearHandler"
    >
      <template #dp-input="{}">
        <input 
          :id="idi"
          v-model="inputValue" 
          class="form-control"
          type="text"
          :onInput="inputHandler"
          :onBlur="blurHandler"
          :placeholder="placeholder || ''"
          :required="Boolean(required)" 
          :disabled="Boolean(disabled)" 
          autocomplete="off"
        >
      </template>
    </VueDatePicker>
  </div>
</template>

<style lang="scss">
@import '../../assets/css/kit-icons.scss';
@import '../../assets/css/env.scss';

.erp {
    &-datepicker {

        input[type=text].form-control {
            background-image: $calendar-icon;
            background-size: 20px;
            background-position: 8px center;
            background-repeat: no-repeat;
            padding-left: 33px;
        }

        & .dp__input {
            border-radius: $border-radius;
            font-family: $font-family;
        }

        & .dp__cell_inner {
            font-weight: 100;
            font-family: $font-family;
        }

        & .dp__active_date {
            font-family: $font-family;
            background-color: $btn-bg-color;
            font-weight: 400;
        }

        & .dp__calendar_header_item {
            font-weight: 100;
            font-family: $font-family;
        }

        & .dp__btn {
            font-family: $font-family;
        }

        & .dp__overlay_cell {
            font-weight: 100;
            font-family: $font-family;
        }

        & .dp__overlay_cell_active {
            background-color: $btn-bg-color;
            font-weight: 400;
            font-family: $font-family;
        }

        & .dp__today {
            border: 1px solid $input-active-border-color;
        }

        & .dp__menu {
            box-shadow: 0 0 5px 1px $shadow-color;
        }
        }
}
</style>