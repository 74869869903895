import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "erp-datepicker" }
const _hoisted_2 = ["id", "placeholder", "required", "disabled"]

import { defineComponent, ref } from 'vue';
// components
import VueDatePicker from '@vuepic/vue-datepicker';
import { DatePickerInstance } from "@vuepic/vue-datepicker";
import '@vuepic/vue-datepicker/dist/main.css';
// types
import { IDatepicker } from './types';
// utils
import {
    getUniqId,
    isStrDateValid,
    isPartOfDate,
    dateToFormat,
    formatDateStr
} from '@/utils';
// helpers
import { getValueFromEvent } from './helpers';


// props 

export default /*@__PURE__*/_defineComponent({
  __name: 'Datepicker',
  props: {
    modelValue: {},
    placeholder: {},
    disabled: { type: Boolean },
    required: { type: Boolean },
    id: {},
    date: {},
    minDate: {},
    maxDate: {},
    format: { type: Function }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const idi = props.id || getUniqId();
const date = ref<Date | null>(props.modelValue || null);
const inputValue = ref<string>( props.modelValue && dateToFormat(props.modelValue) || '' );
let preValue: string = inputValue.value;

// настройки datepicker
const datepicker = ref<DatePickerInstance>(null); // связываем компонент datepicker с переменной

const textInputOptions = ref({
    format: 'dd.MM.yyyy',
});

const dayNames = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

defineComponent({
    name: "DatePicker",
});

// emits
const emits = __emit;

// methods
const inputHandler = (e: Event) => {
    let eventValue = getValueFromEvent(e);

    // проверка, для того, чтобы можно было стирать точки
    if (eventValue + '.' === preValue) {
        preValue = eventValue;
        inputValue.value = eventValue;
        return
    }

    // если стёрли дату
    if (eventValue === '') {
        preValue = ''
        inputValue.value = ''
        const setDate = null;
        emits('update:modelValue', setDate);
        date.value = setDate;
        return
    }

    // при вставке даты c / заменим на точки
    if (eventValue.length === 10) {
        eventValue = eventValue.replace(/\//g, '.')
    }

    // проверяем строку, на часть даты
    if (isPartOfDate(eventValue)) {
        let doted = '';
        if (eventValue === preValue) {
            doted = eventValue;
        }
        else {
            // автодобавление точек
            doted = eventValue.length === 2 ? (eventValue + '.') : (
                eventValue.length === 5 ? (eventValue + '.') : eventValue
            );
        }

        // если дата полная, то отправляем её в дейтпикер
        if (doted.length === 10) {
            if (isStrDateValid(doted)) {
                let setDate: Date | null = new Date(formatDateStr(doted));
                let newValue = doted;
                // если указан диапазон дат
                if (props.minDate && props.maxDate) {
                    if (props.minDate > setDate || props.maxDate < setDate) {
                        setDate = null;
                        newValue = '';
                    }
                }
                else {
                    // если указан только minDate
                    if (props.minDate) {
                        if (props.minDate > setDate) {
                            setDate = null;
                            newValue = '';
                        }
                    }
                    else {
                        // если указан только maxDate
                        if (props.maxDate) {
                            if (props.maxDate < setDate) {
                                setDate = null;
                                newValue = '';
                            }
                        }
                    }

                }

                emits('update:modelValue', setDate);
                date.value = setDate;
                preValue = newValue;
                inputValue.value = newValue;
            }
            else {
                if (date.value) {
                    inputValue.value = dateToFormat(date.value);
                    preValue = inputValue.value;
                }
                else {
                    const lastValidStr = serachValidStr(doted);
                    inputValue.value = lastValidStr;
                    preValue = lastValidStr;
                }
            }
        }
        else {
            preValue = doted;
            inputValue.value = doted;
        }
    }
    else {
        // если введён недопустимый символ, то возврацаем предыдущее значение
        inputValue.value = preValue;
    }
}

// будем искать последнюю часть строки для даты
const serachValidStr = (date: string): string => {
    let str = date;
    while (str) {
        str = str.substring(0, str.length - 1);
        let strForMatch = str.padEnd(10, '1').split('');
        strForMatch[2] = '.';
        strForMatch[5] = '.';

        if (isStrDateValid(strForMatch.join(''))) {
            return str;
        }
    }
    return ''
}

const blurHandler = () => {
    if (date.value) {
        inputValue.value = dateToFormat(new Date(date.value));
        preValue = inputValue.value
    }
    else {
        inputValue.value = '';
        preValue = '';
        date.value = null;
        emits('update:modelValue', null);
    }

    datepicker.value?.closeMenu();
}

const clearHandler = () => {
    inputValue.value = '';
    preValue = '';
}

const updateHandler = (modelData: string) => {
    if (modelData) {
        const formatedDate = dateToFormat(new Date(modelData));
        inputValue.value = formatedDate;
        preValue = formatedDate;
        emits('update:modelValue', new Date(modelData));
    }
    else {
        emits('update:modelValue', null);
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(VueDatePicker), {
      modelValue: date.value,
      "onUpdate:modelValue": [
        _cache[1] || (_cache[1] = ($event: any) => ((date).value = $event)),
        updateHandler
      ],
      placeholder: "dd.mm.yyyy",
      format: "dd.MM.yyyy",
      "text-input-options": textInputOptions.value,
      "enable-time-picker": false,
      disabled: Boolean(_ctx.disabled),
      "text-input": "",
      ref: datepicker.value,
      "auto-apply": "",
      "day-names": dayNames,
      uid: _unref(idi),
      utc: true,
      "min-date": _ctx.minDate,
      "max-date": _ctx.maxDate,
      locale: "ru",
      onCleared: clearHandler
    }, {
      "dp-input": _withCtx(({}) => [
        _withDirectives(_createElementVNode("input", {
          id: _unref(idi),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
          class: "form-control",
          type: "text",
          onInput: inputHandler,
          onBlur: blurHandler,
          placeholder: _ctx.placeholder || '',
          required: Boolean(_ctx.required),
          disabled: Boolean(_ctx.disabled),
          autocomplete: "off"
        }, null, 40, _hoisted_2), [
          [_vModelText, inputValue.value]
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "text-input-options", "disabled", "uid", "min-date", "max-date"])
  ]))
}
}

})