import { postWithoutAuth, get } from '@/service/bffService';
export { TBffResponseError } from '@/service/bffService';


type TUserLoginReqDto = {
	identifier: string;
	password: string;
}

type TUserLoginResDto = {
	jwt: string;

	user: {
		id: number;
		email: string;
		phone: string;
	}
}


export const loginUserByPassword = async (identifier: string, password: string): Promise<TUserLoginResDto> => {
	return await postWithoutAuth<TUserLoginReqDto, TUserLoginResDto>('/api/login', { identifier, password })
}

type TUserMeResDto = {
	confirmed: boolean;
	phone: string;
	email: string;
	id: number;
	permissions: number[];
	first_name: string;
	last_name: string;
	telegram: string;
	instagram: string;
	watsapp: string;
	avatar: string;
}

export const getMe = async () => {
	return get<TUserMeResDto>('/api/users/me')
}