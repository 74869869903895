<script setup lang="ts">
import { defineComponent, defineProps, ref, watch } from 'vue';
// components
import Row from '@/components/kit/Row.vue';
import Input from '@/components/kit/Input.vue';
// types
import { TTableStore, useTableSearch } from '@/components/Table';


const props = defineProps<{ useStore: TTableStore<any> }>();
const store = props.useStore();

// search
const searchValue = useTableSearch(props.useStore);

// perpage
const perPageList = ref<number[]>([10, 25, 50, 100]);
const perPageValue = ref<number>(store.paginatePerPage);
watch(perPageValue, ()=>store.setPerPage(perPageValue.value));
watch(()=>store.paginatePerPage, ()=>{
    const storePerPage = store.paginatePerPage;
    if (!~perPageList.value.indexOf(storePerPage)) {
        perPageList.value = [...perPageList.value, storePerPage].sort((a,b)=>{return a<b?-1:1});
        perPageValue.value = storePerPage;
    }
})

defineComponent({ name: 'KitTableSearch'});
</script>

<template>
  <Row>
    <div class="col-sm-12 col-md-6">
      <div class="table-per-page">
        <label>Показывать
          <select
            v-model="perPageValue"
            class="custom-select custom-select-sm form-control"
          >
            <option
              v-for="(v,k) of perPageList"
              :key="k"
              :value="v"
            >{{ v }}</option>
          </select>
        </label>
      </div>
    </div>
        
    <div class="col-sm-12 col-md-6">
      <div class="table-search-filter">
        <label>
          <Input
            v-model="searchValue"
            type="search"
            placeholder="Поиск"
          />
        </label>
      </div>
    </div>
  </Row>
</template>

<style lang="scss">
.table-per-page {
    select {
        width: auto;
        display: inline-block;
    }

    @media screen and (max-width: 540px) {
        & {
            margin-bottom: 10px;
            text-align: right;
        }
    }
}

.table-search-filter {
    text-align: right;

    label {
        @media screen and (max-width: 540px) {
            & {
                width: 100%;
            }
        }
    }
}
</style>