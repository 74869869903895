import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { defineComponent } from 'vue';
// components
import {
    Card,
    CardTitle,
    Row,
    // tables
    MinWidthAndScroll,
    Table,
    TablePagination,
} from '@/components/kit';
import { 
    AllDealsFilters,
    EmptyTr,
    // ActionsTd,
    Row as TableRow
} from './components';
// store 
import { useAsyncCmiAllListStore } from './store';
import { isUserCanViewCmi } from '@/store';



export default /*@__PURE__*/_defineComponent({
  __name: 'AllDealsTable',
  setup(__props) {

const tableStore = useAsyncCmiAllListStore();

// настройки талицы
tableStore.columns = [
    {
        key: 'id',
        title: '#',
        // component: ActionsTd,
    },
    {
        key: 'status',
        title: 'Статус'
    },

    {
        key: 'site_id',
        title: 'ID',
        searchable: true,
        filterable: true,
    },
    
    {
        key: 'antrag_id',
        title: 'Antrag ID',
        searchable: true,
    },
    
    {
        key: 'fname',
        title: 'Имя',
        searchable: true,
        filterable: true,
    },
    
    {
        key: 'lname',
        title: 'Фамилия',
        searchable: true,
        filterable: true,
    },
    
    {
        key: 'email',
        title: 'email',
        searchable: true,
        filterable: true,
    },
    {
        key: 'date',
        title: 'Дата',
        // sortable: true,
    },

];

tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
    tableStore.row = {};
}
tableStore.row.component = TableRow;

// получение данных
if (isUserCanViewCmi()) {
    tableStore.getData()
}

defineComponent({name: 'AllDealTable'});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(isUserCanViewCmi)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(isUserCanViewCmi)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 1,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Полный список заявок")
              ])),
              _: 1
            }),
            _createVNode(_unref(Row), null, {
              default: _withCtx(() => [
                _createVNode(_unref(MinWidthAndScroll), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Table), { "use-store": _unref(useAsyncCmiAllListStore) }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(AllDealsFilters))
                      ]),
                      _: 1
                    }, 8, ["use-store"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(TablePagination), {
              class: "tab-content tab-transparent-content",
              "use-store": _unref(useAsyncCmiAllListStore)
            }, null, 8, ["use-store"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})