import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-sm-12 col-md-5 table-view-info" }
const _hoisted_2 = { class: "col-sm-12 col-md-7" }
const _hoisted_3 = { class: "dataTables_paginate paging_simple_numbers" }
const _hoisted_4 = { class: "pagination" }

import { defineComponent } from 'vue';
// components
import Row from '@/components/kit/Row.vue';
import { TablePagination } from '@/components/Table';
import PaginationBtn from './PaginationBtn.vue';
import PaginationPrevBtn from './PaginationPrevBtn.vue';
import PaginationNextBtn from './PaginationNextBtn.vue';
import PaginationDelimeter from './PaginationDelimeter.vue';
import MinWidthAndScroll from '@/components/kit/MinWidthAndScroll.vue';
// types
import { TTableStore } from '@/components/Table/tableStore';



export default /*@__PURE__*/_defineComponent({
  __name: 'TablePagination',
  props: {
    useStore: {}
  },
  setup(__props: any) {

const props = __props;
const store = props.useStore();

defineComponent({ name: 'KitTablePagination'});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Row, null, {
    default: _withCtx(() => [
      _createVNode(MinWidthAndScroll, { class: "kit-table-paginate" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, _toDisplayString(_unref(store).paginateInfo), 1)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("ul", _hoisted_4, [
                _createVNode(_unref(TablePagination), {
                  store: props.useStore,
                  btn: PaginationBtn,
                  "prev-btn": PaginationPrevBtn,
                  "next-btn": PaginationNextBtn,
                  delimeter: PaginationDelimeter
                }, null, 8, ["store"])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})